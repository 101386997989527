<ng-container *ngIf="showNavigation$ | async">
  <div
    class="sidebar no-print"
    [ngClass]="sideBarClasses$ | async"
    [ngStyle]="sideBarStyles"
  >
    <div id="sidebar-header">
      <div id="logo-container">
        <img
          *ngIf="logo"
          id="logo"
          [src]="logo"
          alt="deskbird-portal-logo"
          [ngClass]="deskbirdLogoRouterLink$ | async"
          [routerLink]="logoLink$ | async"
        />
        <span class="title" *ngIf="showTitle$ | async">{{
          title$ | async
        }}</span>
      </div>
    </div>
    <div
      id="sidebar-menu"
      class="special-scrollbar purple"
      [ngClass]="{
        admin: !!(isAdminAppEnv$ | async),
        'db-admin': !!(isUserDeskbirdAdmin$ | async)
      }"
      [ngStyle]="{
        height: !!(isAdminAppEnv$ | async)
          ? 'calc(100vh - 242px - ' + navigationMarginTop + 'px)'
          : 'calc(100vh - 308px - ' + navigationMarginTop + 'px)'
      }"
    >
      <db-admin-navigation
        *ngIf="isAdminAppEnv$ | async"
        [dbResolve]="adminAppFeatureFlagsBundle"
        [hideIcons]="!!(hideIcons$ | async)"
        [publicApiKeysPageAllowed]="!!(publicApiKeysPageAllowed$ | async)"
        [isExpanded]="!!(isExpanded$ | async)"
        [officeId]="(officeId$ | async)!"
        [companyId]="(companyId$ | async)!"
        [hasPlanningAccess]="!!(hasPlanningAccess$ | async)"
        [hasBookingAccess]="!!(hasBookingAccess$ | async)"
        [isUserAdmin]="!!(isUserAdmin$ | async)"
        [isUserManager]="!!(isUserManager$ | async)"
        [isUserGroupManager]="!!(isUserGroupManager$ | async)"
        [isUserGuest]="!!(isUserGuest$ | async)"
        [isUserOfficeAdmin]="!!(isUserOfficeAdmin$ | async)"
        [isUserRegularUser]="!!(isUserRegularUser$ | async)"
        [isUserDeskbirdAdmin]="!!(isUserDeskbirdAdmin$ | async)"
        [cateringAndServicesEnabled]="
          !!(featureEnableCateringAndServices$ | async)
        "
        [kioskModeEnabled]="!!(featureEnableKioskMode$ | async)"
        [officeEquipmentEnabled]="!!(featureEnableOfficeEquipment$ | async)"
        [hybridWorkPoliciesEnabled]="
          !!(featureEnableHybridWorkPolicies$ | async)
        "
        [adminPartPrefix]="adminPartPrefix"
        [userProfileImageUrl]="userProfileImage$ | async"
        [userInfo]="(userInfo$ | async)!"
        [clientPartPrefix]="clientPartPrefix"
        [userRoleCompanyPageRestrictions]="
          userRoleCompanyPageRestrictionsForAdmin$ | async
        "
        [hasIntegrations]="(hasIntegrations$ | async)!"
        (navigateToProfile)="navigateToProfileHandler()"
        (signOut)="navigateToSignOutHandler()"
      >
      </db-admin-navigation>
      <db-client-navigation
        *ngIf="(isAdminAppEnv$ | async) === false"
        [dbResolve]="showIconsClientBundle"
        [hideIcons]="!!(hideIcons$ | async)"
        [hasPlanningAccess]="!!(hasPlanningAccess$ | async)"
        [hasBookingAccess]="!!(hasBookingAccess$ | async)"
        [isUserAdmin]="!!(isUserAdmin$ | async)"
        [isUserManager]="!!(isUserManager$ | async)"
        [isUserGroupManager]="!!(isUserGroupManager$ | async)"
        [isUserGuest]="!!(isUserGuest$ | async)"
        [isUserOfficeAdmin]="!!(isUserOfficeAdmin$ | async)"
        [isUserRegularUser]="!!(isUserRegularUser$ | async)"
        [isUserDeskbirdAdmin]="!!(isUserDeskbirdAdmin$ | async)"
        [isExpanded]="!!(isExpanded$ | async)"
        [isUserDeskbirdAdmin]="!!(isUserDeskbirdAdmin$ | async)"
        [officeId]="(officeId$ | async)!"
        [companyId]="(companyId$ | async)!"
        [userProfileImageUrl]="userProfileImage$ | async"
        [userInfo]="(userInfo$ | async)!"
        [adminPartPrefix]="adminPartPrefix"
        [clientPartPrefix]="clientPartPrefix"
        [isLoadingOfficesInitially]="!!(isLoadingOfficesInitially$ | async)"
        [userRoleCompanyPageRestrictions]="
          userRoleCompanyPageRestrictionsForClient$ | async
        "
        (navigateToProfile)="navigateToProfileHandler()"
        (navigateToYourSpaces)="navigateToYourSpacesHandler()"
        (signOut)="navigateToSignOutHandler()"
      >
      </db-client-navigation>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="shouldShowSimplifyMobile$ | async">
  <div class="header-content">
    <img src="assets/icons/deskbird_logo_text.svg" alt="deskbird" />
  </div>
</ng-container>
<div
  id="content"
  [ngClass]="{
    'no-navigation':
      (isOnSamlPage$ | async) ||
      (isOnLoginPage$ | async) ||
      (isLogged$ | async) === false ||
      (isOnFreeTrialPage$ | async) ||
      (hasError$ | async) ||
      ((isOffline$ | async) && (isMaintenanceMode$ | async)) ||
      (hasNoAccess$ | async),
    'side-nav-minimized': (isExpanded$ | async) === false,
    'sticky-parent-container': (isOnPageWithStickyHeader$ | async)
  }"
  [ngStyle]="{ minHeight: 'calc(100vh - ' + navigationMarginTop + 'px)' }"
>
  <ng-content></ng-content>
</div>
