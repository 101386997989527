import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store';

const factory = forNamespace('[GLOBAL LOADER]');


export const showLoaderBundle = factory.singleAction(
  'showLoader',
   props<{ visibility: boolean, textContent?: string, reset?: boolean }>()
);

export const globalLoaderBundles = [
  showLoaderBundle
];
