import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { RequestMethod } from './enums/request-method';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadFiles(
    files: File[],
    formDataName: string,
    location: string,
    additionalData?: any,
    csrf?: { token: string; headerName: string },
    requestMethod: RequestMethod.PATCH | RequestMethod.PUT | RequestMethod.POST = RequestMethod.POST,
    skipFormDataHeader = true
  ): Observable<HttpEvent<void>> {
    if (!files.length) {
      return EMPTY;
    }
    let headers = skipFormDataHeader ? new HttpHeaders() : new HttpHeaders({ 'content-type': 'multipart/form-data' });
    const formData = new FormData();

    if (csrf && csrf.token && csrf.headerName) {
      headers = headers.set(csrf.headerName, csrf.token);
    }
    if (additionalData) {
      Object.entries(additionalData).forEach(([key, value]) => formData.append(key, value as any));
    }
    files.forEach((file) => {
      formData.append(formDataName || 'files', file, file.name);
    });

    return this.http[requestMethod](location, formData, {
      reportProgress: true,
      observe: 'events',
      headers,
    }) as any;
  }
}
