import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { IOfficeEvent } from 'common-module';
import {
  loadOfficeEventsBundle,
  selectOfficeEventBundle,
  saveOfficeEventBundle,
  deleteOfficeEventBundle,
  attendOfficeEventBundle,
  activateInvitationInNonPrimaryOfficeEventsBundle,
  deactivateInvitationInNonPrimaryOfficeEventsBundle,
} from './bundles';
import { GridDatePeriodType } from 'types';
import moment from 'moment';

const sortComparer = (a: IOfficeEvent, b: IOfficeEvent) => a.id.localeCompare(b.id);

const adapter = createEntityAdapter<IOfficeEvent>({
  selectId: (entity: IOfficeEvent) => entity.id,
  sortComparer,
});

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export interface IOfficeEventsState extends EntityState<IOfficeEvent> {
  selectedEvent: IOfficeEvent | null;
  isSelectedEventLoading: boolean;
  isListLoading: boolean;
  listOfficeIds: string[];
  listDatePeriodType: GridDatePeriodType | null;
}

export const initialState: IOfficeEventsState = {
  ...adapter.getInitialState({}),
  selectedEvent: null,
  isSelectedEventLoading: false,
  isListLoading: false,
  listOfficeIds: [],
  listDatePeriodType: null,
};

export const officeEventsReducer = createReducer(
  initialState,
  on(loadOfficeEventsBundle.loadOfficeEvents, (state) => {
    return {
      ...state,
      isListLoading: true,
    };
  }),
  on(loadOfficeEventsBundle.loadOfficeEventsSuccess, (state, { events, gridDatePeriodType, officeIds }) => {
    const cleanState = {
      ...state,
      ...adapter.removeAll(state),
    };

    return {
      ...cleanState,
      ...adapter.addMany(events, cleanState),
      isListLoading: false,
      listOfficeIds: officeIds,
      listDatePeriodType: gridDatePeriodType ?? null,
    };
  }),
  on(loadOfficeEventsBundle.loadOfficeEventsFailure, (state) => {
    return {
      ...state,
      isListLoading: false,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEvent, (state) => {
    return {
      ...state,
      isSelectedEventLoading: true,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEventSuccess, (state, { event }) => {
    return {
      ...adapter.updateOne({ id: event.id, changes: event }, state),
      selectedEvent: event,
      isListLoading: false,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEventFailure, (state) => {
    return {
      ...state,
      isSelectedEventLoading: false,
    };
  }),
  on(selectOfficeEventBundle.selectOfficeEventCleanup, (state) => {
    return {
      ...state,
      selectedEvent: null,
    };
  }),
  on(saveOfficeEventBundle.saveOfficeEventSuccess, (state, { event }) => {
    if (state.listOfficeIds.indexOf(event.officeId) < 0) {
      return state;
    }

    if (state.listDatePeriodType === GridDatePeriodType.PAST) {
      // We don't want to add new entities in the store when the list is filtered by past events
      return {
        ...state,
        ...adapter.updateOne({ id: event.id, changes: event }, state),
      };
    } else {
      return {
        ...state,
        ...adapter.upsertOne(event, state),
      };
    }
  }),

  on(deleteOfficeEventBundle.deleteOfficeEventSuccess, (state, { event }) => {
    return {
      ...state,
      ...adapter.removeOne(event.id, state),
    };
  }),

  on(attendOfficeEventBundle.attendOfficeEventSuccess, (state, { event }) => adapter.updateOne({ id: event.id, changes: event }, state)),

  on(activateInvitationInNonPrimaryOfficeEventsBundle.activateInvitationInNonPrimaryOfficeEvents, (state, { officeId, date }) => {
    const events: Update<IOfficeEvent>[] = selectAll(state)
      .filter((event) => event.officeId === officeId && event.attendance?.me && moment(date).isSame(event.startTime, 'day'))
      .map((event) => {
        return {
          id: event.id,
          changes: {
            ...event,
            attendance: {
              stats: event.attendance!.stats,
              attendees: event.attendance!.attendees,
              me: {
                response: event.attendance!.me!.response,
                isInvited: true,
              },
            },
          },
        };
      });
    return adapter.updateMany(events, state);
  }),

  on(deactivateInvitationInNonPrimaryOfficeEventsBundle.deactivateInvitationInNonPrimaryOfficeEvents, (state, { officeId, date }) => {
    const events: Update<IOfficeEvent>[] = selectAll(state)
      .filter((event) => event.officeId === officeId && event.attendance?.me && moment(date).isSame(event.startTime, 'day'))
      .map((event) => {
        return {
          id: event.id,
          changes: {
            ...event,
            attendance: {
              stats: event.attendance!.stats,
              attendees: event.attendance!.attendees,
              me: {
                response: event.attendance!.me!.response,
                isInvited: false,
              },
            },
          },
        };
      });
    return adapter.updateMany(events, state);
  })
);

export const selectOfficeEventIds = selectIds;
export const selectOfficeEventEntities = selectEntities;
export const selectAllOfficeEvents = selectAll;
export const selectOfficeEventsTotalCount = selectTotal;
