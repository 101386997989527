import { Pipe, PipeTransform } from '@angular/core';
import { IUserInfo } from 'types';

@Pipe({ name: 'userName', standalone: true })
/** Prepare user name string taking into account anonymous and guest labeling */
export class UserNamePipe implements PipeTransform {
  transform(userInfo?: IUserInfo): string {
    if (!userInfo) return '';

    const firstLastName = userInfo.firstName ? `${userInfo.firstName} ${userInfo.lastName}` : '';
    const userName =
      userInfo.isAnonymous && firstLastName === 'Anonymous user' ? $localize`:@@common|anonymous-user:Anonymous user` : firstLastName;
    return userInfo.isGuest ? `${userName} ${$localize`:@@common|guest-with-brackets:(Guest)`}` : userName;
  }
}
