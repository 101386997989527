import { ConnectedPosition, Overlay, PositionStrategy } from '@angular/cdk/overlay';

const getConnectedPositionRight = (offsets: { offsetX?: number; offsetY?: number } = {}): ConnectedPosition => ({
  originY: 'top',
  originX: 'end',
  overlayY: 'top',
  overlayX: 'start',
  offsetX: offsets.offsetX,
  offsetY: offsets.offsetY,
});

const getConnectedPositionCenterRight = (offsets: { offsetX?: number; offsetY?: number } = {}): ConnectedPosition => ({
  originY: 'center',
  originX: 'end',
  overlayY: 'center',
  overlayX: 'start',
  offsetX: offsets.offsetX,
  offsetY: offsets.offsetY,
});

const getConnectedPositionCenterTop = (offsets: { offsetX?: number; offsetY?: number } = {}): ConnectedPosition => ({
  originY: 'top',
  originX: 'center',
  overlayY: 'top',
  overlayX: 'center',
  offsetX: offsets.offsetX,
  offsetY: offsets.offsetY,
});

const getConnectedPositionDefault = (offsets: { offsetX?: number; offsetY?: number } = {}): ConnectedPosition => ({
  originY: 'bottom',
  originX: 'start',
  overlayY: 'top',
  overlayX: 'start',
  offsetX: offsets.offsetX,
  offsetY: offsets.offsetY,
});

export function getAttachedPopupPositionStrategy(
  overlay: Overlay,
  nativeElement: HTMLElement,
  position: 'default' | 'right' | 'center-right' | 'center-top' = 'default',
  offsets: { offsetX?: number; offsetY?: number } = {}
): PositionStrategy {
  let connectedPosition: ConnectedPosition;
  switch (position) {
    case 'right':
      connectedPosition = getConnectedPositionRight(offsets);
      break;
    case 'center-right':
      connectedPosition = getConnectedPositionCenterRight(offsets);
      break;
    case 'center-top':
      connectedPosition = getConnectedPositionCenterTop(offsets);
      break;      
    default:
      connectedPosition = getConnectedPositionDefault(offsets);
      break;
  }

  return overlay.position().flexibleConnectedTo(nativeElement).withPositions([connectedPosition]);
}
