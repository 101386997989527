import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputText, InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonIconComponent } from 'db-ui';

@Component({
  selector: 'db-editable-input',
  standalone: true,
  imports: [CommonModule, ButtonModule, InputTextModule, RippleModule, ReactiveFormsModule, ButtonIconComponent],
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EditableInputComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableInputComponent implements ControlValueAccessor {
  private changeDetectorRef = inject(ChangeDetectorRef);
  @ViewChild('inputElement') inputElement!: InputText;

  private _initialValue: string | undefined = undefined;
  readonly form = new FormGroup({
    item: new FormControl<string>({ value: '', disabled: true }, { validators: Validators.required }),
  });
  readonly itemControl = this.form.get('item')!;
  editModeEnabled = false;

  @Input() set inputValue(value: string | undefined) {
    this._initialValue = value;
    this.form.get('item')?.setValue(value || '');
  }

  private onChange!: (value: string | number) => {};
  private onTouch!: () => {};

  // TODO: removed state
  @Input() removed = false;
  @Input() placeholder = '';
  @Input() editButtonVisible = true;
  @Output() inputValueChanged = new EventEmitter<string>();

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: string): void {
    this.form.get('item')!.setValue(obj);
    this.changeDetectorRef.detectChanges();
  }
  setDisabledState?(isDisabled: boolean): void {
    this.form.get('item')!.disable();
  }

  get isFieldInvalid(): boolean {
    return this.itemControl.invalid && this.itemControl.touched;
  }

  enterEditModeHandler(): void {
    this.editModeEnabled = true;
    this.form.get('item')!.enable();
  }

  cancelEditHandler(): void {
    this.editModeEnabled = false;
    this.form.get('item')!.setValue(this._initialValue || '');
    this.form.get('item')!.disable();
  }

  formSubmittedHandler(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const value = this.form.get('item')!.value!;
    this._initialValue = value;
    this.editModeEnabled = false;
    this.form.get('item')!.disable();
    this.inputValueChanged.emit(value);
  }
}
