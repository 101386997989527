export const localesWith12hFormat = [
  // English
  'en-US', // United States
  'en-CA', // Canada
  'en-IE', // Ireland
  'en-IN', // India
  'en-AU', // Australia
  'en-NZ', // New Zealand
  'en-MX', // Mexico
  'en-HN', // Honduras
  // Spanish
  'es-HN', // Honduras
  'es-US', // United States
  'es-MX', // Mexico
  // Other
  'ml', // Malayalam
  'bn', // Bengali
];