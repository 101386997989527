import { OfficeRoleCode } from "types";

export const getOfficeRoleGtmType = (roleCode: OfficeRoleCode): string => {
  switch (roleCode) {
    case OfficeRoleCode.EVACUATION_ASSISTANT:
      return 'evacuation_helper';
    case OfficeRoleCode.FIRE_RESPONDER:
      return 'fire_warden'
    case OfficeRoleCode.FIRST_AIDER:
      return 'first_aider';
    case OfficeRoleCode.KEY_HOLDER:
      return 'hey_holder'
    default:
      return '';
  }
};