<label *ngIf="!!label" class="d-block small-text-bold mb-4">
  {{ label }}
  <ng-container *ngIf="showAsterisk">*</ng-container>
  <i *ngIf="optional" i18n="@@shared|optional-label">(optional)</i>
</label>
<p-multiSelect
  *ngIf="_multiselectVisibility"
  [attr.data-testId]="dataTestId"
  styleClass="w-100"
  [options]="options"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [optionDisabled]="optionDisabled"
  [display]="chip ? 'chip' : 'comma'"
  [showToggleAll]="showToggleAll"
  [placeholder]="placeholder"
  [filter]="filter"
  [filterBy]="filterBy || optionLabel"
  [showHeader]="showHeader"
  [selectedItemsLabel]="selectedItemsLabel"
  [maxSelectedLabels]="maxSelectedLabels"
  [panelStyleClass]="panelStyleClass || ''"
  [group]="group"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [showClear]="showClear"
  [resetFilterOnHide]="resetFilterOnHide"
  [ngModel]="selectedOptions ?? undefined"
  appendTo="body"
>
  <ng-template
    *ngFor="let template of templates"
    let-item
    [pTemplate]="template.name"
    [type]="template.type"
  >
    <ng-container
      *ngTemplateOutlet="template.template; context: { $implicit: item }"
    ></ng-container>
  </ng-template>
</p-multiSelect>
