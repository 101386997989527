import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  standalone: true,
})
export class IncludesPipe implements PipeTransform {
  transform(values: any, value: any, propertyPath?: string): boolean {
    if (!Array.isArray(values)) {
      return false;
    }
    if (!propertyPath) {
      return values.includes(value);
    }
    const pathArray = propertyPath.split('.');
    return !!values.find((objValue) => {
      for (const path of pathArray) {
        if (!(path in objValue)) {
          return false;
        }
        objValue = objValue[path];
      }
      return objValue === value;
    });
  }
}
