<ng-template #officeSwitcherTemplate>
  <div id="office-switcher-page-frame-container">
    <div *ngIf="!(noOfficeId$ | async) && !(isClientAppEnv$ | async)" id="office-switch-container" class="mr-16">
      <db-office-switcher [officeChangeGtmEventName]="officeChangeGtmEventName"></db-office-switcher>
    </div>

    <div id="other">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
  </div>
</ng-template>
<db-page-frame
  [rightSideHeaderTemplate]="false"
  [title]="title"
  [headerTemplate]="officeSwitcherTemplate"
  [showProgressLoader]="showProgressLoader">
  <ng-content></ng-content>
</db-page-frame>