import { Injectable, inject } from '@angular/core';
import { CanLoad, Route, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateLoad implements CanLoad {
  authModel = inject(AuthModel);

  canLoad(route: Route): Observable<boolean | UrlTree> {
    const { data } = route;
    const isPublicOnlyRoute = !!data?.['publicOnly'];

    return this.authModel.isLoggedIn$.pipe(
      take(1),
      map((isLogged) => {
        if (isPublicOnlyRoute) return isLogged === false;
        return isLogged === true;
      })
    );
  }
}
