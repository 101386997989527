import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OfficeRoleLabelComponent } from '../office-role-label/office-role-label.component';
import { OfficeRoleCode } from 'types';

@Component({
  selector: 'db-office-role-label-list',
  standalone: true,
  imports: [NgFor, OfficeRoleLabelComponent],
  templateUrl: './office-role-label-list.component.html',
  styleUrls: ['./office-role-label-list.component.scss'],
})
export class OfficeRoleLabelListComponent {
  @Input({ required: true }) officeRoleCodes: OfficeRoleCode[] = [];
  /** Controls whether the labels are shown as chip with icon and colorful background */
  @Input() displayAsChips = false;

  trackByFn = (_: number, item: OfficeRoleCode) => item;
}
