import * as configcat from 'configcat-js';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { API_URL, ENVIRONMENT, Environment, SentryService, isDevelopmentEnvironment, isProductionEnvironment } from 'common-module';
import {
  CONFIG_CAT_CACHE_INTERVAL,
  CONFIG_CAT_CACHE_INTERVAL_FOR_DEVELOPMENT,
  CONFIG_CAT_POLLING_MODE,
  CONFIG_CAT_PROXY,
  FEATURE_FLAG_DEFAULT_VALUE,
} from '../constants-feature-flags';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  private configcatClient: configcat.IConfigCatClient | null = null;

  private readonly apiUrl = inject<string>(API_URL);
  private readonly environment = inject<Environment>(ENVIRONMENT);
  private readonly sentryService = inject(SentryService);

  getFeatureFlagValue(flagName: string, userUuid: string, companyUuid: string): Observable<boolean> {
    const user = {
      identifier: userUuid,
      custom: {
        companyUuid: companyUuid,
      },
    };

    if (!this.configcatClient) {
      throw new Error('ConfigCat SDK is not initialized');
    }

    return from(this.configcatClient.getValueAsync(flagName, FEATURE_FLAG_DEFAULT_VALUE, user));
  }

  initialize() {
    this.configcatClient = configcat.getClient(CONFIG_CAT_PROXY, CONFIG_CAT_POLLING_MODE, {
      baseUrl: `${this.apiUrl}/configcat`,
      cacheTimeToLiveSeconds: isDevelopmentEnvironment(this.environment)
        ? CONFIG_CAT_CACHE_INTERVAL_FOR_DEVELOPMENT
        : CONFIG_CAT_CACHE_INTERVAL,
      logger: isProductionEnvironment(this.environment)
        ? configcat.createConsoleLogger(configcat.LogLevel.Off)
        : configcat.createConsoleLogger(configcat.LogLevel.Info),
      setupHooks: (hooks) =>
        hooks.on('clientError', (error: unknown) => {
          this.sentryService.handleError(error);
        }),
    });

    this.configcatClient.waitForReady().catch((err: HttpErrorResponse) => {
      console.error('ConfigCat SDK initialization failed', err);
    });
  }

  ngOnDestroy(): void {
    this.configcatClient?.dispose();
  }
}
