import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
  inject,
} from '@angular/core';
import { TreeNode } from '../typings/tree-node';
import { CommonModule } from '@angular/common';
import { ITreeNodeTemplateContext } from '../typings';
import { ExecPipe } from '../../../pipes';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'db-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ExecPipe, TooltipModule],
})
export class TreeNodeComponent {
  @ViewChildren(TreeNodeComponent) distributionTreeNodes!: QueryList<TreeNodeComponent>;

  @Input() level = 0;
  @Input() index = 0;
  @Input() node!: TreeNode<any, any>;
  @Input() indentSpacePx = 0;
  @Input() skipInitialIndent = false;
  @Input() treeNodeNameColumnWidthPx = 100;
  @Input() parentNode!: TreeNode<any, any>;
  @Input() hoveredItem!: any | null;
  @Input() parentNodeComponent: any | null = null;
  @Input() boldNamePredFn: (node: TreeNode<any, any>) => boolean = (_) => true;
  @Input() nodeClickEmitter!: EventEmitter<{ node: TreeNode<any, any>; level: number; index: number }>;
  @Input() nodeTemplateRef!: TemplateRef<ITreeNodeTemplateContext<TreeNodeComponent>>;

  @Output() hoverItem = new EventEmitter<TreeNodeComponent | null>();

  changeDetectorRef = inject(ChangeDetectorRef);

  get hasExpandButton(): boolean {
    return this.node.children.length > 0;
  }

  get treeNodeInfoWidth(): number {
    return this.treeNodeNameColumnWidthPx - 20 * this.level + this.level * this.indentSpacePx;
  }

  get hasMoreChildLevels(): boolean {
    return this.node.children.some((c) => c.children.length > 0);
  }

  isExpanded = false;
  isHovered = false;

  nodeClickHandler = (): void => {
    this.nodeClickEmitter.emit({ node: this.node, level: this.level, index: this.index });
  };

  toggleExpandHandler = () => {
    this.isExpanded = !this.isExpanded;
    this.hoverItem.emit(this.isHovered ? this : null);
  };

  toggleHoveredHandler = (isHovered?: boolean) => {
    if (typeof isHovered === 'boolean') {
      this.isHovered = isHovered;
    } else {
      this.isHovered = !this.isHovered;
    }
    this.hoverItem.emit(this.isHovered ? this : null);
    this.changeDetectorRef.markForCheck();
  };

  mouseenterListenerHandler = () => {
    this.isHovered = true;
    this.hoverItem.emit(this);
  };
  mouseleaveListenerHandler = () => {
    this.isHovered = false;
    this.hoverItem.emit(null);
  };

  hoverItemHandler = ($event: TreeNodeComponent | null) => {
    this.hoverItem.emit($event);
  };
}
