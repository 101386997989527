import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FAVOURITE_SPACES_STORE_NAME } from '../constants';
import { IFavoriteSpaces } from './reducer';

const getState = createFeatureSelector<IFavoriteSpaces>(FAVOURITE_SPACES_STORE_NAME);

const getLoadingResourceIds = createSelector(getState, (state: IFavoriteSpaces) => state.loadingResourceIds);

export const favoriteSpacesSelectors = {
  loadingResourceIds: getLoadingResourceIds
};
