<div id="tree">
  <div
    id="tree-node-info"
    [ngStyle]="{
      opacity: hoveredItem !== this && hoveredItem !== null ? 0.6 : 1
    }"
  >
    <div id="root-node-name" [style.width.px]="treeNodeNameColumnWidthPx">
      <span
        [ngClass]="{
          bold: rootNode | exec : boldNamePredFn,
          hovered: isHovered
        }"
        [pTooltip]="rootNode.name"
        [showDelay]="1000"
        tooltipPosition="top"
        (mouseenter)="mouseenterListenerHandler()"
        (mouseleave)="mouseleaveListenerHandler()"
      >
        {{ rootNode.name }}
      </span>

      <span
        *ngIf="rootNode.details"
        class="details"
        (mouseenter)="mouseenterListenerHandler()"
        (mouseleave)="mouseleaveListenerHandler()"
      >
        {{ rootNode.details }}
      </span>
    </div>
    <div
      id="root-node-content"
      [style.width]="'calc(100% - ' + treeNodeNameColumnWidthPx + 'px)'"
    >
      <ng-container *ngIf="nodeTemplateRef">
        <ng-container
          *ngTemplateOutlet="
            nodeTemplateRef;
            context: {
              $implicit: rootNode,
              level: 0,
              index: 0,
              parentNode: null,
              parentNodeComponent: null,
              toggleHoveredHandler: toggleHoveredHandler,
            }
          "
        ></ng-container>
      </ng-container>
    </div>
  </div>
  <div id="root-node-children">
    <div
      id="root-node-children-container"
      [style.marginLeft.px]="skipInitialIndent ? -indentSpacePx : 0"
    >
      <db-tree-node
        *ngFor="let node of rootNode.children; let idx = index"
        [node]="node"
        [index]="idx"
        [level]="1"
        [parentNode]="rootNode"
        [parentNodeComponent]="this"
        [boldNamePredFn]="boldNamePredFn"
        [skipInitialIndent]="skipInitialIndent"
        [indentSpacePx]="indentSpacePx"
        [nodeTemplateRef]="nodeTemplateRef"
        [treeNodeNameColumnWidthPx]="treeNodeNameColumnWidthPx"
        [hoveredItem]="hoveredItem"
        (hoverItem)="hoverItemHandler($event)"
      ></db-tree-node>
    </div>
  </div>
</div>
