import { S } from '@angular/cdk/keycodes';
import { Component, HostBinding, Input, SimpleChanges, inject } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { FeatureFlagModel, TeamsService } from 'auth-module';
import { RouterModel } from 'router-module';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'db-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  // NOTE: prevent default behavior for html title attribute
  @HostBinding('attr.title') get getTitle(): null {
    return null;
  }

  featureFlagModel = inject(FeatureFlagModel);
  teamsService = inject(TeamsService);
  routerModel = inject(RouterModel);

  teamsAppInitialized$ = this.teamsService.initialized$;
  sub = new Subscription();
  showIcon = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hideIcons']) {
      this.teamsAppInitialized$.pipe(take(1)).subscribe((teamsAppInitialized) => {
        if (this.hideIcons && teamsAppInitialized) {
          this.showIcon = false;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  @Input() link: string | any[] | null = null;
  @Input() linkActive = 'active';
  @Input() linkActiveOptions: { exact: boolean } = { exact: false };
  @Input() isExternalLink = false;
  @Input() skipLocationChange = false;
  @Input() hideIcons = false;
  @Input() tooltip: string | undefined = undefined;

  @Input() queryParamsHandling: QueryParamsHandling = 'preserve';
  @Input() queryParams: { [key: string]: string } = {};

  @Input() linkClasses: string[] = [];

  @Input() imgSrc: string | null = null;
  @Input() titleImgSrc: string | null = null;
  @Input() title = '';
  @Input() subtitle: string | null = null;

  @Input() disabled = false;
  @Input() loading = false;

  @Input() style: 'webapp' | 'admin' | 'nav-with-border' | 'nav-with-border-admin' = 'webapp';
  @Input() dataTestId = '';

  get isNavWithBorder(): boolean {
    return this.style === 'nav-with-border' || this.style === 'nav-with-border-admin';
  }

  get linkClassesWithBoldCheck(): string[] {
    return this.linkClasses.concat(this.isNavWithBorder ? ['small-text-bold'] : []);
  }
}
