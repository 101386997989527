<div id="tree-node-container">
  <div
    id="tree-node-info"
    [ngStyle]="{
      opacity: hoveredItem !== this && hoveredItem !== null ? 0.6 : 1
    }"
  >
    <div
      id="tree-node-name-action-holder"
      (click)="toggleExpandHandler()"
      [style.width.px]="
        treeNodeNameColumnWidthPx -
        (skipInitialIndent ? level - 1 : level) * indentSpacePx +
        (skipInitialIndent ? level * indentSpacePx : 0)
      "
      [style.paddingLeft.px]="level * indentSpacePx"
    >
      <div id="tree-node-name-action-holder-container">
        <div id="tree-node-action" *ngIf="hasExpandButton">
          <i
            [ngClass]="{
              'toggle-minus': isExpanded,
              'toggle-plus': !isExpanded
            }"
          ></i>
        </div>

        <div
          id="tree-node-name"
          (mouseenter)="mouseenterListenerHandler()"
          (mouseleave)="mouseleaveListenerHandler()"
          [style.paddingLeft.px]="!hasExpandButton ? 20 : 0"
        >
          <span
            [ngClass]="{
              bold: node | exec : boldNamePredFn,
              hovered: isHovered
            }"
            [pTooltip]="node.name"
            [showDelay]="1000"
            tooltipPosition="top"
            >{{ node.name }}</span
          >
          <span *ngIf="node.details" class="details">
            {{ node.details }}
          </span>
        </div>
      </div>
    </div>

    <div
      id="tree-node-content"
      [style.width]="
        'calc(100% - ' +
        (treeNodeNameColumnWidthPx -
          (skipInitialIndent ? level - 1 : level) * indentSpacePx +
          (skipInitialIndent ? level * indentSpacePx : 0)) +
        'px)'
      "
    >
      <div id="tree-node-content-container">
        <ng-container *ngIf="nodeTemplateRef">
          <ng-container
            *ngTemplateOutlet="
              nodeTemplateRef;
              context: {
                $implicit: node,
                level: level,
                index: index,
                parentNode: parentNode,
                parentNodeComponent: parentNodeComponent,
                toggleHoveredHandler: toggleHoveredHandler,
                isHovered: isHovered,
                toggleExpandHandler: toggleExpandHandler
              }
            "
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div id="tree-node-children">
    <div
      id="line"
      *ngIf="isExpanded && hasMoreChildLevels"
      [style.left.px]="(level + 1) * indentSpacePx + 5.5"
    ></div>
    <div id="tree-node-children-holder" *ngIf="isExpanded">
      <db-tree-node
        *ngFor="let node of node.children; let idx = index"
        [node]="node"
        [index]="idx"
        [level]="level + 1"
        [parentNode]="node"
        [parentNodeComponent]="this"
        [nodeTemplateRef]="nodeTemplateRef"
        [skipInitialIndent]="skipInitialIndent"
        [indentSpacePx]="indentSpacePx"
        [boldNamePredFn]="boldNamePredFn"
        [treeNodeNameColumnWidthPx]="treeNodeNameColumnWidthPx"
        [hoveredItem]="hoveredItem"
        (hoverItem)="hoverItemHandler($event)"
      ></db-tree-node>
    </div>
  </div>
</div>
