import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ApplicationModule } from 'common-module';
import { RouterModel } from 'router-module';
import { map, Observable, take, zip } from 'rxjs';
import { hasAccessToPageFactory } from 'shared';
import { UserRole } from 'types';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeActivate implements CanActivate {
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);
  router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const { data } = route;
    const { navigated } = this.router;

    const deskbirdAdminOnly = !!data?.['deskbirdAdminOnly'];
    const userRolesRestrictions: UserRole[] | null = (data?.['userRoles'] as UserRole[]) || null;
    const appModuleRestrictions: ApplicationModule | null = (data?.['appModuleRestrictions'] as ApplicationModule) || null;

    return zip(
      this.authModel.user$,
      this.authModel.selectors.corporateInfo$,
      this.routerModel.isAdminAppEnv$,
      this.authModel.selectors.isDeskbirdAdmin$,
      this.routerModel.selectors.url$,
      this.authModel.userRoleCompanyPageRestrictions$
    ).pipe(
      map(([user, corporateInfo, isAdminAppEnv, isDeskbirdAdmin, url, userRoleCompanyPageRestrictions]) => {
        if (!user || !corporateInfo) return navigated ? false : this.router.parseUrl(`/login?redirectUrl=${url}`);
        if (deskbirdAdminOnly)
          return !isDeskbirdAdmin
            ? this.router.parseUrl(`${isAdminAppEnv ? this.routerModel.adminPartPrefix : this.routerModel.clientPartPrefix}default`)
            : true;
        if (isDeskbirdAdmin) return true;

        const userRoleCompanyPageRestrictionsForEnv =
          (isAdminAppEnv ? userRoleCompanyPageRestrictions.admin : userRoleCompanyPageRestrictions.client) || null;

        const hasUserRoleCompanyPageRestriction = !(userRoleCompanyPageRestrictionsForEnv
          ? hasAccessToPageFactory(userRoleCompanyPageRestrictionsForEnv)(route.url.map((s) => s.path).join('/'))
          : true);

        let canContinue = false;
        if (!appModuleRestrictions && !userRolesRestrictions && !hasUserRoleCompanyPageRestriction) {
          canContinue = true;
        } else {
          if (!hasUserRoleCompanyPageRestriction && appModuleRestrictions && corporateInfo[appModuleRestrictions]) {
            canContinue = true;
          }
          if (!hasUserRoleCompanyPageRestriction && userRolesRestrictions && userRolesRestrictions.includes(user.role)) {
            canContinue = true;
          }
        }
        if (!canContinue)
          return !navigated
            ? this.router.parseUrl(`${isAdminAppEnv ? this.routerModel.adminPartPrefix : this.routerModel.clientPartPrefix}default`)
            : false;

        return true;
      }),
      take(1)
    );
  }
}
