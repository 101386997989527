<p-selectButton
  [options]="$any(options)"
  [optionLabel]="$any(optionLabel)"
  [optionValue]="$any(optionValue)"
  [multiple]="multiple"
  [ngClass]="{ 'more-options-right': moreOptionsToTheRight }"
>
  <ng-template let-item pTemplate>
    <ng-container
      *ngTemplateOutlet="template; context: { $implicit: item }"
    ></ng-container>
  </ng-template>
</p-selectButton>
