import { isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID, Provider } from "@angular/core";
import { WINDOW } from "../constants";

export const windowProvider: Provider = {
  provide: WINDOW,
  useFactory: (platformId: string) => {
    if (isPlatformBrowser(platformId)) { return window; }
    console.warn('Window provider not implemented for environment platform!');
    return {};
  },
  deps: [PLATFORM_ID]
};
