export function comparer(initialValue: any, currentValue: any, compareObjectFieldFn: any = null): boolean {
  if (Array.isArray(initialValue) && Array.isArray(currentValue)) {
    if (initialValue.length !== currentValue.length) { return false; }
    const result = initialValue.reduce((acc, value, idx) => {
      return acc && comparer(value, currentValue[idx], compareObjectFieldFn);
    }, true);
    return result;
  } else if (
    typeof initialValue === 'object' && initialValue !== null &&
    typeof currentValue === 'object' && currentValue !== null
  ) {
    const initialKeys = Object.keys(initialValue);
    const currentKeys = Object.keys(currentValue);
    const result = initialKeys.length === currentKeys.length && initialKeys.reduce((acc, key) => {
      return acc && (compareObjectFieldFn ?
        compareObjectFieldFn(initialValue[key]) === compareObjectFieldFn(currentValue[key]) :
        comparer(initialValue[key], currentValue[key], compareObjectFieldFn));
    }, true);
    return result;
  }

  if (
    typeof initialValue === 'number' && typeof currentValue === 'number' &&
    isNaN(initialValue) && isNaN(currentValue)
  ) { return true; }

  if (initialValue === 0 && currentValue === 0) { return (1 / initialValue) === (1 / currentValue); }

  return initialValue === currentValue;
}