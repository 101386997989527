<p-dropdown
  [attr.data-testId]="dataTestId"
  [options]="$any(options)"
  [optionLabel]="$any(optionLabel)"
  [optionValue]="$any(optionValue)"
  [optionDisabled]="optionDisabled || ''"
  [filter]="$any(filter)"
  [styleClass]="styleClass || ''"
  [style]="style"
  [panelStyleClass]="panelStyleClass || ''"
  [filterBy]="$any(filterBy)"
  [placeholder]="$any(placeholder)"
  [emptyFilterMessage]="$any(emptyFilterMessage)"
  [readonly]="!!readonly"
  [resetFilterOnHide]="!!resetFilterOnHide"
  [appendTo]="appendTo"
  [group]="!!group"
  [ngModel]="selectedValue"
  [disabled]="!!disabled"
  [autoDisplayFirst]="!!autoDisplayFirst"
  [showClear]="!!showClear"
  (ngModelChange)="selectedValueChangeHandler($event)"
>
  <ng-container *ngIf="templatesAsInput">
    <ng-template
      *ngFor="let template of templatesAsInput"
      let-item
      [pTemplate]="template.name"
      [type]="template.type"
    >
      <ng-container
        *ngTemplateOutlet="template.template; context: { $implicit: item }"
      ></ng-container>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!templatesAsInput">
    <ng-template
      *ngFor="let template of templates"
      let-item
      [pTemplate]="template.name"
      [type]="template.type"
    >
      <ng-container
        *ngTemplateOutlet="template.template; context: { $implicit: item }"
      ></ng-container>
    </ng-template>
  </ng-container>
</p-dropdown>
