<div
  class="picker-container"
  dbClickOutside
  (clickOutside)="isPickerVisible = false"
>
  <div class="trigger" (click)="isPickerVisible = !isPickerVisible">
    <db-emoji
      *ngIf="value; else defaultTrigger"
      [emojiHtml]="value"
      [dataTestId]="ctaDataTestId"
    >
    </db-emoji>
    <ng-template #defaultTrigger>
      <img
        [attr.dataTestId]="ctaDataTestId"
        class="emoji"
        src="assets/icons/blushing.svg"
      />
    </ng-template>
  </div>

  <emoji-mart
    *ngIf="isPickerVisible"
    [i18n]="i18n"
    [emoji]="selectedEmoji?.colons || ''"
    [darkMode]="false"
    [emojiTooltip]="false"
    [backgroundImageFn]="backgroundImageFn"
    (emojiSelect)="emojiSelectHandler($event)"
  ></emoji-mart>
</div>
