import { createFeatureSelector, createSelector } from "@ngrx/store";
import { INTERNALIZATION_STORE_NAME } from "../constants";
import { IInternalizationState } from "./reducer";

const getState = createFeatureSelector<IInternalizationState>(INTERNALIZATION_STORE_NAME);

const getLocale = createSelector(getState, state => state.locale);

export const internalizationSelectors = {
  locale: getLocale
};