import { Injectable } from "@angular/core";
import { LocalStorageService } from "common-module";
import { AvailableLocales, LOCALE } from "shared";

const availableLanguagesArray = Object.values(AvailableLocales).filter(value => typeof value === 'string') as AvailableLocales[];

@Injectable({ providedIn: 'root' })
export class i18nService {
  currentLocale: string;

  constructor(
    private localStorageService: LocalStorageService) {
    const locale = (localStorageService.getItem(LOCALE) || null) as AvailableLocales;
    this.currentLocale = availableLanguagesArray.includes(locale) ? locale : AvailableLocales.English;
  }

  changeLanguage(locale: string): void {
    this.currentLocale = availableLanguagesArray.includes(locale as AvailableLocales) ? locale : AvailableLocales.English;
    this.localStorageService.setItem('locale', this.currentLocale);
    location.reload();
  }
}