export enum ErrorCode {
  TOKEN_EXPIRED = 'tokenExpired',
  TOKEN_REVOKED = 'tokenRevoked',
  TOKEN_INCOMPATIBLE = 'tokenIncompatible',
  EMAIL_UNVERIFIED = 'emailUnverified',
}

export enum ModuleAccess {
  BOOKING_MODULE = 'allowsResourceBooking',
  PLANNING_MODULE = 'allowsScheduling'
}

export enum FeatureRuleNames {
  ColleaguesPlanning = 'ColleaguesPlanning',
  InteractiveFloorPlans = 'InteractiveFloorPlans'
}