import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeskAreaType, IBookingCheckInParams, IBookingViewProps, IUser } from 'types';
import { ClickFavoriteSpaceEntryPoint } from '../../enums';
import { NgForOf, NgIf } from '@angular/common';
import { BookingCardItemComponent } from 'db-ui';

@Component({
  selector: 'db-booking-cards',
  templateUrl: './booking-cards.component.html',
  styleUrls: ['./booking-cards.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, BookingCardItemComponent],
})
export class BookingCardsComponent {
  readonly deskAreaType = DeskAreaType;
  @Input() bookings!: IBookingViewProps[];
  @Input() isResolving!: boolean;
  @Input() authUser: IUser | undefined;
  @Input() gtmEntryPoint!: ClickFavoriteSpaceEntryPoint;
  @Input() showIfEmptyMessage = false;
  @Input() fitDetailsOnOneLine = false;
  @Output() bookingClick = new EventEmitter<void>();
  @Output() bookingCheckIn = new EventEmitter<IBookingCheckInParams>();

  trackByFn = (_: number, { id }: IBookingViewProps) => id;

  checkInHandler(booking: IBookingViewProps): void {
    this.bookingCheckIn.emit({
      bookingId: booking.id,
      workspaceId: booking.workspaceId,
      zoneId: booking.zoneId,
      zoneItemId: booking.zoneItemId,
      guestId: booking.guestId,
    });
  }

  bookingItemClickHandler() {
    this.bookingClick.emit();
  }
}
