import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store';

import { ICompany, IDemoCompany } from 'common-module';
import {
  IAdminStatusRequest,
  IDeleteDemoBookingRequest,
  IDemoBookingRequest,
  IMigrateFromBookMyDeskRequest,
  IMigrateFromBookMyDeskResponse,
  IQRCodeGenerationRequest,
  ISearchBookMyDeskCompanyRequest,
  ISearchBookMyDeskCompanyResponse,
  ISearchCompanyRequest,
  ISearchUserRequest,
  IUpdateCompanyPrivacySettingsRequest,
  IUpdateCompanyStatusRequest,
  IUpdateCoreModulesRequest,
  IUpdateDomainsRequest,
  IUpdateInviteOnlyAccessRequest,
  IUpdateLoginMethodsRequest,
  IUserCustomClaims,
} from '../types';
import { IUser, IDeskArea, IBaseResponse } from 'types';

const factory = forNamespace('[ADMINISTRATION]');

export const loadUsersBundle = factory.asyncAction('loadUsers', undefined, props<{ users: IUser[] }>(), props<{ error: any }>());

export const deleteDemoBookingsBundle = factory.asyncAction('deleteDemoBookings', props<{request: IDeleteDemoBookingRequest}>(), props<{ response: IBaseResponse<any> }>(), props<{ error: any }>());

export const createDemoBookingsBundle = factory.asyncAction('createDemoBookings', props<{request: IDemoBookingRequest}>(), props<{ response: IBaseResponse<any> }>(), props<{ error: any }>());

export const loadDeskAreasBundle = factory.asyncActionWithCleanup('loadDeskAreas', props<{ officeId: string }>(), props<{ deskAreas: IDeskArea[] }>(), props<{ error: any }>());

export const setDeskAreasBundle = factory.singleAction('setDeskAreas', props<{ deskAreas: IDeskArea[] }>());

export const changeAdminStatusBundle = factory.asyncAction('changeAdminStatus', props<{ request: IAdminStatusRequest }>(), props<{ userClaimsGroup: string }>(), props<{ error: any }>());

export const updateBusinessCompanyBundle = factory.asyncAction('updateBusinessCompany', props<{ companyId: string; request: IUpdateCoreModulesRequest | IUpdateCompanyStatusRequest | IUpdateLoginMethodsRequest | IUpdateInviteOnlyAccessRequest | IUpdateCompanyPrivacySettingsRequest }>(), props<{ company: ICompany }>(), props<{ error: any }>());

export const getUserCustomClaimsBundle = factory.asyncAction('getUserCustomClaims', props<{ firebaseUserId: string }>(), props<{ userCustomClaims: IUserCustomClaims }>(), props<{ error: any }>());

export const updateDomainsBundle = factory.asyncAction('updateDomains', props<{ request: IUpdateDomainsRequest }>(), props<{ response: IBaseResponse<any> }>(), props<{ error: any }>());

export const createDemoCompany = factory.asyncAction('createDemoCompany', props<{ demoCompany: IDemoCompany }>(), props<{ company: ICompany }>(), props<{ error: any }>());

export const searchBookMyDeskCompanyBundle = factory.asyncAction('searchBookMyDeskCompany', props<{ request: ISearchBookMyDeskCompanyRequest }>(), props<{ response: ISearchBookMyDeskCompanyResponse }>(), props<{ error: any }>());

export const migrateFromBookMyDeskBundle = factory.asyncAction('migrateFromBookMyDesk', props<{ request: IMigrateFromBookMyDeskRequest }>(), props<{ response: IMigrateFromBookMyDeskResponse }>(), props<{ error: any }>());

export const searchUsersBundle = factory.asyncAction('searchUsers', props<{ request: ISearchUserRequest }>(), props<{ users: any[]; totalCount: number }>(), props<{ error: any }>());

export const searchCompanyBundle = factory.asyncAction('searchCompany', props<{ request: ISearchCompanyRequest }>(), props<{ company: any }>(), props<{ error: any }>());

export const loadInactiveCompaniesBundle = factory.asyncAction('loadInactiveCompanies', undefined, props<{ companies: ICompany[] }>(), props<{ error: any }>());

export const deleteInactiveCompanyBundle = factory.asyncAction('deleteInactiveCompany', props<{ companyId: string, companyName: string }>(), props<{ companyId: string }>(), props<{ error: any }>());

export const administrationBundles = [
  loadUsersBundle,
  deleteDemoBookingsBundle,
  createDemoBookingsBundle,
  changeAdminStatusBundle,
  updateBusinessCompanyBundle,
  getUserCustomClaimsBundle,
  updateDomainsBundle,
  createDemoCompany,
  loadDeskAreasBundle,
  setDeskAreasBundle,
  searchBookMyDeskCompanyBundle,
  migrateFromBookMyDeskBundle,
  searchUsersBundle,
  searchCompanyBundle,
  loadInactiveCompaniesBundle,
  deleteInactiveCompanyBundle,
];
