import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonIconComponent } from '../button-icon';

@Component({
  selector: 'db-ui-slide-over-content',
  standalone: true,
  imports: [CommonModule, ButtonIconComponent],
  templateUrl: './ui-slide-over-content.component.html',
  styleUrls: ['./ui-slide-over-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSlideOverContentComponent {
  @Output() slideOverClosed = new EventEmitter<void>();

  slideOverClosedHandler(): void {
    this.slideOverClosed.emit();
  }
}
