<db-menu-item
  [link]="[adminPartPrefix, 'default']"
  [style]="'nav-with-border'"
  *ngIf="
    (isUserDeskbirdAdmin ||
      isUserAdmin ||
      isUserManager ||
      isUserGroupManager ||
      isUserOfficeAdmin) &&
    ([adminPartPrefix, 'default'] | exec : hasAccessToPage)
  "
  [hideIcons]="hideIcons"
  imgSrc="/assets/icons/admin.svg"
  title="Admin"
  i18n-title="@@navigation-module|client-navigation|admin"
>
</db-menu-item>

<hr
  *ngIf="
    (isUserDeskbirdAdmin ||
      isUserAdmin ||
      isUserManager ||
      isUserGroupManager ||
      isUserOfficeAdmin) &&
    ([adminPartPrefix, 'default'] | exec : hasAccessToPage)
  "
/>

<db-menu-item
  *ngIf="
    hasPlanningAccess &&
    ([clientPartPrefix, 'planning'] | exec : hasAccessToPage)
  "
  [style]="'webapp'"
  imgSrc="/assets/icons/scheduling_new.svg"
  title="Schedule"
  [link]="[clientPartPrefix, 'planning']"
  [hideIcons]="hideIcons"
  i18n-title="@@navigation-module|client-navigation|plan"
>
</db-menu-item>

<db-menu-item
  *ngIf="
    hasBookingAccess &&
    ([
      clientPartPrefix,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'bookings',
      'dashboard'
    ] | exec : hasAccessToPage)
  "
  [link]="[
    clientPartPrefix,
    officeId ? 'office' : 'office-redirect',
    officeId ? officeId : 'default',
    'bookings',
    'dashboard'
  ]"
  [queryParams]="{ allBookings: $any(undefined) }"
  [dataTestId]="'navigation--book--item'"
  [style]="'webapp'"
  imgSrc="/assets/icons/book.svg"
  title="Book"
  [queryParamsHandling]="'merge'"
  [hideIcons]="hideIcons"
  i18n-title="@@navigation-module|client-navigation|book"
>
</db-menu-item>

<db-menu-item
  *ngIf="
    hasBookingAccess &&
    ([clientPartPrefix, 'office', ALL_OFFICES, 'bookings']
      | exec : hasAccessToPage)
  "
  [link]="[clientPartPrefix, 'office', ALL_OFFICES, 'bookings']"
  [style]="'webapp'"
  [queryParams]="{ isFullDay: $any(undefined) }"
  imgSrc="/assets/icons/bookings_new.svg"
  title="Bookings"
  [queryParamsHandling]="'merge'"
  [dataTestId]="'navigation--bookings--item'"
  [hideIcons]="hideIcons"
  i18n-title="@@navigation-module|client-navigation|bookings"
>
</db-menu-item>

<div class="footer">
  <div class="support-info">
    <a
      class="cta-help-center small-text"
      href="https://deskbird.zendesk.com/hc/en-us"
      target="_blank"
    >
      <img
        src="assets/icons/help-center-purple.svg"
        alt="help"
        class="help-icon"
      />
      <div class="text" i18n="@@navigation-module|help-center">Help center</div>
      <img src="assets/icons/link-purple.svg" alt="link" class="link-icon" />
    </a>
  </div>
  <hr />

  <div
    class="menu-trigger"
    (click)="menu.toggle($event)"
  >
    <db-user-info
      [onlyImage]="true"
      [userInfo]="userInfo"
      [avatarSize]="'standard'"
      data-testid="navigation--profile--menu-trigger"
    ></db-user-info>
  </div>
  <db-menu
    #menu
    [items]="userMenuItems"
    [popup]="true"
  ></db-menu>
</div>
