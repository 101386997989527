import { Component, inject } from '@angular/core';
import { TeamsService } from '../services/teams.service';
import { filter, take } from 'rxjs';

@Component({
  selector: 'db-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {

  teamsService: TeamsService = inject(TeamsService);
  teamsInitialized$ = this.teamsService.initialized$;

  goToDeskbird(e: MouseEvent) {
    this.teamsInitialized$.pipe(take(1), filter(Boolean)).subscribe(() => {
      // make sure we do not redirect in teams context
      e.preventDefault();      
    });
  }
}
