import { createReducer, on } from '@ngrx/store';
import { loadFeatureFlagBundle } from './bundles';
import { FeatureFlag } from 'types';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface IFeatureFlag {
  flag: FeatureFlag;
  value: boolean;
}

export interface IFeatureFlagState extends EntityState<IFeatureFlag> {}

const adapter = createEntityAdapter<IFeatureFlag>({
  selectId: (entity: IFeatureFlag) => entity.flag,
});

const { selectEntities } = adapter.getSelectors();

export const featureFlagReducer = createReducer(
  adapter.getInitialState({}),

  on(loadFeatureFlagBundle.loadFlagSuccess, (state, payload) => {
    const newState = adapter.upsertOne(payload, state);
    return newState;
  })
);

export const selectAllFeatureFlags = selectEntities;
