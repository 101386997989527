import { Injectable } from '@angular/core';
import { authentication, app } from '@microsoft/teams-js';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  initialized$ = new BehaviorSubject<null | boolean>(null);
  currentContextValue: null | app.Context = null;

  authentication = authentication;
  getContext = app.getContext;

  constructor() {
    const teamsApp = app.initialize();

    teamsApp
      .then(() => {
        this.initialized$.next(true);
        return app.getContext();
      })
      .then((context) => {
        this.currentContextValue = context;
      })
      .catch((_err) => {
        this.initialized$.next(false);
      });
  }
}
