import { IUserInfo } from 'types';
import { UserMultiselectType } from '../enums/user-multiselect-type';

/** A multiselect user entity could be any entity that describes one or few users in a unique way e.g. individual user, a group of users, all users within a given office etc.*/
export interface IUserMultiselectEntity {
  type: UserMultiselectType;
  /** Value is combination between type and entityId so that it's always unique */
  value: string;
  label: string;

  /** Available only if type is user */
  userEntity?: IUserInfo;
  /** Available only if type is group */
  groupEntity?: { type: UserMultiselectType.USER_GROUP; id: string; name: string; membersCount: number };
  /** Available only if type is office-users */
  officeEntity?: { type: UserMultiselectType.OFFICE_USERS; id: string; name: string; membersCount: number };
}
