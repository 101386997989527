import { forNamespace } from 'ngrx-action-bundles';
import { RequestMethod } from '../enums/request-method';
import { props } from '@ngrx/store';

const factory = forNamespace('UPLOAD');

export interface IUploadFailure {
  uuid: string;
  body?: any;
  error?: any;
  timestamp: number;
}

export interface IUploadAction {
  files: File[];
  location: string;
  csrf?: { token: string; headerName: string };
  formDataName: string;
  uuid: string;
  additionalData?: any;
  requestMethod?: RequestMethod.PUT | RequestMethod.POST | RequestMethod.PATCH | undefined;
}

export const uploadBundle = factory.asyncActionWithCleanup(
  'upload',
  props<IUploadAction>(),
  props<{ uuid: string; body?: any; timestamp: number }>(),
  props<IUploadFailure>(),
  props<{ uuid: string; timestamp: number }>(),
  props<{ uuid: string; timestamp: number }>(),
  true
);

export const uploadProgressBundle = factory.singleAction('uploadProgress', props<{ progress: number; uuid: string }>());

export const uploadBundles = [uploadBundle, uploadProgressBundle];
