import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT_H24 } from '../constants';

/** Parse date (in any format) and time (@type {TIME_FORMAT_H24}) strings in office time zone to ISO 8601 UTC format */
export const convertDateAndTimeToIso8601String = (date: string, time: string, officeTimeZone: string) => {
  if (!date || !time) {
    return '';
  }

  const dateAndTime = moment()
    .tz(officeTimeZone)
    .set({
      year: moment(date).year(),
      month: moment(date).month(),
      date: moment(date).date(),
      hour: moment(time, TIME_FORMAT_H24).hours(),
      minute: moment(time, TIME_FORMAT_H24).minutes(),
      second: 0,
      millisecond: 0,
    });

  const iso8601Format = dateAndTime.toISOString();
  return iso8601Format;
};

/** Parse string in ISO 8601 UTC format to date time (@type {DATE_FORMAT} / @type {TIME_FORMAT_H24}) format in office time zone used by prime controls   */
export const convertFromIso8601ToDateTimeString = (value: string | undefined, type: 'date' | 'time', officeTimeZone: string): string => {
  if (!value) {
    return '';
  }

  const dateTime = moment.utc(value).tz(officeTimeZone);
  return type === 'date' ? dateTime.format(DATE_FORMAT) : dateTime.format(TIME_FORMAT_H24);
};
