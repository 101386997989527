import { Injectable } from "@angular/core";
import { AuthModel } from "auth-module";
import { IOffice } from "common-module";
import { combineLatest, distinctUntilChanged, map, Observable } from "rxjs";
import { globalBundles } from "./bundles";
import { globalSelectors } from "./selectors";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";

@Injectable({ providedIn: "root" })
export class GlobalModel {
  selectors = connectSelectors(globalSelectors);
  actions = connectBundles(globalBundles);

  adminAppOffices$: Observable<IOffice[] | null>;
  clientAppOffices$: Observable<IOffice[] | null>;
  clientAppAccessibleOffices$: Observable<IOffice[] | null>;

  noAdminAppOffices$: Observable<boolean>;
  noClientAppOffices$: Observable<boolean>;

  constructor(private authModel: AuthModel) {
    this.adminAppOffices$ = combineLatest([
      this.selectors.allOffices$,
      this.selectors.adminAppCompanyId$.pipe(distinctUntilChanged()),
      this.authModel.user$,
    ]).pipe(
      map(([offices, companyId, user]) => {
        if (!offices || !companyId || !user) {
          return null;
        }
        return (
          offices?.filter(
            (o) =>
              o.businessCompanyId === companyId &&
              user?.officeIds.includes(o.id),
          ) || []
        );
      }),
    );

    this.clientAppOffices$ = combineLatest([
      this.selectors.allOffices$,
      this.selectors.clientAppCompanyId$.pipe(distinctUntilChanged()),
      this.authModel.selectors.isDeskbirdAdmin$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([offices, companyId, isDeskbirdAdmin]) => {
        if (!offices || !companyId) {
          return null;
        }
        // FIXME: looks like it is already filtered (by ID) in the backend
        return isDeskbirdAdmin
          ? offices
          : offices.filter(
              (o) => o.businessCompanyId === companyId && o.isActive,
            );
      }),
    );

    this.clientAppAccessibleOffices$ = combineLatest(
      this.clientAppOffices$,
      this.authModel.user$,
    ).pipe(
      map(([clientAppOffices, user]) => {
        return !!user?.accessibleOfficeIds
          ? clientAppOffices?.filter((o) =>
              user?.accessibleOfficeIds?.includes(o.id),
            ) || null
          : clientAppOffices;
      }),
    );

    this.noAdminAppOffices$ = this.adminAppOffices$.pipe(
      map((o) => !!o && o.length === 0),
    );
    this.noClientAppOffices$ = this.clientAppOffices$.pipe(
      map((o) => !!o && o.length === 0),
    );
  }
}
