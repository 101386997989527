import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { RadioButtonModule } from "primeng/radiobutton";
import { TooltipModule } from "primeng/tooltip";

import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

import { appProviders, IApplicationProviderData } from "./providers";

import { RedirectComponent } from "./components/redirect/redirect.component";
import { SlideOverFrameComponent } from "./components/slide-over-frame/slide-over-frame.component";
import { UserSpacesCardsComponent } from "./components/user-spaces-cards/user-spaces-cards.component";

import { DelayVisibilityDirective } from "./directives";

import { OutletNavigationPipe } from "./pipes";

import {
  EmailUniqueValidatorDirective,
  PasswordValidatorDirective,
} from "./validators";
import {
  AsyncValueValidatorDirective,
  ExecPipe,
  FormatDateTimePipe,
  GetTimeFormatStringForUser,
  GtmEventDirective,
  HiddenDirective,
  HoverContainerDirective,
  InfoLabelComponent,
  ProgressBarComponent,
  TimeZoneAbbreviationPipe,
} from "shared";
import { IsSpaceBeingBookedPipe } from "./components/user-spaces-cards/is-space-being-booked.pipe";
import {
  ButtonIconComponent,
  BookingStartEndTimeComponent,
  BookingStatusComponent,
  AreaRestrictionIconComponent,
  BookingCardItemComponent,
  MenuComponent,
} from "db-ui";

const primeImports = [RadioButtonModule, TooltipModule];

const components = [RedirectComponent, UserSpacesCardsComponent];

const pipes = [OutletNavigationPipe, IsSpaceBeingBookedPipe];

const directives = [
  DelayVisibilityDirective,
  PasswordValidatorDirective,
  EmailUniqueValidatorDirective,
];

const sharedImports = [
  ExecPipe,
  HiddenDirective,
  InfoLabelComponent,
  ProgressBarComponent,
  GtmEventDirective,
  HoverContainerDirective,
  TimeZoneAbbreviationPipe,
  FormatDateTimePipe,
  GetTimeFormatStringForUser,
  AsyncValueValidatorDirective,
];

const dbUiImports = [
  ButtonIconComponent,
  BookingStartEndTimeComponent,
  BookingStatusComponent,
  AreaRestrictionIconComponent,
  BookingCardItemComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    GoogleTagManagerModule,
    SlideOverFrameComponent,
    ...primeImports,
    ...sharedImports,
    ...dbUiImports,
    MenuComponent,
  ],
  declarations: [...pipes, ...directives, ...components],
  exports: [...pipes, ...directives, ...components],
})
export class DBCommonModule {
  static forRoot(
    config: IApplicationProviderData,
  ): ModuleWithProviders<DBCommonModule> {
    const ngxSkeletonProviders =
      NgxSkeletonLoaderModule.forRoot().providers || ([] as Provider[]);

    return {
      ngModule: DBCommonModule,
      providers: [...appProviders(config), ...ngxSkeletonProviders],
    };
  }
}
