import { Injectable, inject } from '@angular/core';
import { AvailableLocales } from 'shared';
import { DEFAULT_PRIME_DATE_FORMAT_MOMENT } from './constants';
import { PrimeNGConfig } from 'primeng/api';
import { PrimeNgTranslation } from './prime-ng-translation-utils';

@Injectable({ providedIn: 'root' })
export class PrimeNgTranslationService {
  currentLocale = AvailableLocales.English;
  availableLocales = Object.values(AvailableLocales).filter((value) => typeof value === 'string') as string[];

  private readonly config = inject(PrimeNGConfig);

  initTranslations(translation: PrimeNgTranslation) {
    if (translation) {
      this.config.setTranslation(translation);
    }
  }

  getDateFormatMoment(): string {
    try {
      return this.config.getTranslation('dateFormatMoment');
    } catch (error) {
      console.log(error);
      return DEFAULT_PRIME_DATE_FORMAT_MOMENT;
    }
  }
}
