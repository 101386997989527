import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { NgFor } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChipComponent } from '../chip';
import { IncludesPipe } from 'shared-pipes';

@Component({
  selector: 'db-chip-list',
  standalone: true,
  imports: [ChipComponent, NgFor, IncludesPipe],
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipListComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent {
  @Input() options: { label: string; value: string }[] = [];

  private onChange!: (value: string[]) => {};
  private onTouch!: () => {};
  cd = inject(ChangeDetectorRef);
  selectedValues: string[] = [];

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: string[]): void {
    this.selectedValues = obj;
    //this.cd.detectChanges();
  }

  buttonClickHandler(value: string): void {
    console.log('buttonClickHandler');

    if (this.selectedValues.includes(value)) {
      this.selectedValues = this.selectedValues.filter((v) => v !== value);
    } else {
      this.selectedValues = [...this.selectedValues, value];
    }
    this.onChange(this.selectedValues);
    this.onTouch();
  }
}
