import { OfficePlanningStatusOptionType, IOfficePlanningStatusOption, HybridPolicyRule } from 'types';

export const MISSING_SCHEDULING_OPTION: IOfficePlanningStatusOption = {
  id: '1',
  name: 'N/A',
  type: OfficePlanningStatusOptionType.Invalid,
  color: '#979797',
  order: 1,
  isPrimary: true,
  companyId: 'N/A',
  isActive: true,
  policyRule: HybridPolicyRule.NONE,
};
