import { TableColumnType } from 'types';

export interface IColumnConfig {
  field: string;
  header: string;
}

export interface IBulkActionColumnConfig {
  header: string;
  styleClass?: string; // Only global style classes (e.g. those existing in table.scss)
  columnType: TableColumnType;
  style?: { [klass: string]: unknown };
}
