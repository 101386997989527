import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'db-user-names',
  templateUrl: './user-names.component.html',
  styleUrls: ['./user-names.component.scss'],
})
export class UserNamesComponent {
  @Output() setNames = new EventEmitter<{ firstName: string; lastName: string }>();

  // NOTE: prevent default behavior for html title attribute
  @HostBinding('attr.title') get getTitle(): null {
    return null;
  }

  @Input() title: string = '';
  @Input() firstName: string = '';
  @Input() lastName: string = '';
}
