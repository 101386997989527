import { BookingStatus, CheckInStatus } from 'types/enums';
import { IApiErrorResponse } from '../api-error-response';
import { IBooking } from './booking';

export interface ICheckInBookingDto {
  qrCodeZoneItemId: number;
}

export interface ICheckInBookingResponse {
  checkInStatus: CheckInStatus;
}

export interface IUpdateBookingDto {
  bookingId: string;
  bookingStartTime?: number;
  bookingEndTime?: number;
  isAnonymous?: boolean;
  zoneItemId?: number;
  userId?: number;
}

export interface IUpdateBookingResponse {
  bookingAutoCancellationTime: string;
  bookingEndTime: string;
  bookingStartTime: string;
  bookingStatus: BookingStatus;
  checkInStatus: CheckInStatus;
  isAnonymousBooking: boolean;
  isDayPass: boolean;
  zoneItemId: number;
}

export interface ICreateBookingAttributes {
  bookingStartTime: number;
  bookingEndTime: number;
  isAnonymous: boolean;
  zoneItemId: number;
}

export interface ICreateBookingForEmployee extends ICreateBookingAttributes {
  resourceId: string;
  workspaceId: string;
  /** undefined when booking is for the auth user */
  userId?: number;
}

export interface IMultiDayBookingCreationFailureResult {
  booking: ICreateBookingAttributes;
  error: IApiErrorResponse;
}

export interface IMultiDayBookingsCreationResult {
  successfulBookings: IBooking[];
  failedBookings: IMultiDayBookingCreationFailureResult[];
}
