import { NgZone } from '@angular/core';
import { IAddressInfo } from './interfaces';

export function getLatLngFromLocation(location: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined) {
  const lat = (location && location.lat && typeof location.lat === 'function' ? location.lat() : location?.lat || null) as null | number;
  const lng = (location && location.lng && typeof location.lng === 'function' ? location.lng() : location?.lng || null) as null | number;
  return { lat, lng };
}

export function locateAddress(zone: NgZone, address: string): Promise<google.maps.GeocoderResult | null> {
  return new Promise((res, rej) => {
    zone.runOutsideAngular(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ address })
        .then(({ results }) => (results || [null])[0] || null)
        .then((data) => res(data))
        .catch((err) => rej(err));
    });
  });
}

export function locateCoordinates(zone: NgZone, lat: number, lng: number): Promise<google.maps.GeocoderResult | null> {
  return new Promise((res, rej) => {
    zone.runOutsideAngular(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ location: { lat, lng } })
        .then(({ results }) => (results || [null])[0] || null)
        .then((data) => res(data))
        .catch((err) => rej(err));
    });
  });
}

export function generateAddressInfo(data: google.maps.places.PlaceResult | google.maps.GeocoderResult, timeZone: string): IAddressInfo {
  const streetNumber = data.address_components?.find((c) => c.types.includes('street_number'))?.long_name || '';
  const street = data.address_components?.find((c) => c.types.includes('route'))?.long_name || '';
  const city = data.address_components?.find((c) => c.types.includes('locality'))?.long_name || '';
  const country = data.address_components?.find((c) => c.types.includes('country'))?.long_name || '';
  const postCode = data.address_components?.find((c) => c.types.includes('postal_code'))?.long_name || '';
  const lat = data.geometry && data.geometry.location ? data.geometry.location.lat() : 0;
  const lng = data.geometry && data.geometry.location ? data.geometry.location.lng() : 0;

  return {
    street,
    streetNumber,
    city,
    country,
    postCode,
    coordinates: { lat, lng },
    formattedAddress: data.formatted_address || '',
    timeZone,
  };
}

function normalizeAddress(address: string): string {
  return address
    .trim()
    .toLowerCase()
    .replace(/blvd.?/, 'boulevard')
    .replace(/st./, 'street');
}

export function areAddressesEqual(addr1: string | undefined | null, addr2: string | undefined | null): boolean {
  if (typeof addr1 === 'string' && typeof addr2 === 'string') {
    return normalizeAddress(addr1) === normalizeAddress(addr2);
  }
  return addr1 === addr2;
}
