import { Component, Input } from '@angular/core';

@Component({
  selector: 'db-circle-image',
  templateUrl: './circle-image.component.html',
  styleUrls: ['./circle-image.component.scss'],
  standalone: true,
})
export class CircleImageComponent {
  @Input() src!: string;
  @Input() alt: string = '';
}
