export interface IAddress {
  street: string;
  streetNo: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  timeZone?: string;
  coordinate?: {
    _latitude: 0,
    _longitude: 0
  }
}
