import { GoogleTagManagerDimensions } from './google-tag-manager-dimensions';

/**
 * Super/user properties that can change after being initially sent to GTM/Mixpanel on login (e.g. when the user changes their language or main office)
 */
export type GoogleTagManagerVariableProperty =
  | GoogleTagManagerDimensions.ALLOWS_RESOURCE_BOOKING
  | GoogleTagManagerDimensions.ALLOWS_OFFICE_PLANNING
  | GoogleTagManagerDimensions.MEETING_ROOM_SYNC_ENABLED
  | GoogleTagManagerDimensions.COMPANY_USER_VISIBILITY_RESTRICTION
  | GoogleTagManagerDimensions.USER_MAIN_OFFICE_ID
  | GoogleTagManagerDimensions.USER_ROLE
  | GoogleTagManagerDimensions.USER_OFFICE_ID
  | GoogleTagManagerDimensions.USER_AVATAR_ENABLED
  | GoogleTagManagerDimensions.USER_LANGUAGE
  | GoogleTagManagerDimensions.COMPANY_USE_PRIVATE_PROFILE;
