<button
  class="status-button"
  [ngClass]="styleClasses"
  [ngStyle]="styles"
  [disabled]="isDisabled"
  (click)="statusClickHandler()"
  [attr.data-testId]="dataTestId"
>
  <div class="label-wrapper">
    <ng-container *ngIf="buttonLabel; else statusLabel">
      <span>{{ buttonLabel }}</span>
    </ng-container>
    <ng-template #statusLabel>
      <span>
        <db-daily-status-option-label
          [status]="status"
        ></db-daily-status-option-label>
      </span>
    </ng-template>
    <span data-testid="schedule--office-name" *ngIf="officeName" class="very-small-text">{{ officeName }}</span>
  </div>
</button>
