import { MeetingRoom, MeetingRoomCalendarData, MeetingRoomCalendarEventData } from "types/interfaces";

export const getEventsForMeetingRoom = (
  meetingRoom: MeetingRoom,
  meetingRoomCalendarData: MeetingRoomCalendarData[]
): MeetingRoomCalendarEventData[] => {
  if (!meetingRoom || !meetingRoomCalendarData) {
    return [];
  }
  return meetingRoomCalendarData.find((d) => d.id === meetingRoom.id)?.events || [];
};
