import { OfficePlanningStatusOptionType, HybridPolicyRule } from '../enums';

export interface IOfficePlanningStatusInfo {
  color: string;
  officeName: string;
  name: string;
}

interface IOfficePlanningStatusOptionDtoAttributes {
  isNameUserDefined?: boolean;
}

export interface IOfficePlanningStatusOption extends IOfficePlanningStatusOptionDtoAttributes {
  id: string;
  color: string;
  name: string;
  type: OfficePlanningStatusOptionType;
  isActive: boolean;
  order: number;
  companyId: string;
  isPrimary: boolean;
  policyRule?: HybridPolicyRule;
}
