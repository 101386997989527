import { Pipe, PipeTransform } from '@angular/core';
import { AreaTranslationType } from '../enums';
import { DeskAreaType } from 'types';
import { getMappedAreaSuffixValue } from '../utils';

@Pipe({
  name: 'areaTypeTranslation',
  standalone: true,
})
export class AreaTypeTranslationPipe implements PipeTransform {
  readonly areaNames: { [key: string]: string } = {
    desk: $localize`:@@common|desk-area-name:Desk area`,
    'meeting room': $localize`:@@common|meeting-room-area-name:Meeting room area`,
    'parking spot': $localize`:@@common|parking-area-name:Parking area`,
    item: $localize`:@@common|area-name:Area`,
  };

  readonly areaResourcesSingular: { [key: string]: string } = {
    desk: $localize`:@@floors-and-spaces-module|area-desk-sing:Desk`,
    'meeting room': $localize`:@@floors-and-spaces-module|area-meeting-room-sing:Meeting room`,
    'parking spot': $localize`:@@floors-and-spaces-module|area-parking-sing:Parking spot`,
    item: $localize`:@@floors-and-spaces-module|area-item-sing:Item`,
  };

  readonly areaResourcesPlural: { [key: string]: string } = {
    desk: $localize`:@@floors-and-spaces-module|area-desk-pl:Desks`,
    'meeting room': $localize`:@@floors-and-spaces-module|area-meeting-room-pl:Meeting rooms`,
    'parking spot': $localize`:@@floors-and-spaces-module|area-parking-pl:Parking spots`,
    item: $localize`:@@floors-and-spaces-module|area-item-pl:Items`,
  };

  readonly areaResourceSize: { [key: string]: string } = {
    desk: $localize`:@@common|area-desk-size:Desk size`,
    'parking spot': $localize`:@@common|area-parking-size:Spot size`,
    item: $localize`:@@common|area-item-size:Item size`,
  };

  readonly areaResourcesUniversal: { [key: string]: string } = {
    desk: $localize`:@@common|desk-universal:desks(s)`,
    'meeting room': $localize`:@@common|meeting-room-universal:spot(s)`,
    'parking spot': $localize`:@@common|parking-area-universal:spot(s)`,
    item: $localize`:@@common|resource-universal:item(s)`,
  };

  readonly areaResourcesPlacedUniversal: { [key: string]: string } = {
    desk: $localize`:@@floors-and-spaces-module|area-desk-placed:desk(s) placed`,
    'meeting room': $localize`:@@floors-and-spaces-module|area-meeting-room-placed:room placed`,
    'parking spot': $localize`:@@floors-and-spaces-module|area-parking-placed:spot(s) placed`,
    item: $localize`:@@floors-and-spaces-module|area-item-placed:item(s) placed`,
  };

  readonly addArea: { [key: string]: string } = {
    desk: $localize`:@@common|area-desk-add:Add desk area`,
    'meeting room': $localize`:@@common|area-meeting-room-add:Add meeting room`,
    'parking spot': $localize`:@@common|area-parking-add:Add parking area`,
    item: $localize`:@@common|area-item-add:Add other`,
  };

  readonly addResources: { [key: string]: string } = {
    desk: $localize`:@@common|add-resources-desk:Add desks`,
    'meeting room': $localize`:@@common|add-resources-meeting-room:Add spots`,
    'parking spot': $localize`:@@common|add-resources-area-parking-spot:Add spots`,
    item: $localize`:@@common|add-resources:Add resources`,
  };

  transform(deskAreaType: DeskAreaType | undefined, areaTranslationType: AreaTranslationType): string {
    const deskAreaTypeSuffix = getMappedAreaSuffixValue(deskAreaType);
    if (!deskAreaTypeSuffix) {
      return 'N/A';
    }

    switch (areaTranslationType) {
      case AreaTranslationType.AREA_NAME:
        return this.areaNames[deskAreaTypeSuffix];
      case AreaTranslationType.AREA_RESOURCE_SING:
        return this.areaResourcesSingular[deskAreaTypeSuffix];
      case AreaTranslationType.AREA_RESOURCE_PL:
        return this.areaResourcesPlural[deskAreaTypeSuffix];
      case AreaTranslationType.AREA_RESOURCE_SIZE:
        return this.areaResourceSize[deskAreaTypeSuffix];
      case AreaTranslationType.AREA_RESOURCE_UNIVERSAL:
        return this.areaResourcesUniversal[deskAreaTypeSuffix];
      case AreaTranslationType.AREA_RESOURCE_PLACED_UNIVERSAL:
        return this.areaResourcesPlacedUniversal[deskAreaTypeSuffix];
      case AreaTranslationType.ADD_AREA:
        return this.addArea[deskAreaTypeSuffix];
      case AreaTranslationType.ADD_RESOURCES:
        return this.addResources[deskAreaTypeSuffix];
      default:
        return 'N/A';
    }
  }
}
