import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { DeskAreaType } from "types";
import { DropdownModule } from "primeng/dropdown";
import { NgClass, NgTemplateOutlet } from "@angular/common";
import { IListItem } from "shared-types";

@Component({
  selector: "db-dropdown-resources",
  standalone: true,
  imports: [DropdownComponent, DropdownModule, NgTemplateOutlet, NgClass],
  templateUrl: "./dropdown-resources.component.html",
})
export class DropdownResourcesComponent {
  @Input() selectedValue: DeskAreaType | null = null;
  @Input() class: string = "";
  @Output() selectedValueChanged = new EventEmitter<DeskAreaType>();

  readonly DeskAreaType = DeskAreaType;
  readonly dropdownOptions: IListItem[] = [
    {
      value: DeskAreaType.FlexDesk,
      label: $localize`:@@common|resource-filter-desks:Desks`,
    },
    {
      value: DeskAreaType.MeetingRoom,
      label: $localize`:@@common|resource-filter-meeting-rooms:Meeting rooms`,
    },
    {
      value: DeskAreaType.Parking,
      label: $localize`:@@common|resource-filter-parking:Parking`,
    },
    {
      value: DeskAreaType.Other,
      label: $localize`:@@common|resource-filter-other:Other`,
    },
  ];
}
