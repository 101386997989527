import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { NotificationModel, NotificationType } from 'notification-module';
import { Observable, switchMap, EMPTY, take, race, startWith } from 'rxjs';
import { AuthModel } from 'auth-module';
import { IBaseResponseError } from 'types';

/** Catch specific error for restricted office and show notification message */
export function catchErrorOnBookingAction(
  authModel: AuthModel,
  notificationModel: NotificationModel,
  error: IBaseResponseError,
  actionCreatorFailure: ActionCreator<
    string,
    (props: { error: any }) => {
      error: any;
    } & TypedAction<string>
  >,
  notificationErrorMessage: string
) {
  const restrictedOfficeAccessAction = getRestrictedOfficeAccessActionOrNull(authModel, error);
  let stream$: Observable<unknown>;
  if (restrictedOfficeAccessAction) {
    stream$ = restrictedOfficeAccessAction;
  } else {
    stream$ = EMPTY.pipe(startWith(null));
  }
  return stream$.pipe(
    switchMap(() => {
      return [
        actionCreatorFailure({ error }),
        notificationModel.actions.create.showNotification({
          data: notificationErrorMessage,
          notificationType: NotificationType.ERROR,
        }),
      ];
    })
  );
}

function getRestrictedOfficeAccessActionOrNull(authModel: AuthModel, response: IBaseResponseError) {
  if (response?.errorCode === 'bookingNotFound') {
    authModel.actions.dispatch.restrictedOfficeAccess();
    return race(authModel.actions.listen.restrictedOfficeAccessSuccess$, authModel.actions.listen.restrictedOfficeAccessFailure$).pipe(
      take(1)
    );
  }

  return null;
}
