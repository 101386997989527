<div class="container-empty-state" [class.white]="isWhite">
  <img height="60" [src]="imageSrc" [alt]="title" />
  <h4 class="text-center" [innerHTML]="title">
  </h4>
  <span class="normal-text">
    <ng-container *ngIf="body">{{ body }}</ng-container>
    <ng-content></ng-content
  ></span>
  <div class="action-container" *ngIf="action">
    <db-button (click)="actionClick.emit()">
      {{ action }}
    </db-button>
  </div>
</div>
