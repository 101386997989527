import { HttpErrorResponse } from '@angular/common/http';
import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store'
import { FeatureFlag } from 'types/enums';

const factory = forNamespace('[FEATURE_FLAG]');

export const loadFeatureFlagBundle = factory.asyncAction(
  'loadFlag',
  props<{ flag: FeatureFlag }>(),
  props<{ flag: FeatureFlag; value: boolean }>(),
  props<{ error: HttpErrorResponse }>(),
);

export const loadFeatureFlagForAdminBundle = factory.asyncAction(
  'loadFlagForAdmin',
  props<{ flag: FeatureFlag }>(),
  props<{ flag: FeatureFlag; value: boolean }>(),
  props<{ error: HttpErrorResponse }>(),
);

export const featureFlagBundles = [loadFeatureFlagBundle, loadFeatureFlagForAdminBundle];
