import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableMultiSelectService {
  private _selectedItems: Set<unknown> = new Set();

  get selectedItems(): unknown[] {
    return Array.from(this._selectedItems);
  }

  toggleSelection(item: unknown, selected: boolean): void {
    if (!selected && this._selectedItems.has(item)) {
      this._selectedItems.delete(item);
    } else if (selected) {
      this._selectedItems.add(item);
    }
  }

  selectAll(items: unknown[]): void {
    items.forEach((item) => this._selectedItems.add(item));
  }

  unselectAll(): void {
    this._selectedItems.clear();
  }

  isSelected(item: unknown): boolean {
    return this._selectedItems.has(item);
  }
}
