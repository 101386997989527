import { commonExecPipeFunctions, hasAccessToPageFactory } from 'shared';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IUserInfo } from 'types';
import { ALL_OFFICES } from 'office-module/constants';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'db-client-navigation',
  templateUrl: './client-navigation.component.html',
  styleUrls: ['./client-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientNavigationComponent implements OnChanges {
  @Input() hasPlanningAccess = false;
  @Input() hasBookingAccess = false;

  @Input() isUserAdmin = false;
  @Input() isUserManager = false;
  @Input() isUserGroupManager = false;
  @Input() isUserGuest = false;
  @Input() isUserOfficeAdmin = false;
  @Input() isUserRegularUser = false;
  @Input() isUserDeskbirdAdmin = false;
  @Input() hideIcons = false;

  @Input() isExpanded = false;
  @Input() officeId!: string | null;
  @Input() companyId!: string;
  @Input() userProfileImageUrl: string | null | undefined;
  @Input() userInfo!: IUserInfo;
  @Input() isLoadingOfficesInitially = false;

  @Input() adminPartPrefix!: string;
  @Input() clientPartPrefix!: string;

  @Input() userRoleCompanyPageRestrictions: string[] | null = null;

  @Output() navigateToProfile = new EventEmitter<void>();
  @Output() navigateToYourSpaces = new EventEmitter<void>();
  @Output() signOut = new EventEmitter<void>();

  ALL_OFFICES = ALL_OFFICES;

  userMenuItems: MenuItem[] = [
    {
      icon: 'user-icon',
      label: $localize`:@@navigation-module|client-navigation|profile:Profile settings`,
      command: () => this.navigateToProfile.emit()
    },
    {
      icon: 'spaces-icon',
      label: $localize`:@@navigation-module|client-navigation|your-spaces:Your spaces`,
      command: () => this.navigateToYourSpaces.emit()
    },
    {
      icon: 'sign-out-icon',
      label: $localize`:@@navigation-module|client-navigation|sign out:Sign out`,
      command: () => this.signOut.emit()
    }
  ];

  hasAccessToPage: (args: string[] | null) => boolean = () => true;

  getAbbreviation = commonExecPipeFunctions.getAbbreviation;

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['userProfileImageUrl']?.currentValue === 'string' && this.userInfo) {
      this.userInfo = { ...this.userInfo, profileImage: this.userProfileImageUrl as string };
    }

    if (changes['userRoleCompanyPageRestrictions']) {
      this.hasAccessToPage = hasAccessToPageFactory(this.userRoleCompanyPageRestrictions);
    }
  }
}
