import moment, { Moment } from 'moment';

/**Last day in lead time on which a booking could be made */
export function getBookingLeadTime(officeTimeZone: string, maximumLeadTimeForBooking: number | undefined): Moment {
  if (!maximumLeadTimeForBooking) {
    return moment()
      .tz(officeTimeZone)
      .startOf('day');
  }
  
  return moment()
    .tz(officeTimeZone)
    .startOf('day')
    .add(maximumLeadTimeForBooking - 1, 'day');
}
