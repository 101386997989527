import { Pipe, PipeTransform } from '@angular/core';
import { getAbbreviation } from '../utils';

export const commonExecPipeFunctions = {
  includes: (container: any[] | { [key: string]: any }, value: any): boolean => {
    if (Array.isArray(container)) { return !!container.find(a => a === value); }
    return !!container[value];
  },
  getAbbreviation,
  objectAssign: (o1: object, o2: object) => Object.assign({}, o1, o2)
};

@Pipe({ 
  name: 'exec', 
  standalone: true 
})
export class ExecPipe implements PipeTransform {

  transform<T, F extends Array<any>, R>(value: T, func: (val: T, ...other: F) => R, ...otherArgs: F): R {
    return func(value, ...otherArgs);
  }

}