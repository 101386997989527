import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'formatDateTime',
  standalone: true,
})
export class FormatDateTimePipe implements PipeTransform {
  private readonly intlLocale = inject(LOCALE_ID);

  transform(value: number | string, formatString: string | undefined, timeZone?: string): string {
    if (!value) {
      return '';
    }
    const val = moment(value).locale(this.intlLocale);
    if (timeZone) {
      val.tz(timeZone);
    } else {
      val.local();
    }
    return val.format(formatString || 'MMM D, y');
  }
}
