import { Component, HostBinding, Input } from '@angular/core';
import {
  areaHasRestrictedAccess,
  areaHasRestrictedAccessButUserDoesNotHaveAccess,
  areaHasRestrictedAccessButUserHasAccess,
  areaIsTimeRestricted,
} from 'shared-utils';
import { ExecPipe } from 'shared-pipes';
import { NgClass, NgIf } from '@angular/common';
import { IAreaAccessRulesIconProps } from './types';

@Component({
  selector: 'db-area-restriction-icon',
  templateUrl: './area-restriction-icon.component.html',
  styleUrls: ['./area-restriction-icon.component.scss'],
  standalone: true,
  imports: [ExecPipe, NgIf, NgClass],
})
export class AreaRestrictionIconComponent {
  @Input() accessRules!: IAreaAccessRulesIconProps;
  @Input() iconSize = 14;
  @Input() isTextGrayIcon = false;
  @Input() @HostBinding('class.with-left-margin') withLeftMargin = true;
  @Input() @HostBinding('class.with-right-margin') withRightMargin = true;

  isTimeRestricted = areaIsTimeRestricted;
  hasRestrictedAccess = areaHasRestrictedAccess;
  isRestrictedButHasAccess = areaHasRestrictedAccessButUserHasAccess;
  isRestrictedButDoesNotHaveAccess = areaHasRestrictedAccessButUserDoesNotHaveAccess;
}
