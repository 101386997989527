import { Environment } from "../enums";
import { isProductionEnvironment } from "../utils";
import { allUserRoles } from "./all-roles";
import { UserRole } from "types";

type CompanyId = string;
type PageUrl = string;
type EnvironmentRecord<T> = Partial<Record<Environment, T>>;
type PageRoute =
  | { admin: string[] | null; client?: string[] | null }
  | { admin?: string[] | null; client: string[] | null };
type PageRoleRestrictionEntry = (
  | { roles: UserRole[]; email?: string }
  | { email: string; roles?: UserRole[] }
) &
  PageRoute;
type PageWhitelistEntry = {
  pageRoute: PageRoute;
  whitelistedCompanies: CompanyId[];
};
export interface IPermissions {
  pageRoleRestrictionsPerCompanyId: EnvironmentRecord<
    Record<CompanyId, PageRoleRestrictionEntry>
  >;
  /** Pages that are accessible only for the white listed companies (and all of their roles) */
  whiteListedCompaniesPerPage: EnvironmentRecord<{
    [key: PageUrl]: PageWhitelistEntry;
  }>;
}

const permissions: IPermissions = {
  pageRoleRestrictionsPerCompanyId: {
    [Environment.PRODUCTION]: {
      "2350": {
        // HUK-COBURG Test
        roles: [UserRole.OFFICE_ADMIN],
        admin: ["user", "people-analytics/people", "integrations"],
      },
      "3095": {
        // HUK-COBURG
        roles: [UserRole.OFFICE_ADMIN],
        admin: ["user", "people-analytics/people", "integrations"],
      },
      "2956": {
        // Vrije Universiteit Amsterdam
        roles: allUserRoles,
        admin: ["people-analytics/people"],
      },
      "659": {
        // FC Bayern'
        roles: [UserRole.OFFICE_ADMIN],
        admin: ["people-analytics/people"],
      },
      "3060": {
        // Osborne Clarke DE
        roles: [UserRole.GROUP_MANAGER],
        admin: ["people-analytics/people"],
      },
      "268": {
        // Volksbank Hellweg
        roles: [
          UserRole.GROUP_MANAGER,
          UserRole.OFFICE_ADMIN,
          UserRole.MANAGER,
        ],
        admin: ["people-analytics/people"],
      },
      "2712": {
        // Gascade
        roles: [UserRole.GROUP_MANAGER],
        admin: ["people-analytics/people"],
      },
      "3676": {
        // Zooplus
        roles: [
          UserRole.GROUP_MANAGER,
          UserRole.MANAGER,
          UserRole.OFFICE_ADMIN,
        ],
        admin: ["analytics/people"],
      },
    },
    [Environment.STAGING]: {
      "614": {
        // Marko V company 1
        roles: [UserRole.OFFICE_ADMIN],
        admin: ["user", "people-analytics/people", "integrations"],
      },
      "375": {
        // Diana LTD
        roles: allUserRoles,
        admin: ["user", "people-analytics/people"],
      },
      "371": {
        // IP Österreich
        roles: [UserRole.GROUP_MANAGER, UserRole.MANAGER],
        admin: ["people-analytics/people"],
      },
    },
    [Environment.SANDBOX_404]: {
      "505": {
        // BRAN
        roles: [UserRole.OFFICE_ADMIN],
        admin: ["user", "people-analytics/people", "integrations"],
      },
    },
    [Environment.DEVELOPMENT]: {
      "375": {
        // Diana LTD
        roles: allUserRoles,
        admin: ["user", "people-analytics/people"],
      },
    },
  },
  whiteListedCompaniesPerPage: {},
};

export function getPageRoleRestrictionsForCompanyId(
  env: Environment,
  companyId: string,
) {
  return permissions.pageRoleRestrictionsPerCompanyId[env]?.[companyId] || null;
}

export function getPageRestrictionsPerCompanyId(
  env: Environment,
  companyId: string,
): PageRoute | null {
  const isStagingOrDevelopment = !isProductionEnvironment(env);
  const getter = isStagingOrDevelopment
    ? Environment.STAGING
    : Environment.PRODUCTION;
  const pageRoutes = permissions.whiteListedCompaniesPerPage[getter]
    ? Object.keys(permissions.whiteListedCompaniesPerPage[getter]!)
    : null;
  if (!pageRoutes) {
    return null;
  }

  const companyRestrictions = {
    admin: new Array<string>(),
    client: new Array<string>(),
  };
  for (const whiteListedPage of pageRoutes) {
    const entry =
      permissions.whiteListedCompaniesPerPage[getter]![whiteListedPage];
    if (!entry || entry.whitelistedCompanies.some((id) => id === companyId)) {
      continue;
    }

    companyRestrictions.admin.push(...(entry.pageRoute.admin || []));
    companyRestrictions.client.push(...(entry.pageRoute.client || []));
  }

  return companyRestrictions.admin.length || companyRestrictions.client.length
    ? companyRestrictions
    : null;
}
