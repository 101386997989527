<db-multiselect
  [ngClass]="class"
  [options]="$any(group ? groupedOptions : singleItemOptions)"
  [optionLabel]="'label'"
  [optionValue]="'value'"
  [chip]="true"
  [group]="group"
  [showToggleAll]="showToggleAll"
  [showClear]="showClear"
  [filter]="true"
  [filterBy]="'label,userEntity.email'"
  [panelStyleClass]="panelStyleClass"
  [placeholder]="placeholder"
  [dataTestId]="dataTestId"
  [resetFilterOnHide]="true"
  [withCustomTemplates]="true"
  [virtualScroll]="virtualScroll"
  (onChange)="selectedValuesHandler($event.value)"
  (onClear)="clearSelectionHandler()"
>
  <ng-template pTemplate="item" let-itemOption>
    <div [ngSwitch]="itemOption.type">
      <db-user-info
        *ngSwitchCase="UserMultiselectType.USER"
        [userInfo]="itemOption.userEntity"
        [showUserEmail]="true"
        avatarSize="mini"
        [ignoreEmailMaxWidth]="false"
      >
      </db-user-info>

      <div *ngSwitchDefault>
        {{ itemOption.label }}
      </div>
    </div>
  </ng-template>

  <ng-template let-group pTemplate="group">
    <div class="flex align-items-center">
      <span>{{ group.label }}</span>
    </div>
  </ng-template>
</db-multiselect>
