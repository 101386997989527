export const identifyBrowserLanguage = function (navigator: Navigator) {
  let language: string;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(navigator.languages)) {
    for (let i = 0; i < navigator.languages.length; i++) {
      language = navigator.languages[i];
      if (language && language.length) {
        const browserLanguageNoRegionCode = language.length > 2 ? language.slice(0, 2) : language;
        return browserLanguageNoRegionCode;
      }
    }
  }

  // support for other well known properties in browsers
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = (navigator as any)[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      const browserLanguageNoRegionCode = language.length > 2 ? language.slice(0, 2) : language;
      return browserLanguageNoRegionCode;
    }
  }

  return null;
};
