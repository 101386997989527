import { AvailableLocales } from 'shared';
import { Translation } from 'primeng/api';

export type PrimeNgTranslation = Translation & { dateFormatMoment: string };

function fetchPrimeNgLocale(locale: AvailableLocales): Promise<{ [key: string]: Translation & { dateFormatMoment: string } }> {
  let path = `/assets/locales-prime-ng/${locale}.json`;

  return fetch(path)
    .then((res) => res.json())
    .catch((err) => console.error(err));
}

export function getPrimeNgTranslation(requestedLocale: string): Promise<PrimeNgTranslation | null> {
  const locale = requestedLocale as AvailableLocales;
  return fetchPrimeNgLocale(locale)
    .then((translationsByLocale) => {
      if (translationsByLocale) {
        return translationsByLocale[locale];
      }
      return null;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}
