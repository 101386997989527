import {
  CALENDAR_INTEGRATION_MEETING_ROOMS_FEATURE,
  CALENDAR_INTEGRATION_OFFICE_EVENTS_FEATURE,
  CALENDAR_INTEGRATION_SCHEDULING_FEATURE,
  CALENDAR_INTEGRATION_TYPE,
  HRIS_INTEGRATION_TYPE
} from '../constants';

export interface IBaseIntegration {
  isConnected: boolean;
  isEnabled: boolean;
  provider?: string;
}

export interface ICalendarIntegrationFeature {
  type: typeof CALENDAR_INTEGRATION_SCHEDULING_FEATURE
    | typeof CALENDAR_INTEGRATION_OFFICE_EVENTS_FEATURE
    | typeof CALENDAR_INTEGRATION_MEETING_ROOMS_FEATURE;
  isConnected: boolean;
  isEnabled: boolean;
}

export interface ICalendarIntegration extends IBaseIntegration {
  type: typeof CALENDAR_INTEGRATION_TYPE;
  features: ICalendarIntegrationFeature[];
}

export interface IHrisIntegration extends IBaseIntegration {
  type: typeof HRIS_INTEGRATION_TYPE;
  syncStatus?: string;
}

export type IIntegration = IHrisIntegration | ICalendarIntegration;
