import moment from 'moment';
import { IAccessRules, MeetingRoomCalendarDataAccessRules } from 'types';

export const areaHasRestrictedAccess = (
  accessRules?: Pick<MeetingRoomCalendarDataAccessRules | IAccessRules, 'isRestricted' | 'userHasAccess' | 'restrictedTime'>
): boolean => {
  if (!accessRules) {
    return false;
  }
  const { isRestricted, restrictedTime } = accessRules;
  return isRestricted && (!restrictedTime || restrictedTime === undefined);
};

export const areaHasRestrictedAccessButUserHasAccess = (
  accessRules?: Pick<MeetingRoomCalendarDataAccessRules | IAccessRules, 'isRestricted' | 'userHasAccess' | 'restrictedTime'>
): boolean => {
  if (!accessRules) {
    return false;
  }
  const { userHasAccess } = accessRules;
  return areaHasRestrictedAccess(accessRules) && userHasAccess;
};

export const areaHasRestrictedAccessButUserDoesNotHaveAccess = (
  accessRules?: Pick<MeetingRoomCalendarDataAccessRules | IAccessRules, 'isRestricted' | 'userHasAccess' | 'restrictedTime'>
): boolean => {
  if (!accessRules) {
    return true;
  }
  const { userHasAccess } = accessRules;
  return areaHasRestrictedAccess(accessRules) && !userHasAccess;
};

export const areaIsTimeRestricted = (
  accessRules?: Pick<MeetingRoomCalendarDataAccessRules | IAccessRules, 'isRestricted' | 'userHasAccess' | 'restrictedTime'>
): boolean => {
  if (!accessRules) {
    return true;
  }
  const { isRestricted, restrictedTime } = accessRules;
  return isRestricted && (!!restrictedTime || restrictedTime === 0);
};

export const areaIsTimeRestrictedAndRestrictionTimeHasPassed = (
  accessRules: IAccessRules | MeetingRoomCalendarDataAccessRules | undefined,
  targetDay: moment.Moment
): boolean => {
  if (!accessRules) {
    return true;
  }
  const { isRestricted, restrictedTime, userHasAccess } = accessRules;
  if (!isRestricted || restrictedTime === undefined || userHasAccess) {
    return true;
  }

  const tomorrowMoment = moment().add(1, 'day');
  const targetDateIsToday = moment().date() === targetDay.date() && moment().day() === targetDay.day() && moment().month() === targetDay.month();
  const targetDateIsTomorrow =
    tomorrowMoment.date() === targetDay.date() && tomorrowMoment.day() === targetDay.day() && tomorrowMoment.month() === targetDay.month();
  const secondsPassedToday = moment().diff(moment().startOf('day'), 'seconds');

  return targetDateIsToday || (targetDateIsTomorrow && secondsPassedToday >= restrictedTime);
};

export const areaIsTimeRestrictedAndUserHasAccess = (
  accessRules?: IAccessRules | MeetingRoomCalendarDataAccessRules | undefined
): boolean => {
  if (!accessRules) {
    return true;
  }
  const { isRestricted, restrictedTime, userHasAccess } = accessRules;
  return isRestricted && (!!restrictedTime || restrictedTime === 0) && userHasAccess;
};

export const areaIsTimeRestrictedAndIsBookable = (
  accessRules?: IAccessRules | MeetingRoomCalendarDataAccessRules | undefined
): boolean => {
  if (!accessRules) {
    return false;
  }
  const { isBookable } = accessRules as IAccessRules;
  return areaIsTimeRestricted(accessRules) && !!isBookable;
};

export const areaIsTimeRestrictedAndIsNotBookable = (
  accessRules?: IAccessRules | MeetingRoomCalendarDataAccessRules | undefined
): boolean => {
  if (!accessRules) {
    return true;
  }
  const { isBookable } = accessRules as IAccessRules;
  return areaIsTimeRestricted(accessRules) && !isBookable;
};
