import { Inject, Injectable } from '@angular/core';
import { API_URL, WINDOW } from 'common-module';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor(@Inject(WINDOW) public window: Window, @Inject(API_URL) private APIUrl: string) {}

  open(path: string): Window | null {
    const url = path.startsWith('http') ? path : this.APIUrl + '/v1.1' + path;
    return this.window.open(url, '_blank');
  }

  print() {
    return this.window.print();
  }

  reloadWindow() {
    this.window.location.reload();
  }

  getWindow() {
    return this.window;
  }
}
