import { Injectable, inject } from '@angular/core';
import { Observable, filter, switchMap, take } from 'rxjs';
import { ConfirmationModel } from './+store/model';
import { IGenericConfirmation } from './interfaces';

@Injectable({ providedIn: 'root' })
export class TableActionsConfirmationService {
  private readonly confirmationModel = inject(ConfirmationModel);

  /** Wait for the user to confirm via popup and returns the confirmation result */
  rowDeleteConfirm(header : string, body : string): Observable<boolean> {
    const config = this.getDeleteConfirmConfig(header, body);

    const action = this.confirmationModel.actions.dispatch.openGenericConfirmation({ config });
    return this.confirmationModel.actions.listen.confirmationResult$.pipe(
      filter((a) => a.timestamp === action.timestamp),
      take(1),
      switchMap((payload) => {
        return [payload.result];
      })
    );
  }

  private getDeleteConfirmConfig = (header : string, body : string) : IGenericConfirmation => {
    return {
      headerText: header,
      bodyText: body,
      cancelButtonLabel: $localize`:@@common|cancel:Cancel`,
      confirmButtonLabel: $localize`:@@common|delete:Delete`
    }
  };
}
