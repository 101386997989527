export const customErrorMessage = (errorCode: string, defaultErrorMessage: string): string => {
  let errorNotificationMessage = '';
  switch (errorCode) {
    case 'notAllowedToUpdateEvent':
      errorNotificationMessage = $localize`:@@office-event-module|error-update-unauthorized:You are not allowed to update events in this office.`;
      break;
    case 'notAllowedToDeleteEvent':
      errorNotificationMessage = $localize`:@@office-event-module|error-delete-unauthorized:You are not allowed to delete events in this office.`;
      break;
    case 'notAllowedToCreateEvent':
      errorNotificationMessage = $localize`:@@office-event-module|error-create-unauthorized:You are not allowed to create events in this office.`;
      break;
  }

  return errorNotificationMessage || defaultErrorMessage;
};
