import { FollowingStatusType, ProfileType } from '../enums';

export interface IUserFollowingStatus {
  isUserFollowingMe?: boolean;

  profileType?: ProfileType;
  followingStatus?: FollowingStatusType;

  // Sent by authenticated user
  sentFollowRequestId?: number;
  // Received by authenticated user
  receivedFollowRequestId?: number;

  // Whether auth user is admin and has the right to see scheduling status of the user despite privacy of profile
  roleBasedVisibility?: boolean;
}
