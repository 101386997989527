import { Directive, HostListener } from '@angular/core';
import { HoverDirective } from './hover.directive';


@Directive({
  selector: '[dbHoverContainer]',
  standalone: true,
  exportAs: 'dbHoverContainer'
})
export class HoverContainerDirective {

  private hoverEffectItems = new Map<HTMLElement, HoverDirective>();

  isHovered = false;

  registerHoverItem = (item: HoverDirective): void => {
    this.hoverEffectItems.set(item.elementRef.nativeElement, item);
  }

  unregisterHoverItem(item: HoverDirective): void {
    this.hoverEffectItems.delete(item.elementRef.nativeElement);
  }

  @HostListener('mouseover')
  mouseover() {
    this.setHoverState(true);
  }

  @HostListener('mouseout')
  mouseout() {
    this.setHoverState(false);
  }

  private setHoverState(isHovered: boolean) {
    this.isHovered = isHovered;
    Array.from(this.hoverEffectItems.values()).forEach((value) => {
      value.isHovered = isHovered;
    });
  }

}
