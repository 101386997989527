<div *ngIf="isUserInputRequired$ | async">
  <db-user-names
    id="user-names"
    title="Enter your name"
    i18n-title="@@auth-module|sign-up-wizard|enter-name"
    (setNames)="setNamesHandler($event)"
  >
  </db-user-names>
</div>
<p *ngIf="!!!(isUserInputRequired$ | async) && count < 6" i18n="@@auth-module|saml|page-redirect-info">
  Page will redirect in {{ count }} seconds. If it doesn't please
  <a
    [routerLink]="(isLoggedIn$ | async) ? '/default' : '/login'"
    queryParamsHandling="merge"
    [queryParams]="queryParamsNavigationCleanUp"
    >click here</a
  >.
</p>
