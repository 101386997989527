<ng-container
  *ngIf="(!isResolving || bookings?.length) && authUser; else skeletonLoaders"
>
  <div data-testid="booking--bookings-cards-container" class="bookings-container" *ngIf="bookings?.length; else noBookings">
    <ng-container *ngFor="let booking of bookings; trackBy: trackByFn">
      <db-booking-card-item
        [isClickable]="bookingClick.observed"
        [authUserId]="authUser.id"
        [authUserTimeFormat]="authUser.hourFormat"
        [authUserDedicatedResources]="authUser.dedicatedResources"
        [authUserFavoriteResources]="authUser.favoriteResources"
        [favoriteSpaceGtmEntryPoint]="gtmEntryPoint"
        [booking]="booking"
        [fitDetailsOnOneLine]="fitDetailsOnOneLine"
        (click)="bookingClick.observed && bookingItemClickHandler()"
        (bookingCheckIn)="checkInHandler(booking)"
      ></db-booking-card-item>
    </ng-container>
  </div>
</ng-container>

<ng-template #skeletonLoaders>
  <div class="bookings-container">
    <db-booking-card-item [isLoading]="true"></db-booking-card-item>
    <db-booking-card-item [isLoading]="true"></db-booking-card-item>
    <db-booking-card-item [isLoading]="true"></db-booking-card-item>
  </div>
</ng-template>

<ng-template #noBookings>
  <ng-container *ngIf="showIfEmptyMessage">
    <div class="bookings-empty">
      <div class="small-text" i18n="@@booking-module|your-bookings|no-bookings">
        No bookings on this day
      </div>
    </div>
  </ng-container>
</ng-template>
