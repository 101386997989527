import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GLOBAL_STORE_NAME } from '../../constants';
import { IGlobalState } from './reducer';

const getOfficeState = createFeatureSelector<IGlobalState>(GLOBAL_STORE_NAME);

const getAdminAppCompany = createSelector(getOfficeState, (state) => state.adminAppCompany);
const getDeskbirdAdminCompanies = createSelector(getOfficeState, (state) => state.deskbirdAdminCompanies);
const getLastCompaniesUpdateTime = createSelector(getOfficeState, (state) => state.lastCompaniesUpdateTime);
const getAdminAppCompanyId = createSelector(getOfficeState, (state) => state.adminAppCompanyId);
const getClientAppCompanyId = createSelector(getOfficeState, (state) => state.clientAppCompanyId);
const getNoCompanyConfigured = createSelector(getOfficeState, (state) => state.noCompanyConfigured);
const getIsFetchingCompany = createSelector(getOfficeState, (state) => state.isFetchingCompany);

const getAdminAppOffice = createSelector(getOfficeState, (state) => state.adminAppOffice);
const getClientAppOffice = createSelector(getOfficeState, (state) => state.clientAppOffice);
const getAllOffices = createSelector(getOfficeState, (state) => state.allOffices);
const getLastOfficesUpdateTime = createSelector(getOfficeState, (state) => state.lastOfficesUpdateTime);
const getAdminAppOfficeId = createSelector(getOfficeState, (state) => state.adminAppOfficeId);
const getClientAppOfficeId = createSelector(getOfficeState, (state) => state.clientAppOfficeId);
const getNoOffices = createSelector(getAllOffices, (offices) => offices?.length === 0);

const getIsFetchingCompanies = createSelector(getOfficeState, (state) => state.isFetchingCompanies);
const getIsFetchingOffices = createSelector(getOfficeState, (state) => state.isFetchingOffices);
const getIsFetchingOffice = createSelector(getOfficeState, (state) => state.isFetchingOffice);
const getNoPrimaryOfficeConfigured = createSelector(getOfficeState, (state) => state.noPrimaryOfficeConfigured);
const getUserCsvProcessState = createSelector(getOfficeState, (state) => state.userCsvProcessState);
const getImportCsvId = createSelector(getOfficeState, (state) => state.importCsvId);
const getIsLoadingInitialCsvProcessStatus = createSelector(getOfficeState, (state) => state.isLoadingInitialCsvProcessStatus);
const getIsLoadingCsvProcessStatus = createSelector(getOfficeState, (state) => state.isLoadingCsvProcessStatus);
const getErrorLoadingCsvProgressStatus = createSelector(getOfficeState, (state) => state.errorLoadingCsvProgressStatus);
const getImportUserCsvData = createSelector(getOfficeState, (state) => state.importUserCsvData);
const getIsAppIdle = createSelector(getOfficeState, (state) => state.isAppIdle);
const getInvoiceBanner = createSelector(getOfficeState, (state) => state.invoiceBanner);

export const globalSelectors = {
  adminAppCompany: getAdminAppCompany,
  deskbirdAdminCompanies: getDeskbirdAdminCompanies,
  lastCompaniesUpdateTime: getLastCompaniesUpdateTime,
  adminAppCompanyId: getAdminAppCompanyId,
  clientAppCompanyId: getClientAppCompanyId,
  noCompanyConfigured: getNoCompanyConfigured,
  isFetchingCompany: getIsFetchingCompany,
  adminAppOffice: getAdminAppOffice,
  clientAppOffice: getClientAppOffice,
  allOffices: getAllOffices,
  lastOfficesUpdateTime: getLastOfficesUpdateTime,
  adminAppOfficeId: getAdminAppOfficeId,
  clientAppOfficeId: getClientAppOfficeId,
  isFetchingOffices: getIsFetchingOffices,
  isFetchingOffice: getIsFetchingOffice,
  noPrimaryOfficeConfigured: getNoPrimaryOfficeConfigured,
  noOffices: getNoOffices,
  isFetchingCompanies: getIsFetchingCompanies,
  userCsvProcessState: getUserCsvProcessState,
  importCsvId: getImportCsvId,
  isLoadingInitialCsvProcessStatus: getIsLoadingInitialCsvProcessStatus,
  isLoadingCsvProcessStatus: getIsLoadingCsvProcessStatus,
  errorLoadingCsvProgressStatus: getErrorLoadingCsvProgressStatus,
  importUserCsvData: getImportUserCsvData,
  isAppIdle: getIsAppIdle,
  invoiceBanner: getInvoiceBanner,
};
