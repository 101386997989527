import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { TreeNode } from './typings/tree-node';
import { ITreeNodeTemplateContext } from './typings';
import { CommonModule } from '@angular/common';
import { ExecPipe } from '../../pipes';
import { TooltipModule } from 'primeng/tooltip';

const setHoverItemThrottleTime = 100;

@Component({
  selector: 'db-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TreeNodeComponent, CommonModule, ExecPipe, TooltipModule],
})
export class TreeComponent {
  @ContentChild(TemplateRef<ITreeNodeTemplateContext<TreeNodeComponent>>) nodeTemplateRef!: TemplateRef<
    ITreeNodeTemplateContext<TreeNodeComponent>
  >;
  @ViewChild('rootNodeContent', { static: true }) rootNodeContent!: ElementRef<HTMLDivElement>;

  @Input() rootNode!: TreeNode<any, any>;
  @Input() indentSpacePx = 0;
  @Input() treeNodeNameColumnWidthPx = 100;
  @Input() skipInitialIndent = true;
  @Input() boldNamePredFn: (node: TreeNode<any, any>) => boolean = (_) => true;
  @Output() nodeClick = new EventEmitter<{ node: TreeNode<any, any>; level: number; index: number }>();

  changeDetectorRef = inject(ChangeDetectorRef);

  hoveredItem: any | null = null;
  isHovered = false;
  setHoverItemTimeoutId: number | null = null;

  toggleHoveredHandler = (isHovered?: boolean) => {
    if (typeof isHovered === 'boolean') {
      this.isHovered = isHovered;
    } else {
      this.isHovered = !this.isHovered;
    }
    this.hoveredItem = this.isHovered ? this : null;
    this.changeDetectorRef.markForCheck();
  };

  mouseenterListenerHandler = () => {
    this.isHovered = true;
    this.hoveredItem = this;
  };

  mouseleaveListenerHandler = () => {
    this.isHovered = false;
    this.hoveredItem = null;
  };

  hoverItemHandler = ($event: TreeNodeComponent | null) => {
    this.setHoverItem($event);
  };

  setHoverItem(item: TreeNodeComponent | null) {
    if (this.setHoverItemTimeoutId) {
      clearInterval(this.setHoverItemTimeoutId);
    }
    if (item === null) {
      this.hoveredItem = null;
      return;
    }
    this.setHoverItemTimeoutId = setTimeout(() => {
      this.hoveredItem = item;
      this.changeDetectorRef.detectChanges();
    }, setHoverItemThrottleTime) as unknown as number;
  }
}
