import { createReducer, on } from '@ngrx/store';
import { ICompany, IOffice } from 'common-module';

import {
  loadCompaniesBundle,
  loadCompanyBundle,
  loadOfficeBundle,
  loadOfficesBundle,
  setAdminAppCompanyIdBundle,
  setClientAppCompanyIdBundle,
  setAdminAppOfficeBundle,
  setClientAppOfficeBundle,
  setClientAppOfficeIdBundle,
  setAdminAppOfficeIdBundle,
  setCompanyBundle,
  setNoOfficePrimaryBundle,
  addOfficeBundle,
  removeOfficeBundle,
  setIsDeskbirdAdminBundle,
  updateOfficeBundle,
  addCompanyBundle,
  updateCompanyBundle,
  checkUserCsvImportStatusBundle,
  setUserCsvImportStatusBundle,
  setIdleAppValueBundle,
  updateAllowsUsersToManageOfficeRolesBundle,
  loadInvoiceBannerBundle,
} from './bundles';
import { IUserCsvProcessState } from 'types';
import { IInvoiceBanner } from 'types/interfaces/invoice-banner';

export interface IGlobalState {
  readonly deskbirdAdminCompanies: ICompany[] | null;
  readonly adminAppCompany: ICompany | null;
  readonly adminAppCompanyId: string | null;
  readonly clientAppCompanyId: string | null;

  readonly lastCompaniesUpdateTime: number | null;
  readonly noCompanyConfigured: boolean;

  readonly isFetchingCompanies: boolean;
  readonly isFetchingCompany: boolean;
  readonly isFetchingOffices: boolean;
  readonly isFetchingOffice: boolean;
  readonly isFetchingOfficeTroughOffices: boolean;

  readonly clientAppOffice: IOffice | null;
  readonly clientAppOfficeId: string | null;

  readonly adminAppOffice: IOffice | null;
  readonly adminAppOfficeId: string | null;

  readonly allOffices: IOffice[] | null;
  readonly lastOfficesUpdateTime: number | null;
  readonly noPrimaryOfficeConfigured: boolean | null;
  readonly isDeskbirdAdmin: boolean | null;

  readonly userCsvProcessState: IUserCsvProcessState | null;
  readonly importCsvId: number | null;
  readonly isLoadingInitialCsvProcessStatus: boolean;
  readonly isLoadingCsvProcessStatus: boolean;
  readonly errorLoadingCsvProgressStatus: boolean;
  readonly importUserCsvData: {
    fileName: string;
    fileSize: number;
    uploadDate: string;
  } | null;

  readonly isAppIdle: boolean;
  readonly invoiceBanner: IInvoiceBanner | null;
}

export const initialState: IGlobalState = {
  isFetchingCompany: false,

  adminAppCompany: null,
  deskbirdAdminCompanies: null,
  adminAppCompanyId: null,
  clientAppCompanyId: null,

  lastCompaniesUpdateTime: null,
  noCompanyConfigured: false,

  isFetchingCompanies: false,
  isFetchingOffices: false,
  isFetchingOfficeTroughOffices: false,
  isFetchingOffice: false,

  clientAppOffice: null,
  adminAppOffice: null,

  adminAppOfficeId: null,
  clientAppOfficeId: null,

  allOffices: null,
  lastOfficesUpdateTime: null,

  noPrimaryOfficeConfigured: null,
  isDeskbirdAdmin: null,
  userCsvProcessState: null,
  importCsvId: null,
  isLoadingInitialCsvProcessStatus: true, // set to true due to ui showing content for second on app init
  isLoadingCsvProcessStatus: true,
  errorLoadingCsvProgressStatus: false,
  importUserCsvData: null,
  isAppIdle: false,
  invoiceBanner: null,
};

export const globalReducer = createReducer<IGlobalState>(
  initialState,
  on(setAdminAppCompanyIdBundle.setAdminAppCompanyId, (state, { companyId }): IGlobalState => {
    const adminAppCompany = state.deskbirdAdminCompanies?.find((c) => c.id === companyId) || state.adminAppCompany;
    return { ...state, adminAppCompanyId: companyId, adminAppCompany };
  }),
  on(setClientAppCompanyIdBundle.setClientAppCompanyId, (state, { companyId }): IGlobalState => {
    return { ...state, clientAppCompanyId: companyId };
  }),

  on(loadCompaniesBundle.loadCompanies, (state) => {
    return { ...state, isFetchingCompanies: true };
  }),
  on(loadCompaniesBundle.loadCompaniesCancel, (state) => {
    return { ...state, isFetchingCompanies: false };
  }),

  on(loadCompaniesBundle.loadCompaniesSuccess, (state, { companies }): IGlobalState => {
    const adminAppCompany = companies.find((c) => c.id === state.adminAppCompanyId) || state.adminAppCompany;
    return {
      ...state,
      deskbirdAdminCompanies: companies,
      adminAppCompany,
      lastCompaniesUpdateTime: Date.now(),
      isFetchingCompanies: false,
    };
  }),
  on(setClientAppOfficeIdBundle.setClientAppOfficeId, (state, payload): IGlobalState => {
    let { officeId: clientAppOfficeId } = payload;
    const offices = state.isDeskbirdAdmin
      ? state.allOffices || []
      : (state.allOffices || []).filter((o) => o.isActive && o.businessCompanyId === state.clientAppCompanyId);
    const clientAppOffice = offices.find((o) => o.id === clientAppOfficeId) || offices[0] || state.clientAppOffice;
    clientAppOfficeId = clientAppOffice?.id || null;
    return { ...state, clientAppOfficeId, clientAppOffice };
  }),
  on(setAdminAppOfficeIdBundle.setAdminAppOfficeId, (state, payload): IGlobalState => {
    let { officeId: adminAppOfficeId } = payload;
    const offices = state.isDeskbirdAdmin
      ? state.allOffices || []
      : (state.allOffices || []).filter((o) => o.businessCompanyId === state.clientAppCompanyId);

    const adminAppOffice = offices.find((o) => o.id === adminAppOfficeId) || offices[0] || state.adminAppOffice;
    adminAppOfficeId = adminAppOffice?.id || null;
    return { ...state, adminAppOfficeId, adminAppOffice };
  }),
  on(loadCompanyBundle.loadCompany, (state): IGlobalState => {
    return { ...state, isFetchingCompany: true };
  }),
  on(loadCompanyBundle.loadCompanyCancel, (state): IGlobalState => {
    return { ...state, isFetchingCompany: false };
  }),
  on(loadCompanyBundle.loadCompanySuccess, (state, { company }): IGlobalState => {
    const deskbirdAdminCompanies = (
      state.deskbirdAdminCompanies ? state.deskbirdAdminCompanies.filter((o) => o.id !== company.id) : []
    ).concat(company);

    return { ...state, adminAppCompany: company, deskbirdAdminCompanies, isFetchingCompany: false };
  }),
  on(loadCompanyBundle.loadCompanyCleanup, (state): IGlobalState => {
    return { ...state, adminAppCompany: null };
  }),
  on(loadOfficeBundle.loadOffice, (state): IGlobalState => {
    return { ...state, isFetchingOffice: true };
  }),
  on(loadOfficeBundle.loadOfficeCancel, (state): IGlobalState => {
    return { ...state, isFetchingOffice: false };
  }),
  on(loadOfficeBundle.loadOfficeSuccess, (state, { office }): IGlobalState => {
    const allOffices = state.allOffices ? state.allOffices.map((o) => (o.id === office.id ? office : o)) : [office];
    const adminAppOffice = state.adminAppOfficeId === office.id ? office : state.adminAppOffice;
    const clientAppOffice = state.clientAppOfficeId === office.id ? office : state.clientAppOffice;

    return { ...state, isFetchingOffice: false, allOffices, adminAppOffice, clientAppOffice, lastOfficesUpdateTime: Date.now() };
  }),
  on(loadOfficesBundle.loadOffices, (state): IGlobalState => {
    const hasOffice = !!state.allOffices;
    const isFetchingOfficeTroughOffices = hasOffice ? state.isFetchingOfficeTroughOffices : true;
    const isFetchingOffice = isFetchingOfficeTroughOffices ? true : state.isFetchingOffice;

    return { ...state, isFetchingOffices: true, isFetchingOffice, isFetchingOfficeTroughOffices };
  }),
  on(loadOfficesBundle.loadOfficesCancel, (state): IGlobalState => {
    return { ...state, isFetchingOffices: false };
  }),
  on(loadOfficesBundle.loadOfficesSuccess, (state, { offices, selectedOfficeId }): IGlobalState => {
    const allCurrentOffices = Array.from(new Map(offices.concat(state.allOffices || []).map((o) => [o.id, o])).values());
    const isFetchingOfficeTroughOffices = state.isFetchingOfficeTroughOffices ? false : state.isFetchingOfficeTroughOffices;
    const isFetchingOffice = state.isFetchingOfficeTroughOffices ? false : state.isFetchingOffice;

    const companyOffices = state.isDeskbirdAdmin
      ? allCurrentOffices
      : allCurrentOffices.filter((o) => o.businessCompanyId === state.adminAppCompanyId);
    const firstCompanyOffice = companyOffices[0];

    let adminAppOfficeId = state.adminAppOfficeId || selectedOfficeId || firstCompanyOffice?.id || null;
    const adminAppOffice = state.adminAppOffice || companyOffices.find((o) => o.id === adminAppOfficeId) || firstCompanyOffice || null;
    adminAppOfficeId = adminAppOffice?.id || null;

    const companyActiveOffices = state.isDeskbirdAdmin ? allCurrentOffices : companyOffices.filter((o) => o.isActive);
    const firstCompanyActiveOffice = companyActiveOffices[0];

    let clientAppOfficeId = state.clientAppOfficeId || selectedOfficeId || firstCompanyActiveOffice?.id || null;
    const clientAppOffice =
      state.clientAppOffice || companyActiveOffices.find((o) => o.id === clientAppOfficeId) || firstCompanyActiveOffice || null;
    clientAppOfficeId = clientAppOffice?.id || null;

    return {
      ...state,
      allOffices: allCurrentOffices,
      clientAppOffice,
      clientAppOfficeId,
      adminAppOffice,
      adminAppOfficeId,
      isFetchingOffices: false,
      isFetchingOffice,
      isFetchingOfficeTroughOffices,
    };
  }),
  on(loadOfficesBundle.loadOfficesCleanup, (state): IGlobalState => {
    return { ...state, allOffices: null, adminAppOffice: null, clientAppOffice: null };
  }),
  on(setIsDeskbirdAdminBundle.setIsDeskbirdAdmin, (state, { isDeskbirdAdmin }): IGlobalState => {
    return { ...state, isDeskbirdAdmin };
  }),
  on(setClientAppOfficeBundle.setClientAppOffice, (state, { office }): IGlobalState => {
    const officeIndex = (state.allOffices || [])?.findIndex((o) => o.id === office.id);
    if (officeIndex < 0) {
      return state;
    }
    const allOffices = [...(state.allOffices || [])?.slice(0, officeIndex), office, ...(state.allOffices || []).slice(officeIndex + 1)];
    return { ...state, clientAppOffice: office, allOffices };
  }),
  on(setAdminAppOfficeBundle.setAdminAppOffice, (state, { office }): IGlobalState => {
    const officeIndex = (state.allOffices || [])?.findIndex((o) => o.id === office.id);
    if (officeIndex < 0) {
      return state;
    }
    const allOffices = [...(state.allOffices || [])?.slice(0, officeIndex), office, ...(state.allOffices || []).slice(officeIndex + 1)];
    return { ...state, adminAppOffice: office, allOffices };
  }),
  on(setCompanyBundle.setCompany, (state, { company: adminAppCompany }): IGlobalState => {
    const index = (state.deskbirdAdminCompanies || [])?.findIndex((o) => o.id === adminAppCompany.id);
    if (index < 0) {
      return state;
    }
    const deskbirdAdminCompanies = [
      ...(state.deskbirdAdminCompanies || [])?.slice(0, index),
      adminAppCompany,
      ...(state.deskbirdAdminCompanies || []).slice(index + 1),
    ];
    return { ...state, adminAppCompany, deskbirdAdminCompanies };
  }),
  on(updateCompanyBundle.updateCompany, (state, { company }) => {
    const index = (state.deskbirdAdminCompanies || [])?.findIndex((o) => o.id === company.id);
    if (index < 0) {
      return state;
    }
    const deskbirdAdminCompanies = [
      ...(state.deskbirdAdminCompanies || [])?.slice(0, index),
      { ...state.deskbirdAdminCompanies![index], ...company },
      ...(state.deskbirdAdminCompanies || []).slice(index + 1),
    ];
    return { ...state, deskbirdAdminCompanies };
  }),
  on(addCompanyBundle.addCompany, (state, { company: adminAppCompany }): IGlobalState => {
    const deskbirdAdminCompanies = (state.deskbirdAdminCompanies || []).concat(adminAppCompany);

    return { ...state, adminAppCompany, deskbirdAdminCompanies };
  }),
  on(setNoOfficePrimaryBundle.setNoPrimaryOffice, (state, { hasNoPrimaryOffice }): IGlobalState => {
    return { ...state, noPrimaryOfficeConfigured: hasNoPrimaryOffice };
  }),
  on(addOfficeBundle.addOffice, (state, { office }) => {
    const allOffices = state.allOffices?.concat(office) || null;
    return { ...state, allOffices };
  }),
  on(removeOfficeBundle.removeOffice, (state, { officeId }) => {
    const allOffices = state.allOffices?.filter((office) => office.id !== officeId) || null;
    return { ...state, allOffices };
  }),
  on(updateOfficeBundle.updateOffice, (state, { updates }) => {
    const allOffices = state.allOffices?.map((o) => (o.id === updates.id ? { ...o, ...updates } : o)) || state.allOffices;
    let { adminAppOffice, clientAppOffice } = state;
    if (updates.id === adminAppOffice?.id) {
      adminAppOffice = { ...adminAppOffice, ...updates };
    }
    if (updates.id === clientAppOffice?.id) {
      clientAppOffice = { ...clientAppOffice, ...updates };
    }
    return { ...state, allOffices, adminAppOffice, clientAppOffice };
  }),
  on(checkUserCsvImportStatusBundle.checkUserCsvImportStatus, (state) => {
    return {
      ...state,
      isLoadingInitialCsvProcessStatus: state.importUserCsvData ? true : false,
      isLoadingCsvProcessStatus: true,
      errorLoadingCsvProgressStatus: false,
    };
  }),
  on(
    checkUserCsvImportStatusBundle.checkUserCsvImportStatusSuccess,
    setUserCsvImportStatusBundle.setUserCsvImportStatus,
    (state, { processState, importCsvId, fileName, fileSize, uploadDate }) => {
      return {
        ...state,
        userCsvProcessState: processState,
        importCsvId,
        isLoadingInitialCsvProcessStatus: false,
        isLoadingCsvProcessStatus: false,
        importUserCsvData:
          processState && fileName && fileSize && uploadDate
            ? {
                fileName,
                fileSize,
                uploadDate,
              }
            : null,
      };
    }
  ),
  on(checkUserCsvImportStatusBundle.checkUserCsvImportStatusFailure, (state) => {
    return { ...state, isLoadingInitialCsvProcessStatus: false, isLoadingCsvProcessStatus: false, errorLoadingCsvProgressStatus: true };
  }),
  on(checkUserCsvImportStatusBundle.checkUserCsvImportStatusCleanup, (state) => {
    return { ...state, userCsvProcessState: null, importUserCsvData: null, isLoadingInitialCsvProcessStatus: false };
  }),
  on(setIdleAppValueBundle.setIdleAppValue, (state, { isAppIdle }) => {
    return { ...state, isAppIdle };
  }),
  on(updateAllowsUsersToManageOfficeRolesBundle.updateAllowsUsersToManageOfficeRolesSuccess, (state, { companyId, allowsUsersToManageOfficeRoles }) => {
    if (state.adminAppCompany?.id == companyId) {
      return { ...state, adminAppCompany: { ...state.adminAppCompany, allowsUsersToManageOfficeRoles } };
    }

    return { ...state}
  }),
  on(loadInvoiceBannerBundle.loadInvoiceBannerSuccess, (state, { invoiceBanner }) => {
    return { ...state, invoiceBanner };
  })
);
