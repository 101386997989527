import { Injectable } from '@angular/core';
import { connectBundles, connectSelectors } from 'ngrx-action-bundles';
import { qrGenerationBundles } from './bundles';
import { qrGeneratorSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class QrGenerationModel {
  selectors = connectSelectors(qrGeneratorSelectors);
  actions = connectBundles(qrGenerationBundles);
}
