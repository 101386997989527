import moment from "moment";

export function addWeekdays(date: Date | moment.Moment, days: number): moment.Moment {
  let momentDate = moment(date);
  while (days > 0) {
    momentDate = momentDate.add(1, 'days');
    if (momentDate.isoWeekday() !== 6 && momentDate.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return momentDate;
}
