import { InjectionToken } from '@angular/core';

export const INTERNALIZATION_STORE_NAME = 'intl';

export const DEFAULT_LOCALE = 'en';

export const DEFAULT_PRIME_DATE_FORMAT_MOMENT = 'D/MM/yyyy';

export const dbToLanguageAndRegionLocale: { [key: string]: string } = {
  de: 'de-DE',
  en: 'en-GB',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  uk: 'uk-UA',
  nl: 'nl-NL',
  hu: 'hu-HU',
  cs: 'cs-CZ',
};

export const dbToHubSpotLanguage: { [key: string]: string } = {
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  uk: 'Ukrainian',
  nl: 'Dutch',
  cs: 'Czech',
};

export const LOCALE_ID_NO_REGION = new InjectionToken('LOCALE_ID_NO_REGION');
export const PRIME_NG_TRANSLATION = new InjectionToken('PRIME_NG_TRANSLATION');
