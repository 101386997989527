import { NavigationBehaviorOptions, NavigationExtras, UrlTree } from '@angular/router';
import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store';

const factory = forNamespace('[ROUTER]');

const navigateActionName = 'navigate' as const;
const navigateByUrlActionName = 'navigateByUrl' as const;
const backActionName = 'back' as const;
const forwardActionName = 'forward' as const;

export const navigateBundle = factory.singleAction(
  navigateActionName,
  props<{ commands: any[]; extras?: NavigationExtras }>()
);

export const navigateByUrlBundle = factory.singleAction(
  navigateByUrlActionName,
  props<{ url: UrlTree | string; extras?: NavigationBehaviorOptions }>()
);

export const backBundle = factory.singleAction(backActionName);

export const forwardBundle = factory.singleAction(forwardActionName);

export const routerBundles = [
  navigateBundle,
  navigateByUrlBundle,
  forwardBundle,
  backBundle
];
