import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerComponent } from '../progress-spinner';

@Component({
  selector: 'db-global-progress',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerComponent],
  templateUrl: './global-progress.component.html',
  styleUrls: ['./global-progress.component.scss'],
})
export class GlobalProgressComponent {
  @Input() inProgress = false;
}
