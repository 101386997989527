import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IOfficeRolesState, selectAllOfficeRoles } from './reducer';
import { OFFICE_ROLES_STORE_NAME } from '../../constants';

const getState = createFeatureSelector<IOfficeRolesState>(OFFICE_ROLES_STORE_NAME);

export const officeRolesSelectors = {
  officeRoles: createSelector(getState, (state: IOfficeRolesState) => {
    return selectAllOfficeRoles(state);
  }),
  isLoading: createSelector(getState, (state: IOfficeRolesState) => state.isLoading),
};
