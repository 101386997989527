import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { uuid4 } from "@sentry/utils";
import { Observable } from "rxjs";
import { API_URL } from "../constants";
import { setTag } from "@sentry/browser";

@Injectable()
export class SentryTransactionInterceptor implements HttpInterceptor {
  constructor(@Inject(API_URL) private apiUrl: string) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.apiUrl)) {
      return next.handle(req);
    }
    const transactionId = this.generateUniqueTransactionId();
    const newRequest = this.attachTransactionIdToRequest(req, transactionId);

    this.attachTransactionIdToSentryScope(transactionId);

    return next.handle(newRequest);
  }

  private generateUniqueTransactionId() {
    return uuid4();
  }

  private attachTransactionIdToRequest(
    req: HttpRequest<any>,
    transactionId: string,
  ) {
    return req.clone({
      headers: req.headers.set("X-Transaction-ID", transactionId),
    });
  }

  private attachTransactionIdToSentryScope(transactionId: string) {
    setTag("transaction_id", transactionId);
  }
}
