import { I18nPluralPipe } from '@angular/common';
import { OfficeRoleCode } from 'types';

export const getOfficeRoleTranslation = (roleCode: OfficeRoleCode): string => {
  switch (roleCode) {
    case OfficeRoleCode.EVACUATION_ASSISTANT:
      return $localize`:@@common|office-roles-evacuation-assistant:Evacuation assistant`;
    case OfficeRoleCode.FIRE_RESPONDER:
      return $localize`:@@common|office-roles-fire-responder:Fire responder`;
    case OfficeRoleCode.FIRST_AIDER:
      return $localize`:@@common|office-roles-first-aider:First aider`;
    case OfficeRoleCode.KEY_HOLDER:
      return $localize`:@@common|office-roles-key-holder:Key holder`;
    default:
      return '';
  }
};

export const getOfficeRoleTranslationPlural = (roleCode: OfficeRoleCode, count: number, pluralPipe: I18nPluralPipe): string => {
  const evacuationAssistantPluralMap: { [k: string]: string } = {
    '=0': $localize`:@@common|office-roles-evacuation-assistant-pl.zero:0 Evacuation assistants`,
    '=1': $localize`:@@common|office-roles-evacuation-assistant-pl.one:1 Evacuation assistant`,
    few: $localize`:@@common|office-roles-evacuation-assistant-pl.few:# Evacuation assistants`,
    many: $localize`:@@common|office-roles-evacuation-assistant-pl.many:# Evacuation assistants`,
    other: $localize`:@@common|office-roles-evacuation-assistant-pl.other:# Evacuation assistants`,
  };

  const fireResponderPluralMap: { [k: string]: string } = {
    '=0': $localize`:@@common|office-roles-fire-responder-pl.zero:0 Fire responders`,
    '=1': $localize`:@@common|office-roles-fire-responder-pl.one:1 Fire responder`,
    few: $localize`:@@common|office-roles-fire-responder-pl.few:# Fire responders`,
    many: $localize`:@@common|office-roles-fire-responder-pl.many:# Fire responders`,
    other: $localize`:@@common|office-roles-fire-responder-pl.other:# Fire responders`,
  };

  const firstAiderPluralMap: { [k: string]: string } = {
    '=0': $localize`:@@common|office-roles-first-aider-pl.zero:0 First aiders`,
    '=1': $localize`:@@common|office-roles-first-aider-pl.one:1 First aider`,
    few: $localize`:@@common|office-roles-first-aider-pl.few:# First aiders`,
    many: $localize`:@@common|office-roles-first-aider-pl.many:# First aiders`,
    other: $localize`:@@common|office-roles-first-aider-pl.other:# First aiders`,
  };

  const keyHolderPluralMap: { [k: string]: string } = {
    '=0': $localize`:@@common|office-roles-key-holder-pl.zero:0 Key holders`,
    '=1': $localize`:@@common|office-roles-key-holder-pl.one:1 Key holder`,
    few: $localize`:@@common|office-roles-key-holder-pl.few:# Key holders`,
    many: $localize`:@@common|office-roles-key-holder-pl.many:# Key holders`,
    other: $localize`:@@common|office-roles-key-holder-pl.other:# Key holders`,
  };

  switch (roleCode) {
    case OfficeRoleCode.EVACUATION_ASSISTANT:
      return pluralPipe.transform(count, evacuationAssistantPluralMap);
    case OfficeRoleCode.FIRE_RESPONDER:
      return pluralPipe.transform(count, fireResponderPluralMap);
    case OfficeRoleCode.FIRST_AIDER:
      return pluralPipe.transform(count, firstAiderPluralMap);
    case OfficeRoleCode.KEY_HOLDER:
      return pluralPipe.transform(count, keyHolderPluralMap);
    default:
      return '';
  }
};
