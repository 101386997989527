import { IUser, UserRole } from "types";
import { ICorporateInfo, IEmailCheck } from "common-module";
import { NOT_SET } from "../constants";
import { Action, createReducer, on } from "@ngrx/store";
import {
  setUserBundle,
  loginBundle,
  setAuthResultBundle,
  setProfileImageBundle,
  logoutBundle,
  updateUserBundle,
  setFreeTrialDataBundle,
  refetchAuthDataBundle,
  microsoftTeamsLoginBundle,
  checkEmailBundle,
  patchUserBundle,
  registerBundle,
  setFavoriteResourceBundle,
  removeFavoriteResourceBundle,
  addUserOfficeIdBundle,
  loadPublicDomainsBundle,
} from "./bundles";
import { prepareUserNames } from "../utils";
import { getAbbreviation } from "shared";

export interface IAuthState {
  readonly user: IUser | null | typeof NOT_SET;
  readonly isDeskbirdAdmin: boolean;
  readonly corporateInfo: ICorporateInfo | null;
  readonly emailCheckResult: IEmailCheck | null;
  readonly initials: string | null;
  readonly publicDomains: string[] | null;
  readonly freeTrialStartDate: string | null;
  readonly freeTrialEndDate: string | null;
  readonly isSavingUser: boolean;

  readonly authResultData: {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
  } | null;
}

export const initialState: IAuthState = {
  user: NOT_SET,
  isDeskbirdAdmin: false,
  corporateInfo: null,
  emailCheckResult: null,
  authResultData: null,
  initials: null,
  publicDomains: null,
  freeTrialStartDate: null,
  freeTrialEndDate: null,
  isSavingUser: false,
};

export const authReducer = createReducer(
  initialState,
  on(setUserBundle.setUser, (state, payload) => {
    const user = payload.user;
    const { firstName, lastName } = user
      ? prepareUserNames(user.firstName, user.lastName)
      : { firstName: null, lastName: null };
    const initials = user
      ? getAbbreviation(`${firstName!.trim()} ${lastName!.trim()}`)
      : null;
    return {
      ...state,
      user: payload.user,
      isDeskbirdAdmin:
        "isDeskbirdAdmin" in payload && !!payload["isDeskbirdAdmin"],
      initials,
    };
  }),
  on(
    loginBundle.loginSuccess,
    microsoftTeamsLoginBundle.microsoftTeamsLoginSuccess,
    refetchAuthDataBundle.refetchAuthDataSuccess,
    registerBundle.registerSuccess,
    (state, { user, corporateInfo, isDeskbirdAdmin }) => {
      return {
        ...state,
        user,
        corporateInfo,
        isDeskbirdAdmin,
      };
    },
  ),
  on(patchUserBundle.patchUserSuccess, (state, { updates }) => {
    if (state.user === NOT_SET || state.user === null) return state;
    return { ...state, user: { ...state.user, ...updates } };
  }),
  on(setAuthResultBundle.setAuthResult, (state, authResultData) => {
    return {
      ...state,
      authResultData,
    };
  }),
  on(setAuthResultBundle.setAuthResultCleanup, (state) => {
    return {
      ...state,
      authResultData: null,
    };
  }),
  on(checkEmailBundle.checkEmailSuccess, (state, { emailCheckResult }) => {
    return {
      ...state,
      emailCheckResult,
      email: emailCheckResult?.email || null,
    };
  }),
  on(setProfileImageBundle.setProfileImage, (state, { profileImage }) => {
    return { ...state, profileImage };
  }),
  on(setProfileImageBundle.setProfileImageCleanup, (state) => {
    return { ...state, profileImage: null };
  }),

  on(updateUserBundle.updateUser, (state) => ({
    ...state,
    isSavingUser: true,
  })),
  on(updateUserBundle.updateUserSuccess, (state, { updates, userId }) => {
    const { user: stateUser } = state;
    if (stateUser === null || stateUser === NOT_SET || userId !== stateUser.id)
      return state;
    return {
      ...state,
      user: { ...stateUser, ...updates },
      isSavingUser: false,
    };
  }),
  on(
    setFreeTrialDataBundle.freeTrialData,
    (state, { freeTrialStartDate, freeTrialEndDate }) => {
      return {
        ...state,
        freeTrialStartDate: freeTrialStartDate || null,
        freeTrialEndDate: freeTrialEndDate || null,
      };
    },
  ),
  on(setProfileImageBundle.setProfileImage, (state, { profileImage }) => {
    const user = state.user as IUser;
    return { ...state, user: { ...user, profileImage } };
  }),
  on(loadPublicDomainsBundle.loadPublicDomainsSuccess, (state, payload) => {
    const { publicDomains } = payload;
    return { ...state, publicDomains };
  }),
  on(addUserOfficeIdBundle.addUserOfficeId, (state, payload) => {
    const { officeId } = payload;
    if (
      state.user === NOT_SET ||
      state.user === null ||
      state.user.role === UserRole.USER
    ) {
      return state;
    }

    const officeIds = state.user.officeIds?.concat(officeId);
    const user = { ...state.user, officeIds };
    return { ...state, user };
  }),
  on(setFavoriteResourceBundle.setFavoriteResource, (state, payload) => {
    const { resource } = payload;
    const user = state.user;
    if (!user) {
      return state;
    }
    const favoriteResources = (user as IUser).favoriteResources || [];
    const updatedUser = {
      ...(user as IUser),
      favoriteResources: [...favoriteResources, resource],
    };
    return { ...state, user: updatedUser };
  }),
  on(removeFavoriteResourceBundle.removeFavoriteResource, (state, payload) => {
    const { resourceId } = payload;
    const user = state.user;
    if (!user) {
      return state;
    }
    const favoriteResources = (user as IUser).favoriteResources || [];
    const updatedUser = {
      ...(user as IUser),
      favoriteResources: favoriteResources.filter(
        (fav) => fav.id !== resourceId,
      ),
    };
    return { ...state, user: updatedUser };
  }),
);

export function generateLogoutStateCleaner<State>(
  fn: (state: State) => State = () => ({}) as State,
) {
  return function logoutStateCleaner(
    reducer: (state: State, action: Action) => State,
  ) {
    return (state: State, action: Action) => {
      return reducer(
        logoutBundle.logoutSuccess.type === action.type
          ? {
              auth: { ...initialState, user: null },
              ...fn(state),
            }
          : state,
        action,
      );
    };
  };
}
