<div
  class="menu-item"
  [ngClass]="{
    active: workspaceRouterLinkActive.isActive && !disabled,
    disabled: disabled,
    loading: loading
  }"
>
  <a
    class="parent-link"
    [routerLink]="link"
    [skipLocationChange]="skipLocationChange"
    [ngClass]="{ lone: !childMenuItems.length }"
    [routerLinkActiveOptions]="linkActiveOptions"
    [routerLinkActive]="link ? linkActive : ''"
    [queryParamsHandling]="queryParamsHandling"
    #workspaceRouterLinkActive="routerLinkActive"
  >
    <img *ngIf="imgSrc && showIcon" [src]="imgSrc" alt="<image>" />
    <div class="info">
      <span class="title small-text-bold">{{ title }}</span>
    </div>
    <div *ngIf="badge" class="badge">{{ badge }}</div>
  </a>
  <div class="menu-item-children" *ngIf="childMenuItems.length">
    <ng-content></ng-content>
  </div>
</div>