import { MultiDayBookingUserMode } from 'types/enums';
import { IGuestUser, IGuestUserInfo } from './booking-guest';
import { IUserInfo } from '../user-info';

export interface IMultiDayBookingUserDto {
  userMode: MultiDayBookingUserMode;
  userId: string; //  Availability checks should be made against a real user, so in case of guest booking, the userId is the authenticated one */
  guest?: IGuestUser;
}

//** Required info for visualizing the user on booking resources */
export interface IMultiDayBookingResourceUserDto {
  userMode: MultiDayBookingUserMode;
  userId: string; //  Availability checks should be made against a real user, so in case of guest booking, the userId is the authenticated one */
  guest?: IGuestUserInfo;
  user?: IUserInfo;
}
