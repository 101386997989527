<div class="field">
  <label *ngIf="!!label" [for]="inputId" class="d-block small-text-bold">
    {{ label }}
    <ng-container *ngIf="showAsterisk">*</ng-container>
    <i *ngIf="optional" i18n="@@shared|optional-label">(optional)</i>
  </label>
  <ng-container *ngIf="type === 'text'">
    <input
      [id]="inputId"
      pInputText
      dbTrim
      #input="ngModel"
      #inputElement
      [pattern]="pattern"
      [placeholder]="placeholder"
      [required]="required === undefined ? false : true"
      [disabled]="disabled"
      [ngModel]="value"
      [name]="name"
      [attr.data-testId]="dataTestId"
      (ngModelChange)="valueChangedHandler($event)"
      (focus)="focusHandler()"
      [dbGtmEvent]="gtmEvent"
      [trackFocus]="trackFocus"
      [autocomplete]="disableAutocomplete ? 'off' : 'on'"
      [ngClass]="addClasses"
    />
    <ng-container *ngFor="let error of errorDictionaryList">
      <small
        *ngIf="!hideInternalError && (input.dirty || input.touched || !!control?.touched) && (input.errors?.[error.name] || control?.getError(error.name))"
        class="db-input-error d-block"
      >
        {{ error.getLabel(input.errors?.[error.name] || control?.getError(error.name)) }}
      </small>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="type === 'number'">
    <div class="p-fluid">
      <p-inputNumber
        [id]="inputId"
        #input="ngModel"
        [pattern]="pattern"
        [placeholder]="placeholder"
        [required]="required === undefined ? false : true"
        [disabled]="disabled"
        [ngModel]="value"
        [name]="name"
        [min]="min"
        [max]="max"
        [suffix]="suffix"
        [attr.data-testId]="dataTestId"
        (ngModelChange)="valueChangedHandler($event)"
        [dbGtmEvent]="gtmEvent"
        [trackFocus]="trackFocus"
        [autocomplete]="disableAutocomplete ? 'off' : 'on'"
        [useGrouping]="useNumberGrouping"
      ></p-inputNumber>
      <ng-container *ngFor="let error of errorDictionaryList">
        <small
          *ngIf="!hideInternalError && (input.dirty || input.touched || !!control?.touched) && (input.errors?.[error.name] || control?.getError(error.name))"
          class="db-input-error d-block"
        >
          {{ error.getLabel(input.errors?.[error.name] || control?.getError(error.name)) }}
        </small>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'textarea'">
    <textarea
      #input="ngModel"
      [placeholder]="placeholder"
      [required]="required === undefined ? false : true"
      [ngModel]="value"
      [name]="name"
      [id]="inputId"
      [disabled]="disabled"
      [attr.data-testId]="dataTestId"
      dbTrim
      pInputTextarea
      (ngModelChange)="valueChangedHandler($event)"
      [dbGtmEvent]="gtmEvent"
      [trackFocus]="trackFocus"
      [autocomplete]="disableAutocomplete ? 'off' : 'on'"
    >
    </textarea>
    <ng-container *ngFor="let error of errorDictionaryList">
      <small
        *ngIf="!hideInternalError && (input.dirty || input.touched || !!control?.touched) && (input.errors?.[error.name] || control?.getError(error.name))"
        class="db-input-error d-block"
      >
        {{ error.getLabel(input.errors?.[error.name] || control?.getError(error.name)) }}
      </small>
    </ng-container>
  </ng-container>
</div>
