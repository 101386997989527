<div
  class="link-container"
  [attr.data-testId]="dataTestId"
  [ngStyle]="style"
  [ngClass]="{
    'is-loading': isLoading,
    'fit-content': width === 'fit-content',
    'w-100': width === 'full-width',
    'severity-secondary': severity === 'secondary',
    'severity-danger': severity === 'danger',
    'with-button-padding': withButtonPadding,
    disabled: disabled
  }"
>
  <div class="icon" *ngIf="icon">
    <i class="pi" [ngClass]="icon"></i>
  </div>
  <span>
    {{ label }}
  </span>
</div>
