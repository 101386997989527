import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartEvent, Chart } from 'chart.js';
import { HiddenDirective } from '../../directives';

const staticOffset = 12;

@Component({
  selector: 'db-primeng-chart-tooltip',
  standalone: true,
  imports: [CommonModule, HiddenDirective],
  templateUrl: './primeng-chart-tooltip.component.html',
  styleUrls: ['./primeng-chart-tooltip.component.scss'],
  exportAs: 'dbPrimengChartTooltip',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrimengChartTooltipComponent<T> implements OnInit, OnDestroy, OnChanges {
  @ViewChild('tooltipElement', { static: true }) tooltipElementRef!: ElementRef<HTMLDivElement>;

  @Input() whiteTheme: undefined | string | boolean;

  @Input() x: number | null | undefined = null;
  @Input() y: number | null | undefined = null;

  @Input() data: T | null | undefined = null;

  marginTop: number = 0;
  marginLeft: number = 0;

  get useWhiteTheme() {
    return this.whiteTheme === undefined ? false : true;
  }

  tooltipResizeObserver = new ResizeObserver((entries) => {
    const tooltip = entries.find((e) => e.target.id === 'tooltip');
    if (!tooltip || !tooltip.target) {
      return;
    }
    const { height, width } = tooltip.target.getBoundingClientRect();
    this.marginTop = -height - staticOffset;
    this.marginLeft = -(width / 2);
  });

  static getTooltipData = <T>(window: Window, event: ChartEvent, activeElements: any[]) => {
    const chart: Chart = (event as any).chart;
    let tooltipDataIndex = null;
    let tooltipData = null;
    let tooltipPosition = null;
    let datasetIndex = null;
    if (activeElements && activeElements.length > 0) {
      tooltipDataIndex = activeElements[0].index;
      datasetIndex = activeElements[0].datasetIndex;

      tooltipData = chart.data.datasets ? (chart.data.datasets[datasetIndex] as T) || null : null;
      const { x, y } = event;
      tooltipPosition = { x: x || null, y: y || null };
    }
    return {
      tooltipData,
      tooltipPosition,
      tooltipDataIndex,
      datasetIndex,
      chart,
    };
  };

  ngOnInit(): void {
    this.tooltipResizeObserver.observe(this.tooltipElementRef.nativeElement);
  }

  ngOnChanges(): void {
    if (this.x === this.y && this.x === 0) {
      this.marginTop = 0;
      this.marginLeft = 0;
    }
  }

  ngOnDestroy(): void {
    this.tooltipResizeObserver.disconnect();
  }
}
