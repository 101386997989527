export function defaultStatusTypeNameMap(key: string): string {
  switch (key) {
    case "office":
      return $localize`:@@common|office:Office`;
    case "home_office":
      return $localize`:@@planning-module|home-office:Home office`;
    case "travel":
      return $localize`:@@planning-module|business-travel:Business travel`;
    case "absent":
      return $localize`:@@planning-module|absent:Absent`;
    case "other_office":
      return $localize`:@@planning-module|other-office:Other office`;
    case "office_w_o_booking":
      // Analytics usage only
      return $localize`:@@planning-module|office-without-bookings:Office w/o booking`;
    case "deleted_status":
      // Analytics usage only
      return $localize`:@@planning-module|deleted-status:Deleted status`;
    case "mobile_work":
      return $localize`:@@planning-module|mobile-work:Mobile work`;
    case
      "unknown":
    default:
      return $localize`:@@planning-module|unknown:Unknown`
  }
}

