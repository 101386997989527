import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CONFIRMATION_STORE_NAME } from "../constants";
import { IConfirmationState } from "./reducer";

export const getConfirmationState = createFeatureSelector<IConfirmationState>(CONFIRMATION_STORE_NAME);
export const getConfirmationConfigs = (state: IConfirmationState) => state.confirmationConfigs;

export const selectors = {
  confirmationConfigs: createSelector(getConfirmationState, getConfirmationConfigs)
};
