import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { passwordRegex } from '../constants';

@Directive({
  selector: '[ngModel][dbPasswordValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: PasswordValidatorDirective
  }]
})
export class PasswordValidatorDirective {

  validate(control: AbstractControl): ValidationErrors | null {
    const password = control.value;

    const errors: { [key: string]: boolean | undefined } = {};

    if (!passwordRegex.hasAtLeastOneLetter.test(password) || !passwordRegex.hasAtLeastOneDigit.test(password)) {
      errors['hasMixOfLettersAndNumbers'] = false;
    }
    if (!passwordRegex.hasOneUpperCase.test(password)) {
      errors['hasOneUpperCase'] = false;
    }
    if (!passwordRegex.hasOneSpecialChar.test(password)) {
      errors['hasOneSpecial'] = false;
    }

    if (Object.values(errors).length > 0) { return errors; }

    return null;
  }
}
