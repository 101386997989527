import { Component } from '@angular/core';
import { Lottie, LottiePlayerComponent } from 'lottie-module';
import { RouterModel } from 'router-module';
import { AuthModel } from '../+store/model';
import { ButtonComponent, DialogContainerComponent, DialogCardComponent } from 'db-ui';

@Component({
  selector: 'db-sign-out-confirmation',
  templateUrl: './sign-out-confirmation.component.html',
  imports: [DialogContainerComponent, DialogCardComponent, ButtonComponent, LottiePlayerComponent],
  standalone: true,
})
export class SignOutConfirmationComponent {
  signOutLottie = Lottie.SIGN_OUT;

  constructor(private authModel: AuthModel, private routerModel: RouterModel) {}

  handleSignOutConfirmationConfirmation(result: boolean): void {
    this.routerModel.navigationOutlet('logout', []);
    if (!result) {
      return;
    }
    this.authModel.actions.dispatch.logout({});
  }
}
