import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { IsActiveMatchOptions, Params, QueryParamsHandling, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'db-inner-nav-link',
  templateUrl: './inner-nav-link.component.html',
  styleUrls: ['./inner-nav-link.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class InnerNavLinkComponent implements OnChanges {

  @Input() link!: string | any[];
  @Input() options: { exact: boolean; } | IsActiveMatchOptions = { exact: false };
  @Input() isActive: boolean | undefined;

  // NOTE: prevent default behavior for html title attribute
  @HostBinding('attr.title') get getTitle(): null { return null; }

  @Input() title = '';
  @Input() queryParamsHandling: QueryParamsHandling | null | undefined;
  @Input() queryParams: Params | null | undefined;
  useRouterLink = false;

  constructor() { }

  ngOnChanges(): void {
    this.useRouterLink = Array.isArray(this.link) || typeof this.link === 'string';
  }
}
