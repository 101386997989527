import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IUser, IUserInfo } from 'types';
import { AvatarModule } from "primeng/avatar";
import { ExecPipe } from "../../pipes";
import { getAbbreviation } from '../../utils';

@Component({
  selector: 'db-user-item',
  standalone: true,
  imports: [CommonModule, AvatarModule, ExecPipe],
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserItemComponent {
  @Input() user!: IUser | IUserInfo;
  readonly getAbbreviation = getAbbreviation;
}