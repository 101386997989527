<div class="list-item-col-content">
  <div class="list-item-col-content-row">
    <div class="list-item-title small-text-bold flex-center text-gray">
      <ng-container *ngIf="itemTitle">{{ itemTitle }}</ng-container>
      <ng-content select="[entryTitle]"></ng-content>
    </div>
    <ng-container *ngIf="itemSubTitle">
      <div class="vertical-dash"></div>
      <div class="list-item-subtitle small-text">
        {{ itemSubTitle }}
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="startTime && endTime"
    class="list-item-col-content-row very-small-text text-dark-gray"
  >
    <ng-container *ngIf="!isFullDay; else fullDay">
      <db-start-end-time
        [authUser]="authUser"
        [officeTimeZone]="timezone"
        [startTime]="startTime"
        [endTime]="endTime"
      ></db-start-end-time>
    </ng-container>

    <ng-template #fullDay>
      <ng-container i18n="@@common|full-day">Full day</ng-container>
    </ng-template>
  </div>
</div>
<div class="list-item-col-quick-action">
  <ng-content select="[itemQuickAction]"></ng-content>
</div>
