import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResolveDirective } from './resolve.directive';

export * from './resolve-bundle';
export * from './resolve.directive';

@NgModule({
  declarations: [
    ResolveDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ResolveDirective
  ]
})
export class ResolveModule { }
