import { Observable } from 'rxjs';
export enum SlideOverSize {
  Small = 'small',
  Medium = 'medium',
  Larger = 'larger',
}

export interface ISlideOverConfigItem {
  component: any;
  path: string | RegExp;
  exact?: boolean;
  size?: SlideOverSize;
  on?: {
    [event: string]: (...args: any[]) => void;
  };
  data?: any;
  inputs?: {
    [inputKey: string]: any;
  };
  asyncInputs?: {
    [inputKey: string]: Observable<any>;
  };
}

export interface ISlideOverProcessedConfigItem {
  component: any;
  isVisible: boolean;
  path: string | RegExp;
  isAboutToDisappear: boolean;
  size?: SlideOverSize;
}

export type ISlideOverConfig = ISlideOverConfigItem[];
export type ISlideOverProcessedConfig = ISlideOverProcessedConfigItem[];
