import { IOfficeRoleOption } from 'types';
import { CompanyStatus } from '../enums';
import { ICompanyPrivacySettings } from './company-privacy-settings';
import { IExternalProvider } from './external-provider';
import { IIntegration } from './integration';

export interface ICompany extends ICompanyPrivacySettings {
  id: string;
  uuid: string;
  name: string;
  emailAddress: Array<string>;
  logo: string;
  createdByUserId: string;

  domains: Array<string>;

  createdAt: number;
  updatedAt: number;

  status: CompanyStatus;

  hrisSyncStatus?: string;
  hrisCompanyId?: string;

  allowsScheduling: boolean;
  allowsResourceBooking: boolean;
  allowsCalendarSync: boolean;
  allowsUsersToManageOfficeRoles: boolean;
  allowsCalendarSyncOnScheduling: boolean;
  allowsMeetingRoomEquipment: boolean;

  inviteOnlyAccess: boolean;
  loginMethods: {
    google: {
      enabled: boolean;
    };
    email: {
      enabled: boolean;
    };
    saml: {
      enabled: boolean;
      providerId?: string;
    };
    microsoft: {
      enabled: boolean;
    };
  };
  extraInformation?: string;
  externalProvidersData: IExternalProvider;
  peopleAnalyticsPrivacyBarrier?: number;

  trialStartDate?: Date;
  trialEndDate?: Date;
  trialExpired?: boolean;

  integrations?: IIntegration[];

  officeRoles: IOfficeRoleOption[];
}
