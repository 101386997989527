<ng-container *ngFor="let chip of options">
  <db-chip
    [label]="chip.label"
    [size]="'normal'"
    [styleClass]="
      (selectedValues | includes : chip.value) ? 'chip-db-purple' : ''
    "
    (click)="buttonClickHandler(chip.value)"
  >
  </db-chip>
</ng-container>
