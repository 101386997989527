<dotlottie-player
  *ngIf="lottie"
  [ngStyle]="{ width: width, height: height, margin: margin }"
  [attr.src]="lottie | exec : getLottie"
  [attr.background]="background"
  [attr.speed]="speed"
  [attr.loop]="loop"
  [attr.autoplay]="autoplay"
>
</dotlottie-player>
