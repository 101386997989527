import { Inject, Injectable } from "@angular/core";
import {
  createErrorHandler,
  getCurrentScope,
  SentryErrorHandler,
} from "@sentry/angular";
import { ENVIRONMENT } from "./constants";
import { Environment } from "./enums";
import { IUser } from "types";
import { isDebugEnvironment } from "./utils";

@Injectable({
  providedIn: "root",
})
export class SentryService {
  sentry: SentryErrorHandler;

  updateUser: (user: IUser | Partial<IUser> | null) => void;

  constructor(@Inject(ENVIRONMENT) environment: Environment) {
    if (isDebugEnvironment(environment)) {
      this.sentry = { handleError: console.error } as any;
      this.updateUser = (user: Partial<IUser> | null): void => {
        console.log("set sentry user as:", user?.uuid);
      };
    } else {
      this.sentry = createErrorHandler({ showDialog: false });
      this.updateUser = (user: Partial<IUser> | null): void => {
        this.setSentryUserUuid(user);
      };
    }
  }

  private setSentryUserUuid(user: Partial<IUser> | null): void {
    getCurrentScope().setUser(user ? { id: user.uuid } : null);
  }

  handleError(error: unknown): void {
    this.sentry.handleError(error);
  }
}
