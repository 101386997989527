import { Observable } from 'rxjs';

type UnpackObservable<F extends any> = F extends Observable<infer U> ? U : F;
type UnpackArrayWithObservables<F extends Readonly<Array<Observable<any>>>> =
  F extends Readonly<Array<infer U>> ? { [B in keyof F]: UnpackObservable<F[B]> } : never;

type ResolveConfig<T extends Readonly<Array<F>>, F = any> = {
  dispatchRequest: (...args: T) => void,
  dispatchRequestCancel: (...args: T) => void,
  requestSuccess$: Observable<any>;
  requestFailure$: Observable<any>;
}

type Action = {
  [key: string | number]: any;
}

type ActionWithTimestamp = {
  [key: string | number]: any;
}

type ResolveConfigWithTimestamp<T extends Readonly<Array<F>>, F = any> = {
  dispatchRequest: (timestamp: number, ...args: T) => void | Action | ActionWithTimestamp,
  dispatchRequestCancel: (timestamp: number, ...args: T) => void,
  requestSuccess$: Observable<ActionWithTimestamp>;
  requestFailure$: Observable<ActionWithTimestamp>;
}

export function createResolveBundle<T extends Readonly<Array<any>>>(...dependencies: T) {
  return function (handlers: ResolveConfig<UnpackArrayWithObservables<T>>) {
    return {
      withTimestamp: false,
      dependencies,
      ...handlers
    };
  };
}

export function createResolveBundleWithTimestamp<T extends Readonly<Array<any>>>(...dependencies: T) {
  return function (handlers: ResolveConfigWithTimestamp<UnpackArrayWithObservables<T>>) {
    return {
      withTimestamp: true,
      dependencies,
      ...handlers
    };
  };
}

export type ResolveBundle = ReturnType<ReturnType<typeof createResolveBundle>>;