import { AreaSpot } from '../enums';
import { DeskAreaType } from 'types';

export const areaTypeSuffixMap = {
  [DeskAreaType.FlexDesk]: AreaSpot.DESK,
  [DeskAreaType.Parking]: AreaSpot.PARKING_SPOT,
  [DeskAreaType.MeetingRoom]: AreaSpot.MEETING_ROOM,
  [DeskAreaType.Other]: AreaSpot.OTHER,
};

// Following suffixes are not translated as they are used to name the resource items in the UI and the user is free to adjust as they want
export const areaTypeResourceSuffixMap = {
  [DeskAreaType.FlexDesk]: "Desk",
  [DeskAreaType.Parking]: "Spot",
  [DeskAreaType.MeetingRoom]: "Spot",
  [DeskAreaType.Other]: "Item",
};
