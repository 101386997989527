<div *ngIf="isLoadingCoordinates || errorLoadingApi" id="loading-container">
  <db-progress-spinner *ngIf="!errorLoadingApi"></db-progress-spinner>
  <div *ngIf="errorLoadingApi">Error loading google maps!</div>
</div>
<div
  *ngIf="apiMapsApiLoaded$ | async"
  [dbHidden]="isLoadingCoordinates || errorLoadingApi"
>
  <google-map
    #googleMap
    [height]="height"
    [width]="width"
    [options]="options"
    (mapClick)="mapClickHandler($event)"
  >
    <map-marker
      *ngIf="options?.center"
      [position]="$any(options!.center)"
      [options]="{ draggable: false, icon: '/assets/icons/red-pin.svg' }"
    >
    </map-marker>
  </google-map>
</div>
