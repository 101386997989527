<p-toast position="bottom-left" class="db-toast">
  <ng-template let-message pTemplate="message">
    <div class="p-toast-icon">
      <i [ngClass]="message.severity | exec : getNotificationIconName"></i>
    </div>
    <div class="p-toast-icon-message-container">
      <div
        class="p-toast-message-container"
        data-testId="notification--message-container"
      >
        <div class="p-toast-summary">
          {{ message.summary }}
        </div>
        <div class="p-toast-detail">
          {{ message.detail }}
        </div>
      </div>
      <div class="p-toast-link-container">
        <a
          *ngIf="message.data.link"
          [routerLink]="message.data.link.route"
          class="p-toast-link normal-text-bold"
          >{{ message.data.link.label }}
        </a>
      </div>
    </div>
  </ng-template>
</p-toast>
