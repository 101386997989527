<p-steps
  [activeIndex]="activeIndex"
  [model]="$any(model)"
  [readonly]="readonly"
  [style]="style"
  [styleClass]="$any(styleClass)"
></p-steps>
@if (completedTemplateRef) {
  <ul id="completed-steps-container">
    @for (item of model; track item) {
      <li class="completed-step">
        <span>
          <ng-container
            *ngTemplateOutlet="
              completedTemplateRef;
              context: {
                $implicit: item,
                isCompleted: (item | exec: isStepCompleted),
              }
            "
          ></ng-container>
        </span>
        <!-- NOTE: We need this in order fro the li elements to expand exactly as much as the ones inside the p-steps -->
        <span class="completed-steps-header">{{ item.label }}</span>
      </li>
    }
  </ul>
}
