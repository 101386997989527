import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { NotificationModel, NotificationType } from 'notification-module';
import { Observable, catchError, mergeMap, switchMap, takeUntil, throwError } from 'rxjs';
import { BookingV2Service, getBookingUpsertErrorCodeMessageMap } from 'shared';
import { ICreateBookingForEmployee, IMultiDayBookingsCreationResult } from 'types/interfaces';

export function createQuickBookEffect(
  bookingV2Service: BookingV2Service,
  notificationModel: NotificationModel,
  action: Observable<
    {
      booking: ICreateBookingForEmployee;
      day?: string;
    } & TypedAction<string>
  >,
  actionListenerCancel: Observable<TypedAction<string>>,
  actionCreatorSuccess:
    | ActionCreator<
        string,
        (props: { response: IMultiDayBookingsCreationResult; zoneItemId: number }) => {
          response: IMultiDayBookingsCreationResult;
          zoneItemId: number;
        } & TypedAction<string>
      >
    | ActionCreator<
        string,
        (props: { response: IMultiDayBookingsCreationResult; zoneItemId: number; day: string }) => {
          response: IMultiDayBookingsCreationResult;
          zoneItemId: number;
          day: string;
        } & TypedAction<string>
      >,
  actionCreatorFailure:
    | ActionCreator<
        string,
        (props: { error: any; zoneItemId: number }) => {
          error: any;
          zoneItemId: number;
        } & TypedAction<string>
      >
    | ActionCreator<
        string,
        (props: { error: any; zoneItemId: number; day: string }) => {
          error: any;
          zoneItemId: number;
          day: string;
        } & TypedAction<string>
      >
) {
  return action.pipe(
    mergeMap(({ booking, day }) =>
      bookingV2Service.createBookings([{ ...booking }]).pipe(
        takeUntil(actionListenerCancel),
        switchMap((response: IMultiDayBookingsCreationResult) => {
          if (response.failedBookings.length) {
            const { error } = response.failedBookings[0];
            return throwError(() => error);
          }

          return [actionCreatorSuccess({ response, zoneItemId: booking.zoneItemId, day: day || '' })];
        }),
        catchError((error) => {
          const message = getBookingUpsertErrorCodeMessageMap()[error?.errorCode as string];
          return [
            actionCreatorFailure({ error, zoneItemId: booking.zoneItemId, day: day || '' }),
            notificationModel.actions.create.showNotification({
              data: message || $localize`:@@booking-module|error-create-booking:Error creating a booking`,
              notificationType: NotificationType.ERROR,
            }),
          ];
        })
      )
    )
  );
}
