<ng-container
  *ngIf="
    !(loadingResourceIds$ | async | exec : arrayIncludes : resourceItem.id);
    else loader
  "
>
  <!-- Icon for already a fav space is always visible while icon for adding a fav space is visible only on hover. -->

  <img
    *ngIf="
      authUser && authUser.favoriteResources! | exec : isFavoriteResource;
      else addFavSpace
    "
    [attr.data-testId]="
      (dataTestIdBase || dataTestIdBaseDefault) + 'button-add'
    "
    class="favorite"
    src="./assets/icons/fav-spaces/heart-purple-full.svg"
    alt="Favorite spaces"
    [pTooltip]="removeSpaceTooltipMessage"
    tooltipPosition="top"
    (dbDebounceClick)="iconClickHandler($event, true)"
    dbGtmEvent="click_favorite_space_button"
    [dbGtmEventProps]="resourceItem | exec : getGtmProps : true"
  />

  <ng-template #addFavSpace>
    <ng-container *ngIf="isResourceAvailableForAdding">
      <div [ngClass]="{ 'visible-on-parent-hover': !alwaysVisible }">
        <img
          class="cta-image"
          src="./assets/icons/fav-spaces/heart-purple.svg"
          alt="Favorite spaces"
        />

        <img
          [attr.data-testId]="
            (dataTestIdBase || dataTestIdBaseDefault) + 'button-remove'
          "
          class="action-image"
          src="./assets/icons/fav-spaces/heart-purple-full.svg"
          alt="Favorite spaces"
          [pTooltip]="addSpaceTooltipMessage"
          tooltipPosition="top"
          (dbDebounceClick)="iconClickHandler($event, false)"
          dbGtmEvent="click_favorite_space_button"
          [dbGtmEventProps]="resourceItem | exec : getGtmProps : false"
        />
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #loader>
  <db-progress-spinner></db-progress-spinner>
</ng-template>
