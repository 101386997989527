export interface TableLazyLoadEvent<T extends string> {
  rows: number;
  first: number;
  sortOrder: number;
  filters: {
    [K in T]: {
      value: string | null;
      matchMode: 'startsWith';
    }
  };
}
