import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ScrollableItemHeight } from '../types';

//** Used for some rendered content that is not visible but needed for a better scrolling experience */
const BufferItemsAbove = 3;
const BufferItemsBelow = 3;

export class DynamicSizeVirtualScrollStrategy implements VirtualScrollStrategy {
  private index$ = new Subject<number>();
  private viewport: CdkVirtualScrollViewport | null = null;
  private scrollableItems: ScrollableItemHeight[] = [];

  scrolledIndexChange = this.index$.pipe(distinctUntilChanged());

  updateContent(value: ScrollableItemHeight[]) {
    this.scrollableItems = value;

    if (this.viewport) {
      this.viewport.checkViewportSize();
    }
  }

  attach(viewport: CdkVirtualScrollViewport) {
    this.viewport = viewport;
    this.viewport.setTotalContentSize(this.getTotalHeight());
    this.updateRenderedRange();
  }

  detach() {
    this.index$.complete();
    this.viewport = null;
  }

  onContentScrolled() {
    if (this.viewport) {
      this.updateRenderedRange();
    }
  }

  onDataLengthChanged(): void {
    if (!this.viewport) {
      return;
    }

    this.viewport.setTotalContentSize(this.getTotalHeight());
    this.updateRenderedRange();
  }

  /** These do not matter for this case */
  onContentRendered() {}
  onRenderedOffsetChanged() {}

  scrollToIndex(index: number, behavior: ScrollBehavior) {
    if (this.viewport) {
      const offset = this.getOffsetForIndex(index);
      this.viewport.scrollToOffset(offset + 1, behavior);
    }
  }

  private updateRenderedRange() {
    if (!this.viewport) {
      return;
    }

    const scrollOffset = this.viewport.measureScrollOffset();
    const scrollIndex = this.getIndexForOffset(scrollOffset);
    const dataLength = this.viewport.getDataLength();
    const renderedRange = this.viewport.getRenderedRange();
    const range = {
      start: renderedRange.start,
      end: renderedRange.end,
    };

    range.start = Math.max(0, scrollIndex - BufferItemsAbove);
    range.end = Math.min(dataLength, scrollIndex + this.determineItemsCountInViewport(scrollIndex) + BufferItemsBelow);

    this.viewport.setRenderedRange(range);
    this.viewport.setRenderedContentOffset(this.getOffsetForIndex(range.start));
    this.index$.next(scrollIndex);
  }

  //** Determines the number of messages in the viewport given a start index of an item */
  private determineItemsCountInViewport(startIndex: number): number {
    if (!this.viewport) {
      return 0;
    }

    let totalSize = 0;
    // That is the height of the scrollable container (i.e. viewport)
    const viewportSize = this.viewport.getViewportSize();

    for (let i = startIndex; i < this.scrollableItems.length; i++) {
      const item = this.scrollableItems[i];
      totalSize += this.getItemHeight(item);

      if (totalSize >= viewportSize) {
        return i - startIndex + 1;
      }
    }

    return 0;
  }

  /**
   * Returns the item index by a provided offset.
   */
  private getIndexForOffset(offset: number): number {
    let accumulatedOffset = 0;

    for (let i = 0; i < this.scrollableItems.length; i++) {
      const item = this.scrollableItems[i];
      const itemHeight = this.getItemHeight(item);
      accumulatedOffset += itemHeight;

      if (accumulatedOffset >= offset) {
        return i;
      }
    }

    return 0;
  }

  /**
   * Returns the offset relative to the top of the container by a provided item index.
   */
  private getOffsetForIndex(idx: number): number {
    const offset = this.measureItemsHeight(this.scrollableItems.slice(0, idx));
    return offset;
  }

  /**
   * Returns the total height of the scrollable container
   * given the size of the elements.
   */
  private getTotalHeight(): number {
    return this.measureItemsHeight(this.scrollableItems);
  }

  private getItemHeight(item: ScrollableItemHeight): number {
    return item.height;
  }

  private measureItemsHeight(items: ScrollableItemHeight[]): number {
    return items.map((m) => this.getItemHeight(m)).reduce((a, c) => a + c, 0);
  }
}
