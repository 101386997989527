import { distinctUntilChanged, Observable } from "rxjs";
import { checkObjectsForDifferences } from "../utils/check-objects-for-differences";

export function distinctUntilChangedDiff<T>(formatterFn?: (a: T, b: T) => [any, any]) {
  return function (source: Observable<T>): Observable<T> {
    return source.pipe(distinctUntilChanged((a, b) => {
      [a, b] = formatterFn ? formatterFn(a, b) : [a, b];
      return !checkObjectsForDifferences(a, b);
    }));
  };
}
