<div class="autocomplete-users">
  <p-autoComplete
    [delay]="0"
    [(ngModel)]="selectedUsers"
    [dataKey]="'email'"
    [field]="'email'"
    [suggestions]="suggestions"
    (completeMethod)="onSearch($event)"
    [multiple]="true"
    [placeholder]="placeholder"
    [size]="'small'"
    [forceSelection]="true"
    [autoOptionFocus]="true"
    [showClear]="true"
    [emptyMessage]="emptyMessage"
    [minLength]="1"
    [scrollHeight]="'320px'"
    [lazy]="true"
    [style]="{ width: '462px', 'max-width': '462px' }"
    [panelStyle]="{ 'max-width': '462px' }"
    (onSelect)="onSelect()"
    (onUnselect)="onUnselect()"
    (onClear)="onClearAll()"
    (onBlur)="onBlur()"
    [attr.data-testid]="dataTestId"
  >
    <ng-template let-user pTemplate="item">
      <div
        class="d-flex align-items-center"
        [ngStyle]="{ 'max-width': '462px' }"
      >
        @if (user.id) {
          <db-user-info
            [style]="{ width: '100%' }"
            [userInfo]="user"
            [showUserEmail]="true"
            [fullWidth]="true"
            containerPadding="10px 16px"
            containerDataTestId="autocomplete--search-result"
            avatarDataTestId="autocomplete--search-result-avatar"
            [showEmailIfNameNotAvailable]="true"
            [ignoreEmailMaxWidth]="true"
          >
          </db-user-info>
        } @else {
          <div class="external-list-item">
            <div class="icon">
              <i class="svg-email"></i>
            </div>
            <span class="title">
              {{ user.email }}
            </span>
          </div>
        }
      </div>
    </ng-template>
    <ng-template let-user pTemplate="selectedItem">
      @if (user.id) {
        <db-avatar-chip
          class="d-inline-flex"
          label="{{
            user.firstName && user.lastName
              ? (user?.firstName || '') + ' ' + (user?.lastName || '')
              : user.email
          }}"
          [avatarColor]="user?.avatarColor"
          [avatarImgSrc]="user?.profileImage"
          [size]="'normal'"
          [attr.data-testid]="dataTestId + '--selected-item'"
        ></db-avatar-chip>
      } @else {
        <div class="external-pill">
          <div class="icon">
            <i class="svg-email"></i>
          </div>
          <span class="title">
            {{ user.email }}
          </span>
        </div>
      }
    </ng-template>
    <ng-template pTemplate="clearicon">
      <i class="pi pi-times"></i>
    </ng-template>
    <ng-template pTemplate="removetokenicon">
      <i class="svg-delete-sign remove-pill-icon"></i>
    </ng-template>
  </p-autoComplete>
</div>
