export const reorderItems = <T extends { order: number }>(items: T[], dragIndex: number, dropIndex: number): T[] => {
  const reorderedItems = [...items];
  const [removedItem] = reorderedItems.splice(dragIndex, 1);
  reorderedItems.splice(dropIndex, 0, removedItem);

  const updatedOrderItems = reorderedItems.map((item, index) => ({
    ...item,
    order: index + 1,
  }));

  return updatedOrderItems;
};
