import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiErrorResponse, IBaseResponse, IOfficeEquipmentItem } from 'types';

@Injectable({
  providedIn: 'root',
})
export class OfficeEquipmentService {
  private http = inject(HttpClient);

  loadOfficeEquipment(officeId: string): Observable<IBaseResponse<Array<IOfficeEquipmentItem>> | IApiErrorResponse> {
    const url = `/api/offices/${officeId}/equipment`;
    return this.http.get<IBaseResponse<Array<IOfficeEquipmentItem>> | IApiErrorResponse>(url);
  }

  createOfficeEquipmentItem(officeId: string, data: IOfficeEquipmentItem): Observable<IOfficeEquipmentItem> {
    const url = `/api/offices/${officeId}/equipment`;
    return this.http.post<IOfficeEquipmentItem>(url, data);
  }

  deleteOfficeEquipmentItem(officeId: string, itemId: string): Observable<void> {
    return this.http.delete<void>(`/api/offices/${officeId}/equipment/${itemId}`);
  }
}
