import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ButtonIconComponent } from '../button-icon';

@Component({
  selector: 'db-accordion-item',
  standalone: true,
  imports: [CommonModule, ButtonModule, ButtonIconComponent],
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent {
  @Input() isOpened = false;
  @Input() viewOnly = false;
  @Input() styleArrowInHeader: 'after-header' | 'right-aligned' = 'right-aligned';
  @Output() viewToggled = new EventEmitter<boolean>();

  toggleOpenedHandler = (value: boolean) => {
    this.isOpened = value;
    this.viewToggled.emit(value);
  };
}
