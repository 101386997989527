import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
import { SkeletonLoaderType } from 'types';

@Component({
  selector: 'db-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  standalone: true,
  imports: [CommonModule, SkeletonModule],
})
export class SkeletonLoaderComponent {
  readonly SkeletonLoaderType = SkeletonLoaderType;

  @Input() skeletonType: SkeletonLoaderType = SkeletonLoaderType.DEFAULT;
  @Input() isBorderRounded = false;
  @Input() isBorderRound = false;
  @Input() skeletonHeight = '2rem';
  @Input() skeletonWidth = 'initial';
  @Input() skeletonClass = '';

  get borderRadius(): string {
    if (this.skeletonType === SkeletonLoaderType.CIRCLE) {
      return '';
    }

    if (this.isBorderRound) {
      return '16px';
    } else if (this.isBorderRounded) {
      return '8px';
    } else {
      return '4px';
    }
  }
}
