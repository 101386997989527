<db-chip
  [removable]="removable"
  [removeIcon]="removeIcon"
  [showBadge]="!!showBadge"
  [badgeLabel]="badgeLabel"
  [size]="size"
>
  <img *ngIf="avatarImgSrc" [src]="avatarImgSrc" alt="avatar" />
  <p-avatar
    [size]="size"
    *ngIf="!avatarImgSrc"
    [label]="label || '' | exec : getAbbreviation"
    [style]="{ 'background-color': avatarColor, color: '#fff' }"
    shape="circle"
  >
  </p-avatar>
  <div id="avatar-chip-content" [ngStyle]="contentStyles">
    {{ label }}
    <ng-content></ng-content>
  </div>
</db-chip>
