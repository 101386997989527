import { Component, Input } from '@angular/core';
import { RouterModel } from 'router-module';
import { map } from 'rxjs';

@Component({
  selector: 'db-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

  @Input() showContent = true;

  showLogoutDialog$ = this.routerModel.selectors.path$.pipe(map(path => /logout:logout/.test(path)));

  constructor(private routerModel: RouterModel) { }

}
