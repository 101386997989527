import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { lottieMap } from '../constants';
import { Lottie } from '../enums';
import '@dotlottie/player-component';
import { ExecPipe } from 'shared';
import { JsonPipe, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'db-lottie-player',
  templateUrl: './lottie-player.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [ExecPipe, JsonPipe, NgIf, NgStyle],
})
export class LottiePlayerComponent {
  readonly Lottie = Lottie;

  @Input() lottie: Lottie | null = null;
  @Input() width = '250px';
  @Input() height = '250px';
  @Input() margin = '0px';
  @Input() background = 'transparent';
  @Input() speed = 1;
  @Input() autoplay = true;
  @Input() loop = false;

  getLottie(lottie: Lottie): string {
    return lottieMap[lottie];
  }
}
