import { Component, OnDestroy } from '@angular/core';
import { Params, Router, NavigationCancel } from '@angular/router';
import { AuthModel } from 'auth-module';
import { RouterModel } from 'router-module';
import { Subscription, take, filter, withLatestFrom } from 'rxjs';
import { WindowService } from '../window.service';

@Component({
  selector: 'db-error-unexpected',
  templateUrl: './error-unexpected.component.html',
  styleUrls: ['./error-unexpected.component.scss'],
})
export class ErrorUnexpectedComponent implements OnDestroy {
  user$ = this.authModel.user$;

  previousOutlets!: {
    [outletName: string]: string[];
    primary: string[];
  };

  previousQueryParams!: Params;

  sub = new Subscription();

  constructor(
    private authModel: AuthModel,
    private routerModel: RouterModel,
    private router: Router,
    private windowService: WindowService
  ) {
    if (!router.navigated) {
      this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
    }

    this.routerModel.selectors.previousOutlets$.pipe(take(1)).subscribe((previousOutlets): void => {
      this.previousOutlets = previousOutlets;
    });

    this.routerModel.selectors.previousQueryParams$.pipe(take(1)).subscribe((previousQueryParams): void => {
      this.previousQueryParams = previousQueryParams;
    });
  }

  refresh(): void {
    if (this.previousOutlets.primary.includes('offline') || this.previousOutlets.primary.includes('error')) {
      this.previousOutlets = { primary: ['default'] };
      this.previousQueryParams = {};
    }

    this.routerModel.actions.dispatch.navigate({
      commands: [{ outlets: this.previousOutlets }],
      extras: { queryParams: this.previousQueryParams },
    });

    this.sub.add(
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationCancel),
          withLatestFrom(this.authModel.isLoggedIn$, this.authModel.selectors.emailCheckResult$)
        )
        .subscribe(([navigationCancel, isLogged, emailCheckResult]): void => {
          const canceledUrl = (navigationCancel as NavigationCancel).url;
          if ((!isLogged && canceledUrl.includes('login')) || (isLogged && !canceledUrl.includes('login'))) {
            return void this.windowService.reloadWindow();
          }

          if ((isLogged && emailCheckResult) || !isLogged) {
            return void this.routerModel.actions.dispatch.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } });
          }

          this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
        })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
