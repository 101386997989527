import { Provider } from "@angular/core";
import { ADMIN_PART_PREFIX } from "../constants";

export function adminPartPrefixProviderFactory(prefix: string): Provider {
  prefix = prefix.trim();
  if (!prefix.startsWith('/')) { prefix = `/${prefix}`; }
  if (prefix.length > 1 && prefix.endsWith('/')) { prefix = prefix.slice(0, -1); }
  return {
    provide: ADMIN_PART_PREFIX,
    useValue: prefix
  };
}
