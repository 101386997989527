import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ExecPipe } from "shared-pipes";
import { canOpenProfile, getAbbreviation, userIsActive } from "shared-utils";
import {
  FollowingStatusType,
  IUser,
  IUserViewAvatar,
  IUserViewAvatarProfileOpeningProps,
} from "types";
import { userIsDeleted } from "./utils";
import { NgClass, NgStyle } from "@angular/common";
import { TooltipModule } from "primeng/tooltip";
import { AvatarSize } from "shared-types";

@Component({
  selector: "db-user-avatar",
  templateUrl: "./user-avatar.component.html",
  styleUrls: ["./user-avatar.component.scss"],
  standalone: true,
  imports: [NgClass, TooltipModule, NgStyle, ExecPipe],
})
export class UserAvatarComponent implements OnChanges {
  readonly getAbbreviation = getAbbreviation;
  readonly FollowingStatusType = FollowingStatusType;

  readonly inactiveUserMessage = $localize`:@@common|inactive-user:Deactivated user`;
  readonly deletedUserMessage = $localize`:@@common|deleted-user:Deleted user`;
  readonly anonymousUserMessage = $localize`:@@common|anonymous-user:Anonymous user`;

  imageUrl: string = "";

  @Input() userInfo!: IUserViewAvatar;
  @Input() avatarSrc: string | null | undefined = null;
  @Input() name: string | undefined; // send if you want to display something different than first and last name provided from userInfo
  @Input() avatarSize: AvatarSize = "standard"; // NOTE: when adding new sizes, please update properly the scss (check avatar, initials and favorite-star classes)
  @Input() showFavUserIcon = false;
  @Input() isAnonymous = false;
  @Input() openProfileOnClick = false;
  @Input() authUser: IUser | null = null;
  @Input() showTooltips = true;
  @Output() profileOpening = new EventEmitter<{
    event: Event;
    userInfo: IUserViewAvatarProfileOpeningProps;
  }>();

  get canOpenProfile(): boolean {
    return (
      this.openProfileOnClick && canOpenProfile(this.userInfo, this.authUser)
    );
  }

  get userIsActive(): boolean {
    return userIsActive(this.userInfo);
  }

  get userIsDeleted(): boolean {
    return userIsDeleted(this.userInfo);
  }

  get avatarTooltip(): string {
    if (this.userIsDeleted) {
      return this.deletedUserMessage;
    }
    if (!this.userIsActive) {
      return this.inactiveUserMessage;
    }
    if (this.userInfo?.isAnonymous || this.isAnonymous) {
      return this.anonymousUserMessage;
    }
    return "";
  }

  openProfileHandler(event: Event) {
    this.profileOpening.emit({ event, userInfo: this.userInfo });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["avatarSrc"] || changes["userInfo"]) {
      this.imageUrl =
        changes["avatarSrc"]?.currentValue ||
        changes["userInfo"]?.currentValue?.profileImage ||
        changes["userInfo"]?.currentValue?.avatarUrl ||
        "";

      if (this.imageUrl) {
        this.imageUrl += `?${Date.now()}`;
      }
    }
  }
}
