<p-inputSwitch
  [inputId]="inputId || dataTestId || undefined"
  [(ngModel)]="value"
  (onChange)="valueChangedHandler($event)"
  [attr.data-testId]="dataTestId"
>
</p-inputSwitch>
<div class="labels-wrapper">
  <label
    [ngClass]="{
      'small-text': !description,
      'small-text-bold': !!description
    }"
    [for]="inputId || dataTestId || undefined"
  >
    {{ label }}
  </label>
  <label
    class="small-text"
    [for]="inputId || dataTestId || undefined"
    *ngIf="value && labelTrue"
  >
    {{ labelTrue }}
  </label>
  <label
    class="small-text"
    [for]="inputId || dataTestId || undefined"
    *ngIf="!value && labelFalse"
  >
    {{ labelFalse }}
  </label>
  <div
    class="small-text"
    *ngIf="description"
  >
    {{ description }}
  </div>
</div>
