import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { DBCommonModule, Environment, isLocalEnvironment, isProductionEnvironment, isStagingEnvironment } from 'common-module';

import { AppRoutingModule } from './app-routing.module';
import { AppRouterModule } from 'router-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from 'auth-module';
import { GlobalLoaderModule } from 'loader-module';
import { NavigationModule } from 'navigation-module';
import { UploadModule } from 'upload-module';
import { GlobalModuleWithProviders, NoticeMessageComponent } from 'global-module';
import { ConfirmationModule } from 'confirmation-module';
import { globalErrorHandlerProvider } from './global-error-handler.service';
import { InternalizationModule, INTERNALIZATION_STORE_NAME } from 'internalization-module';
import { NotificationModule } from 'notification-module';
import { storeFreeze } from 'ngrx-store-freeze';
import { generateLogoutStateCleaner } from 'auth-module';
import { NOTIFICATION_STORE_NAME } from 'notification-module';
import { RouteNotFoundComponent } from './route-not-found/route-not-found.component';
import { RouteNotFoundRouterModule } from './route-not-found/not-found-router-module';
import { CustomerIoComponent } from './customer-io/customer-io.component';
import { AnalyticsTrackingModule } from 'analytics-tracking';
import { ExecPipe } from 'shared';
import { MobileBlockerComponent } from './mobile-blocker/mobile-blocker.component';
import { ButtonComponent } from 'db-ui';
import { FavoriteSpacesModule } from 'favorite-spaces-module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { UserTrackingService, ScreenTrackingService } from '@angular/fire/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { ServiceWorkerModule } from '@angular/service-worker';

const logoutStateCleaner = generateLogoutStateCleaner((state: any) => ({
  [NOTIFICATION_STORE_NAME]: state[NOTIFICATION_STORE_NAME],
  [INTERNALIZATION_STORE_NAME]: state[INTERNALIZATION_STORE_NAME],
}));

@NgModule({
  declarations: [AppComponent, RouteNotFoundComponent, CustomerIoComponent, MobileBlockerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker }),
    DBCommonModule.forRoot({
      appURL: environment.appURL,
      apiURL: environment.apiURL,
      publicApiUrl: environment.publicApiUrl,
      backendSlackLoginUrl: environment.backendSlackLoginUrl,
      environment: environment.env,
      adminPartPrefix: 'admin',
      clientPartPrefix: '',
      gtm: {
        id: environment.gtmId,
        gtm_auth: environment.gtmAuth,
        gtm_preview: environment.gtmPreview,
      },
      customerIoConfig: {
        siteId: environment.customerIoSiteId,
        useInAppMessages: environment.customerIoUseInAppMessages,
      },
    }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    // AngularFirestoreModule,
    HttpClientModule,

    AppRouterModule,
    AppRoutingModule,
    InternalizationModule,
    NotificationModule,
    AnalyticsTrackingModule,
    ExecPipe,
    ButtonComponent,
    StoreModule.forRoot(
      {} as any,
      {
        metaReducers: environment.env === Environment.PRODUCTION ? [logoutStateCleaner] : [storeFreeze, logoutStateCleaner],
      } as any
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      logOnly: isProductionEnvironment(environment.env),
      maxAge: 50,
      connectInZone: true,
    }),
    AuthModule,
    NavigationModule,
    GlobalLoaderModule,
    GlobalModuleWithProviders,
    ConfirmationModule,
    FavoriteSpacesModule, // TODO: We need to figure out another way to load this @Iliya
    UploadModule.forRoot(),
    RouteNotFoundRouterModule, // ⚠️ WARNING: keep this at the end of the list!!!
  ],
  providers: [
    globalErrorHandlerProvider,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    UserTrackingService,
    ScreenTrackingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
  ],
  bootstrap: [AppComponent, NoticeMessageComponent, CustomerIoComponent],
})
export class AppModule {}
