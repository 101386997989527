export const convertUnicodeToHtml = (unicode: string): string => {
  const unicodeArray = unicode.split('-');

  let html = '';
  for (const unicode of unicodeArray) {
    html = html.concat(`&#x${unicode};`);
  }

  return html;
};
