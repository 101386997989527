import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'db-button-icon',
  standalone: true,
  imports: [CommonModule, ButtonModule, TooltipModule, RippleModule],
  exportAs: 'dbButtonIcon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ButtonIconComponent {
  @Input() icon = '';
  @Input() dataTestId?: string;

  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() isSubmit = false;
  @Input() withBorder = false;
  @Input() dynamicSize = false;
  @HostBinding('style.--borderSize')
  @Input()
  borderSize: '1px' | '2px' = '1px';
  @Input() tooltip?: string;
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'right';

  get type(): 'button' | 'submit' {
    return this.isSubmit ? 'submit' : 'button';
  }

  /** Severity is used to control button color - example in https://www.primefaces.org/primeng-v14-lts/button
   * Default is empty string which means primary color
   */
  @Input() severity: 'primary' | 'secondary' | 'danger' = 'secondary';

  @Input() size: 'small' | 'medium' | 'large' | 'x-large' | 'xx-large' = 'medium';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() style: { [klass: string]: any } | null | undefined;

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.addEventListener('click', (event: Event) => {
      // Note : event listener is required here because stopImmediatePropagation not working properly on HostBinding - https://github.com/angular/angular/issues/9587
      if (this.isLoading || this.disabled) {
        event.stopImmediatePropagation();
      }
    });
  }
}
