import { IAssignedSpaceRestrictionDto } from '../../interfaces';
import {
  IUser,
  DeskAreaItemAccessType,
  IUserInfo,
  IDeskAreaItemAssignedSpaceProps,
  IDeskAreaItemForAvailabilitySummary,
  IDeskAreaItemForBookingSummary,
  IAssignedSpaceAccessInfo,
} from 'types';

function isForAvailability(
  resourceItem: IDeskAreaItemForBookingSummary | IDeskAreaItemForAvailabilitySummary
): resourceItem is IDeskAreaItemForAvailabilitySummary {
  return (resourceItem as IDeskAreaItemForAvailabilitySummary).dedicatedResourceOwner !== undefined;
}

export const assignedSpaceRestrictionRule = (
  item: IDeskAreaItemForBookingSummary | IDeskAreaItemForAvailabilitySummary,
  bookingUserId: string | null | undefined,
  authUser: IUserInfo
): IAssignedSpaceRestrictionDto | null => {
  const ownerId = isForAvailability(item) ? item.dedicatedResourceOwner?.id : item.userId;

  if (!item || !item.accessInfo || !ownerId) {
    return null;
  }

  const isMineSpace = ownerId === authUser.id;
  const bookForMyself = authUser.id === bookingUserId && isMineSpace;
  const isAssignedToBookingUser = ownerId === bookingUserId;

  if (!isAssignedToBookingUser && item.accessInfo.type === DeskAreaItemAccessType.SHARED) {
    return null;
  }

  const message = isMineSpace
    ? $localize`:@@common-module|message-assigned-space-mine:Assigned to you`
    : isAssignedToBookingUser && isForAvailability(item) && item.dedicatedResourceOwner
    ? $localize`:@@common-module|message-assigned-space-user:Assigned to ${item.dedicatedResourceOwner.firstName} ${item.dedicatedResourceOwner.lastName}`
    : $localize`:@@common-module|message-assigned-space-someone-else:Not available`;

  const restriction: IAssignedSpaceRestrictionDto = {
    allowToBook: bookForMyself || isAssignedToBookingUser || isMineSpace,
    iconName: isMineSpace || isAssignedToBookingUser ? 'svg-dedicated-resource' : 'svg-access-lock',
    message: message,
  };

  return restriction;
};

export const assignedSpaceRestrictionFloorPlanTooltipMessage = (
  item: {
    accessInfo?: IAssignedSpaceAccessInfo;
    dedicatedResourceOwnerId?: string;
  },
  authUserId: string
): string | null => {
  if (!item || !item.accessInfo || !item.dedicatedResourceOwnerId || item.accessInfo.type === DeskAreaItemAccessType.SHARED) {
    return null;
  }

  const isMineSpace = item.dedicatedResourceOwnerId === authUserId;

  const message = isMineSpace
    ? $localize`:@@common-module|message-assigned-space-mine:Assigned to you`
    : $localize`:@@common-module|message-assigned-space-can-not-book:You can't book here. This space is assigned to a colleague.`;

  return message;
};

export const assignedSpaceRuleAllowBooking = (
  item: IDeskAreaItemForBookingSummary | IDeskAreaItemForAvailabilitySummary,
  bookingUserId: string | null | undefined,
  authUser: IUserInfo
): boolean => {
  const restrictionRule = assignedSpaceRestrictionRule(item, bookingUserId, authUser);
  if (restrictionRule) {
    return restrictionRule.allowToBook;
  } else {
    return true;
  }
};

//** Prepares the icon which should be visible for an existing booking from a spectating user's profile perspective */
export const assignedSpaceIconForSpectatingUserRule = (
  item: IDeskAreaItemAssignedSpaceProps,
  existingBookingUserId: string | null | undefined,
  authUser: IUser
) => {
  const ownerId = item.dedicatedResourceOwner?.id || item.userId;

  if (!item || !item.accessInfo || !ownerId) {
    return null;
  }

  const isMineSpace = ownerId === authUser.id;
  const isAssignedToBookingUser = ownerId === existingBookingUserId;

  if (!isAssignedToBookingUser && item.accessInfo.type === DeskAreaItemAccessType.SHARED) {
    return null;
  }

  const iconName = isMineSpace ? 'svg-dedicated-resource' : 'svg-access-lock';
  return iconName;
};
