import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { Emoji, EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EmojiComponent } from '../emoji/emoji.component';
import { ClickOutsideDirective } from '../../directives';
import { emojiPickerI18n } from './emoji-picker-i18n';
import { convertUnicodeToHtml } from '../emoji/unicode-html-converter';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'db-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, PickerModule, EmojiComponent, ClickOutsideDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmojiPickerComponent,
      multi: true,
    },
  ],
})
/** Emoji picker based on third party lib https://www.npmjs.com/package/@ctrl/ngx-emoji-mart?activeTab=readme */
export class EmojiPickerComponent implements ControlValueAccessor {
  i18n = emojiPickerI18n();
  isPickerVisible = false;
  selectedEmoji?: EmojiData;

  value = '';
  private onChange!: (value: string | number) => {};
  private onTouch!: () => {};
  backgroundImageFn: Emoji['backgroundImageFn'] = () => '/assets/images/emoji-set.png';

  @Input() ctaDataTestId?: string;

  emojiSelectHandler(data: { emoji: EmojiData }) {
    const { emoji } = data;
    this.selectedEmoji = emoji;
    this.value = convertUnicodeToHtml(emoji.unified || '');
    this.isPickerVisible = false;

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
