import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioButton, RadioButtonModule } from 'primeng/radiobutton';
import { Subscription, debounceTime } from 'rxjs';

export interface RadioButtonClickEvent {
  originalEvent: Event;
  value: any;
}

@Component({
  selector: 'db-radio-button',
  standalone: true,
  imports: [CommonModule, RadioButtonModule, FormsModule],
  templateUrl: './radio-button.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioButtonComponent,
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor, OnDestroy {
  sub = new Subscription();
  @Input() dataTestId: string | undefined = undefined;

  // Used primeng inputs:
  @Input() value: any;
  @Input() name: string | undefined;
  @Input() inputId: string | undefined;
  @Input() label: string | undefined;
  @Input() disabled: boolean = false;

  // Unused primeng inputs (you can move to used when needed):
  // @Input() tabindex: number | undefined;
  // @Input() formControlName: string = '';
  // @Input() ariaLabelledBy: string | undefined;
  // @Input() ariaLabel: string | undefined;
  // @Input() style: { [klass: string]: any } | null | undefined;
  // @Input() styleClass: string | undefined;
  // @Input() labelStyleClass: string | undefined;

  @Output() buttonClick: EventEmitter<RadioButtonClickEvent> = new EventEmitter<RadioButtonClickEvent>();
  @Output() buttonFocus: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() buttonBlur: EventEmitter<Event> = new EventEmitter<Event>();

  @ViewChild(RadioButton, { static: true }) radioButton!: RadioButton;

  formGroup = inject(FormGroupDirective, { optional: true });
  private onChange!: (value: any) => {};
  private onTouch!: () => {};

  changeDetectorRef = inject(ChangeDetectorRef);
  ngModelValue: any;

  ngOnInit(): void {
    this.radioButton.onClick.subscribe(this.buttonClick);
    this.radioButton.onFocus.subscribe(this.buttonFocus);
    this.radioButton.onBlur.subscribe(this.buttonBlur);
    if (!this.formGroup) return;
    const control = this.formGroup.control.get(this.name || '');
    if (!control) return;
    this.sub.add(control.valueChanges.pipe(debounceTime(0)).subscribe({
      next: (value) => {
        if (value === this.ngModelValue) return;
        this.writeValue(value);
      }
    }));
  }

  writeValue(obj: any): void {
    this.ngModelValue = obj;
    this.radioButton.writeValue(obj);
    // Note:
    // for some reason we need detectChanges instead of markForCheck
    // for the prime radio button to work properly
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.radioButton.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
    this.radioButton.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.radioButton.setDisabledState(isDisabled);
    this.changeDetectorRef.markForCheck();
  }

  valueChangedHandler(event: { value: boolean }): void {
    this.ngModelValue = event.value;
    if (this.onChange) {
      this.onChange(event.value);
    }
    this.onTouch();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
