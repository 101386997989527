<div
  class="accordion-header d-flex align-items-end"
  [style.justifyContent]="
    styleArrowInHeader === 'right-aligned' ? 'space-between' : 'flex-start'
  "
>
  <ng-content select="[dbAccordionItemHeader]"></ng-content>
  <db-button-icon
    *ngIf="!viewOnly && !isOpened"
    icon="pi pi-chevron-down"
    (click)="toggleOpenedHandler(true)"
  ></db-button-icon>
  <db-button-icon
    *ngIf="!viewOnly && isOpened"
    icon="pi pi-chevron-up"
    (click)="toggleOpenedHandler(false)"
  ></db-button-icon>
</div>

<div *ngIf="isOpened" class="accordion-content">
  <ng-content select="[dbAccordionItemContent]"></ng-content>
</div>
