import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ExportRangeType } from 'common-module';
import { RouterModel } from 'router-module';
import { take } from 'rxjs';
import * as moment from 'moment';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { DialogContainerComponent } from 'db-ui';
import { RippleModule } from 'primeng/ripple';
import { exportDialogForm } from './export-dialog-form';
import { ButtonComponent, DatePickerInputComponent, RadioButtonComponent } from 'db-ui';
import { ErrorDictionaryEntry } from 'types';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DATE_FORMAT } from '../../constants/dates';

const datesErrorDictionary: ErrorDictionaryEntry[] = [
  {
    getLabel: () => $localize`:@@common|dates-error:End date must be after start date`,
    name: 'dateRange',
  },
  {
    getLabel: () => $localize`:@@common|dates-error-length:Period must be no longer than 3 months`,
    name: 'dateRangeLength3Months',
  },
];

@Component({
  selector: 'db-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogContainerComponent,
    CardModule,
    ReactiveFormsModule,
    CalendarModule,
    RippleModule,
    DatePickerInputComponent,
    RadioButtonModule,
    RadioButtonComponent,
    ButtonComponent,
  ],
})
export class ExportDialogComponent implements OnInit {
  readonly exportRangeType = ExportRangeType;
  readonly datesErrorDictionary = datesErrorDictionary;

  private readonly routerModel = inject(RouterModel);
  form!: FormGroup;

  @Input() titleText!: string;
  @Input() exportEntityText!: string;
  @Input() exportAllText?: string = '';
  @Input() exportPeriodMaxLimitMonths = 36;

  @Output() exportClick = new EventEmitter<{
    timeframe: { start: string; end: string } | undefined;
  }>();

  getExportAllLabel(): string {
    return this.exportAllText ? this.exportAllText : $localize`:@@export-module|export-dialog|export-all:Export all ${this.exportEntityText}`;
  }

  ngOnInit(): void {
    this.form = exportDialogForm(this.exportPeriodMaxLimitMonths);
  }

  toggleDateRangeActivity(): void {
    const startDateControl = this.form.get('startDate');
    const endDateControl = this.form.get('endDate');
    if (this.form.get('exportType')?.value === ExportRangeType.ALL) {
      startDateControl?.disable();
      endDateControl?.disable();
    } else {
      startDateControl?.enable();
      endDateControl?.enable();
    }
  }

  cancelHandler(): void {
    this.routerModel.selectors.path$.pipe(take(1)).subscribe((path) => {
      const newPath = path.replace('/export', '');
      this.routerModel.actions.dispatch.navigateByUrl({ url: newPath });
    });
  }

  formSubmitHandler(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const type = this.form.get('exportType')?.value;
    if (type === ExportRangeType.TIMEFRAME) {
      const startValue = this.form.get('startDate')?.value!;
      const endValue = this.form.get('endDate')?.value!;
      let startMoment = moment(startValue);
      let endMoment = moment(endValue);
      if (startMoment.format(DATE_FORMAT) === endMoment.format(DATE_FORMAT)) {
        startMoment = startMoment.startOf('day');
        endMoment = endMoment.endOf('day');
      }
      const timeframe = {
        start: startMoment.format(DATE_FORMAT),
        end: endMoment.format(DATE_FORMAT),
      };
      this.exportClick.emit({ timeframe });
    } else if (type === ExportRangeType.ALL) {
      if (this.exportPeriodMaxLimitMonths > 0) {
        const yesterday = moment().subtract(1, 'days').endOf('day');
        const limitMonthsAgo = moment().subtract(this.exportPeriodMaxLimitMonths, 'months').startOf('day');
        const timeframe = {
          start: limitMonthsAgo.format(DATE_FORMAT),
          end: yesterday.format(DATE_FORMAT),
        };
        this.exportClick.emit({ timeframe });
      } else {
        this.exportClick.emit({ timeframe: undefined });
      }
    }
  }
}
