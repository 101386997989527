import { Component } from '@angular/core';
import { AuthModel } from 'auth-module';
import { RouterModel } from 'router-module';
import { combineLatest, distinctUntilChanged, filter, map, take, withLatestFrom } from 'rxjs';
import { GlobalModel } from '../+store/global/model';

@Component({
  selector: 'db-company-switcher',
  templateUrl: './company-switcher.component.html',
  styleUrls: ['./company-switcher.component.scss'],
})
export class CompanySwitcherComponent {
  showCompanySwitcher$ = this.routerModel.selectors.path$.pipe(map((path) => /company/.test(path)));

  isDeskbirdAdmin$ = this.authModel.selectors.isDeskbirdAdmin$;
  adminAppCompanyId$ = this.globalModel.selectors.adminAppCompanyId$;
  companies$ = this.globalModel.selectors.deskbirdAdminCompanies$;
  corporateInfo$ = this.authModel.selectors.corporateInfo$;

  companyOptions$ = this.companies$.pipe(
    withLatestFrom(this.authModel.corporateInfo$),
    map(([companies, corporateInfo]) =>
      (companies || [{ id: corporateInfo!.id, name: corporateInfo!.name }]).map((w) => ({ value: w.id as string, title: w.name }))
    )
  );

  selectedCompanyOption$ = combineLatest([
    this.adminAppCompanyId$.pipe(distinctUntilChanged()),
    this.companyOptions$.pipe(distinctUntilChanged()),
  ]).pipe(map(([adminAppCompanyId, companyOptions]) => companyOptions?.find((c) => c.value === adminAppCompanyId) || null));

  showSkeletonLoader$ = this.selectedCompanyOption$.pipe(map((selectedCompanyOption) => !selectedCompanyOption));

  constructor(private routerModel: RouterModel, private globalModel: GlobalModel, private authModel: AuthModel) {}

  handleCompanySelectionChange(selectedValue: string): void {
    combineLatest([
      this.routerModel.selectors.path$,
      this.companyOptions$,
      this.adminAppCompanyId$,
      this.globalModel.selectors.adminAppOfficeId$,
      this.globalModel.selectors.allOffices$,
    ])
      .pipe(
        take(1),
        map(([path, companyOptions, currentCompanyId, currentOfficeId, offices]) => {
          const newSelectedOption = companyOptions.find((o) => o.value === selectedValue);
          const newSelectedCompanyId = newSelectedOption?.value;
          if (newSelectedCompanyId === currentCompanyId) {
            return null;
          }
          const firstNewCompanyOffice = offices?.find((o) => o.businessCompanyId === newSelectedCompanyId);
          let newCompanyPath = path.replace(`/company/${currentCompanyId}`, `/company/${newSelectedCompanyId}`);
          newCompanyPath = newCompanyPath.replace(
            firstNewCompanyOffice ? `/office/${currentOfficeId}` : /\/office(.*)/,
            firstNewCompanyOffice ? `/office/${firstNewCompanyOffice.id}` : `/office`
          );
          return newCompanyPath;
        }),
        filter((val): val is string => !!val)
      )
      .subscribe((url) => {
        return this.routerModel.actions.dispatch.navigateByUrl({ url });
      });
  }
}
