import { Component, DoCheck, HostBinding } from '@angular/core';
import { RouterModel } from 'router-module';
import { map } from 'rxjs';
import { NoticeMessageService } from './notice-message.service';

@Component({
  selector: 'db-notice-message',
  templateUrl: './notice-message.component.html',
  styleUrls: ['./notice-message.component.scss']
})
export class NoticeMessageComponent implements DoCheck {

  @HostBinding('class.visible') isVisible = false;

  isOnFreeTrialPage$ = this.routerModel.selectors.path$.pipe(map(path => path.startsWith('/free-trial')));

  constructor(
    public noticeMessageService: NoticeMessageService,
    private routerModel: RouterModel
  ) { }

  ngDoCheck(): void {
    this.isVisible = !!this.noticeMessageService._noticeMessageTemplateRef;
  }
}
