import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFeatureFlagState, selectAllFeatureFlags } from './reducer';
import { FEATURE_FLAG_STORE_NAME } from '../../constants-feature-flags';

const getAuthState = createFeatureSelector<IFeatureFlagState>(FEATURE_FLAG_STORE_NAME);

const getFeatureFlags = createSelector(getAuthState, (state: IFeatureFlagState) => selectAllFeatureFlags(state));

export const featureFlagSelectors = {
  featureFlags: getFeatureFlags,
};
