import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotNullOrUndefined',
  standalone: true,
})
export class IsNotNullOrUndefinedPipe implements PipeTransform {
  transform(value: boolean | null | undefined): unknown {
    return !(value === null || value === undefined);
  }
}
