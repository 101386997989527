import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DBCommonModule } from 'common-module';

import { SlideItemComponentPlaceholderDirective } from './slide-over/slide-item-component-placeholder.directive';
import { SlideOverComponent } from './slide-over/slide-over.component';

@NgModule({
  declarations: [SlideOverComponent, SlideItemComponentPlaceholderDirective],
  imports: [CommonModule, DBCommonModule],
  providers: [],
  exports: [SlideOverComponent]
})
export class SlideOverModule { }