export enum HybridWorkPolicyRuleRestrictionType {
  OFFICE = 'office',
  REMOTE = 'remote',
}

export enum HybridWorkPolicyRulePeriodType {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export enum HybridWorkPolicyRuleCounterType {
  DAYS = 'days', // TODO: vX: add also "percentage";
}

export enum HybridWorkPolicyRuleLimitType {
  MIN = 'min',
  MAX = 'max',
}

export enum HybridWorkPolicyActivationDateType {
  IMMEDIATELY = 'immediately',
  NEXT_MONTH = 'next_month',
  CUSTOM_DATE = 'custom_date'
}

export enum HybridWorkPolicyFixedOfficeDayComplianceType {
  COMPLIANT = 'compliant',
  NON_COMPLIANT = 'non-compliant',
  UNKNOWN = 'unknown'
}
