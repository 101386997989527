import { BookingStatus, CheckInStatus } from '../../enums';
import { IDeskAreaForBookingSummary } from '../desk-area';
import { IDeskAreaItemForBookingSummary } from '../desk-area-item';
import { IOfficeSummary } from '../offices/office-summary';
import { IUserInfo } from '../user-info';
import { IGuestUser } from './booking-guest';

export interface IBooking {
  id: string;
  bookingId: string;
  zoneId: string;
  zone: IDeskAreaForBookingSummary;
  userId: string;
  user: IUserInfo;
  workspaceId: string;
  workspace: IOfficeSummary;
  bookingStatus: BookingStatus;
  checkInAvailableFrom: number;
  checkInStatus: CheckInStatus;
  bookingStartTime: number;
  bookingEndTime: number;
  earlyReleaseEndTime?: number;
  bookingAutoCancellationTime: number;
  isDayPass: boolean;
  cancelledByUserId: string;
  cancelledBy: string;
  /**  @deprecated - always true */
  internal: boolean;
  zoneItemId: number;
  zoneItemName: string;
  isAnonymousBooking: boolean;
  anonymized: boolean;
  bookingTitle?: string;
  isDedicatedResource?: boolean;
  zoneItem?: IDeskAreaItemForBookingSummary;

  guestId: number;
  guest: IGuestUser;
  isPrivate?: boolean;
}
