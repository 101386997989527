import { Injectable, inject } from '@angular/core';
import { officeRolesBundles } from './bundles';
import { officeRolesSelectors } from './selectors';
import { connectBundles, connectSelectors } from 'ngrx-action-bundles';
import { Observable, filter, map, switchMap } from 'rxjs';
import { AuthModel, FeatureFlagModel } from 'auth-module';
import { combineLatestForFrame } from 'shared';
import { IUserOfficeRoleDto, OfficeRoleCode } from 'types';
import { distinctUntilChangedDiff } from 'common-module';
import { GlobalModel } from 'global-module';

@Injectable({ providedIn: 'root' })
export class OfficeRolesModel {
  private authModel = inject(AuthModel);
  private featureFlagModel = inject(FeatureFlagModel);
  private globalModel = inject(GlobalModel);
  selectors = connectSelectors(officeRolesSelectors);
  actions = connectBundles(officeRolesBundles);

  /** Map of office roles by users'id. In case when user has same office role in more than one office - the role will have only one entry here. Null in case of featureEnableOfficeRoles false. */
  officeRolesByUser$: Observable<Map<string, IUserOfficeRoleDto[]> | null>;

  /** Map of office roles by users'id. In case when user has same office role in more than one office - the role will be presented by array of office names. Null in case of featureEnableOfficeRoles false. */
  officeRolesByUserForAllOffices$: Observable<Map<string, Map<OfficeRoleCode, string[]>> | null>;

  constructor() {
    this.officeRolesByUser$ = this.featureFlagModel.featureEnableOfficeRoles$.pipe(
      switchMap((isEnabled) =>
        !isEnabled
          ? [null]
          : combineLatestForFrame([
              this.selectors.officeRoles$.pipe(distinctUntilChangedDiff()),
              this.authModel.corporateInfo$.pipe(filter(Boolean), distinctUntilChangedDiff()),
            ]).pipe(
              map(([officeRoles, corporateInfo]) => {
                if (!officeRoles) {
                  return null;
                }

                const officeRolesForCompany = corporateInfo.officeRoles.map((role, idx) => ({ ...role, order: idx }));

                const officeRolesForAllUsers = officeRoles
                  .filter(
                    (value, index, self) =>
                      self.findIndex((t) => t.userId === value.userId && t.officeRoleId === value.officeRoleId) === index
                  ) // filter out duplicates for different office ids
                  .map((userOfficeRole) => {
                    const officeRole = officeRolesForCompany.find((role) => role.id === userOfficeRole.officeRoleId);
                    if (!officeRole) {
                      return null;
                    }

                    return {
                      officeRoleId: userOfficeRole.officeRoleId,
                      officeId: userOfficeRole.officeId,
                      userId: userOfficeRole.userId,
                      code: officeRole?.code || '',
                      name: officeRole?.name || '',
                      order: officeRole.order,
                    };
                  })
                  .filter((officeRole) => officeRole !== null) as IUserOfficeRoleDto[];

                const rolesByUser = new Map<string, IUserOfficeRoleDto[]>();
                officeRolesForAllUsers.forEach((userOfficeRole) => {
                  if (!rolesByUser.has(userOfficeRole.userId)) {
                    rolesByUser.set(userOfficeRole.userId, []);
                  }
                  rolesByUser.get(userOfficeRole.userId)?.push(userOfficeRole);
                });

                return rolesByUser;
              })
            )
      )
    );

    this.officeRolesByUserForAllOffices$ = this.featureFlagModel.featureEnableOfficeRoles$.pipe(
      switchMap((isEnabled) =>
        !isEnabled
          ? [null]
          : combineLatestForFrame([
              this.selectors.officeRoles$.pipe(distinctUntilChangedDiff()),
              this.authModel.corporateInfo$.pipe(filter(Boolean), distinctUntilChangedDiff()),
              this.globalModel.clientAppAccessibleOffices$.pipe(filter(Boolean), distinctUntilChangedDiff()),
            ]).pipe(
              map(([officeRoles, corporateInfo, offices]) => {
                if (!officeRoles) {
                  return null;
                }

                const officeRolesForCompany = corporateInfo.officeRoles.map((role, idx) => ({ ...role, order: idx }));

                const officeRolesForAllUsers = officeRoles
                  .map((userOfficeRole) => {
                    const officeRole = officeRolesForCompany.find((role) => role.id === userOfficeRole.officeRoleId);
                    if (!officeRole) {
                      return null;
                    }

                    return {
                      officeRoleId: userOfficeRole.officeRoleId,
                      officeId: userOfficeRole.officeId,
                      userId: userOfficeRole.userId,
                      code: officeRole?.code || '',
                      name: officeRole?.name || '',
                      order: officeRole.order,
                    };
                  })
                  .filter((officeRole) => officeRole !== null) as IUserOfficeRoleDto[];

                const rolesByUser = new Map<string, Map<OfficeRoleCode, string[]>>();
                officeRolesForAllUsers.forEach((userOfficeRole) => {
                  const roleOfficeId = userOfficeRole.officeId;
                  const roleOfficeName = offices.find((office) => office.id === roleOfficeId)?.name || '';

                  if (!rolesByUser.has(userOfficeRole.userId)) {
                    rolesByUser.set(userOfficeRole.userId, new Map<OfficeRoleCode, string[]>().set(userOfficeRole.code, [roleOfficeName]));
                  }

                  if (!rolesByUser.get(userOfficeRole.userId)?.has(userOfficeRole.code)) {
                    rolesByUser.get(userOfficeRole.userId)?.set(userOfficeRole.code, [roleOfficeName]);
                  }

                  if (!rolesByUser.get(userOfficeRole.userId)?.get(userOfficeRole.code)?.includes(roleOfficeName)) {
                    rolesByUser.get(userOfficeRole.userId)?.get(userOfficeRole.code)?.push(roleOfficeName);
                  }
                });

                return rolesByUser;
              })
            )
      )
    );
  }
}
