import moment from 'moment';
import { BookingInvalidTimeReason, IOfficeWorkingTimesDto, IOpeningHours } from 'types';
import { getPreviousCurrentAndNextWorkingDayHours } from './utils';
import { getBookingLeadTime } from 'shared';

export function isWithinLeadTime(startTime: number | null, timezone: string, maximumLeadTimeForBooking: number) {
  const startDayMoment =
    !startTime || moment(startTime).tz(timezone).diff(moment(), 'days') < 0
      ? moment().tz(timezone).startOf('day')
      : moment(startTime).tz(timezone).startOf('day');

  const maxLeadTimeMoment = getBookingLeadTime(timezone, maximumLeadTimeForBooking);

  return startDayMoment.isSameOrBefore(maxLeadTimeMoment);
}

export function officeIsOpenInTimestamp(
  startTime: number,
  endTime: number,
  workingTimes: IOfficeWorkingTimesDto
): { isOpen: boolean; invalidReason?: BookingInvalidTimeReason } {
  const { isOfficeOpenForGivenDay: givenTimeIsWorkDay, currentWorkingDayTimes } = workingTimes;

  if (!givenTimeIsWorkDay) {
    return { isOpen: false, invalidReason: BookingInvalidTimeReason.OFFICE_CLOSED };
  }

  const givenTimeIsBeforeOpeningHours = givenTimeIsWorkDay && startTime < currentWorkingDayTimes!.startTime;
  const givenTimeIsAfterClosingHours = givenTimeIsWorkDay && endTime > currentWorkingDayTimes!.endTime;

  if (givenTimeIsAfterClosingHours) {
    return { isOpen: false, invalidReason: BookingInvalidTimeReason.AFTER_CLOSING_TIME };
  }
  if (givenTimeIsBeforeOpeningHours) {
    return { isOpen: false, invalidReason: BookingInvalidTimeReason.BEFORE_OPENING_TIME };
  }
  return { isOpen: true };
}

export function isTimestampInPastDay(startTime: number | null, timezone: string, officeId: string, officeOpeningHours: IOpeningHours) {
  const startOfSelectedDay = moment(startTime).tz(timezone);

  const startDay = moment().tz(timezone);
  const startDayWorkingHours = getPreviousCurrentAndNextWorkingDayHours(startDay, officeOpeningHours, officeId, timezone);
  const startOfFirstOpenedDay = moment(startDayWorkingHours.currentWorkingDayTimes?.startTime).tz(timezone);

  return startOfSelectedDay.isBefore(startOfFirstOpenedDay);
}
