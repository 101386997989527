<p-avatar
  *ngIf="user.profileImage || user.avatarUrl as imageUrl; else initials"
  shape="circle"
  [image]="imageUrl"
>
</p-avatar>
<ng-template #initials>
  <p-avatar
    [label]="user.firstName + ' ' + user.lastName | exec : getAbbreviation"
    [style]="{
      background: user.avatarColor || user.color || '#A5A5A5',
      color: '#FFF'
    }"
    shape="circle"
  >
  </p-avatar>
</ng-template>
<div class="info-content">
  <div class="title">{{ user.firstName + ' ' + user.lastName }}</div>
  <div class="email">{{ user.email }}</div>
</div>
