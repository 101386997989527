import { Component, Input } from '@angular/core';
import { MessageModule } from 'primeng/message';

@Component({
  selector: 'db-info-message',
  templateUrl: './info-message.component.html',
  standalone: true,
  imports: [MessageModule],
})
export class InfoMessageComponent {
  @Input() text!: string;
  @Input() severity: 'success' | 'error' | 'warn' | 'info' = 'info';
  @Input() hideIcon = false;
}
