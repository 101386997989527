import { HttpErrorResponse } from '@angular/common/http';
import { props } from '@ngrx/store';
import { IOfficeEvent } from 'common-module';
import { forNamespace } from 'ngrx-action-bundles';
import { ApiErrorResponse, GridDatePeriodType, OfficeEventAttendanceResponseType } from 'types';

const factory = forNamespace('OFFICE-EVENTS');

export const loadOfficeEventsBundle = factory.asyncAction(
  'loadOfficeEvents',
  props<{ companyId: string; officeIds: string[]; fromDate?: string; untilDate?: string; gridDatePeriodType?: GridDatePeriodType }>(),
  props<{ events: IOfficeEvent[]; officeIds: string[]; gridDatePeriodType?: GridDatePeriodType }>(),
  props<{ error: ApiErrorResponse }>()
);

export const selectOfficeEventBundle = factory.asyncActionWithCleanup(
  'selectOfficeEvent',
  props<{ officeEventId: string }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: ApiErrorResponse }>()
);
export const reloadOfficeEventBundle = factory.asyncAction(
  'reloadOfficeEvent',
  props<{ officeEventId: string }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: ApiErrorResponse }>()
);
export const saveOfficeEventBundle = factory.asyncAction(
  'saveOfficeEvent',
  props<{ event: Partial<IOfficeEvent> }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: HttpErrorResponse }>()
);

export const deleteOfficeEventBundle = factory.asyncAction(
  'deleteOfficeEvent',
  props<{ event: IOfficeEvent }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: HttpErrorResponse }>()
);

export const attendOfficeEventBundle = factory.asyncAction(
  'attendOfficeEvent',
  props<{ event: IOfficeEvent; eventId: string; response: OfficeEventAttendanceResponseType; userId: string }>(),
  props<{ event: IOfficeEvent }>(),
  props<{ error: HttpErrorResponse }>()
);

export const activateInvitationInNonPrimaryOfficeEventsBundle = factory.asyncAction(
  'activateInvitationInNonPrimaryOfficeEvents',
  props<{ officeId: string; date: string }>()
);

export const deactivateInvitationInNonPrimaryOfficeEventsBundle = factory.asyncAction(
  'deactivateInvitationInNonPrimaryOfficeEvents',
  props<{ officeId: string; date: string }>()
);

export const officeEventsBundles = [
  loadOfficeEventsBundle,
  selectOfficeEventBundle,
  reloadOfficeEventBundle,
  saveOfficeEventBundle,
  deleteOfficeEventBundle,
  attendOfficeEventBundle,
  activateInvitationInNonPrimaryOfficeEventsBundle,
  deactivateInvitationInNonPrimaryOfficeEventsBundle,
];
