<p-panel
  class="clear-panel"
  [toggleable]="true"
  [collapsed]="true"
  (collapsedChange)="collapsedChange.emit($event)"
>
  <ng-template
    *ngFor="let template of templates"
    let-item
    [pTemplate]="template.name"
    [type]="template.type"
  >
    <ng-container
      *ngTemplateOutlet="template.template; context: { $implicit: item }"
    ></ng-container>
  </ng-template>

  <ng-content></ng-content>
</p-panel>
