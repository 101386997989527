<div
  class="menu-item small-text-bold"
  [ngClass]="{
    active:
      workspaceRouterLinkActive &&
      workspaceRouterLinkActive.isActive &&
      !disabled,
    disabled: disabled,
    loading: loading,
    webapp: style === 'webapp',
    admin: style === 'admin',
    'pointer-events': 'auto',
    'nav-with-border': style === 'nav-with-border',
    'nav-with-border-admin': style === 'nav-with-border-admin'
  }"
>
  <a
    [pTooltip]="tooltip"
    tooltipPosition="bottom"
    class="small-text"
    [routerLink]="link"
    [skipLocationChange]="skipLocationChange"
    [ngClass]="linkClassesWithBoldCheck"
    [routerLinkActiveOptions]="linkActiveOptions"
    [ngStyle]="{ display: isExternalLink ? 'none' : 'flex' }"
    [routerLinkActive]="link ? linkActive : ''"
    [queryParams]="queryParams"
    [queryParamsHandling]="queryParamsHandling"
    [attr.data-testid]="dataTestId"
    #workspaceRouterLinkActive="routerLinkActive"
  >
    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
  </a>
  <a
    *ngIf="isExternalLink"
    [href]="link"
    [ngClass]="linkClassesWithBoldCheck"
    target="blank"
  >
    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
  </a>

  <ng-template #linkContent>
    <img *ngIf="imgSrc && showIcon" [src]="imgSrc" alt="<image>"/>
    <div class="info">
      <span class="title">{{ title }} </span>
    </div>
  </ng-template>
</div>