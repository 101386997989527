import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { Steps, StepsModule } from "primeng/steps";
import { StepMenuItem } from "./typings";
import { ExecPipe } from "../../pipes";

type CompletedTemplateRef = TemplateRef<{
  $implicit: StepMenuItem;
  isCompleted: boolean;
}>;

@Component({
  selector: "db-steps",
  standalone: true,
  imports: [CommonModule, StepsModule, ExecPipe],
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent implements OnInit {
  @ContentChild(TemplateRef) completedTemplateRef:
    | CompletedTemplateRef
    | undefined;
  @ViewChild(Steps, { static: true }) stepsComponent!: Steps;

  @Input() activeIndex: number = 0;
  @Input() model: StepMenuItem[] | undefined;
  @Input() readonly: boolean = true;
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() styleClass: string | undefined;
  @Output() activeIndexChange: EventEmitter<number> =
    new EventEmitter<number>();

  ngOnInit(): void {
    this.stepsComponent.activeIndexChange.subscribe(this.activeIndexChange);
  }

  isStepCompleted = (step: StepMenuItem) => {
    return !!step.completed;
  };
}
