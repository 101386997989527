<div class="field w-100">
  <label [for]="inputId" class="d-block small-text-bold">
    {{ label }}
    <ng-container *ngIf="required">*</ng-container>
    <i *ngIf="optional" i18n="@@shared|optional-label">(optional)</i>
  </label>

  <p-calendar
    [inputId]="inputId"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [disabled]="disabled"
    [ngModel]="value"
    [showIcon]="true"
    [minDate]="$any(minDate)"
    [maxDate]="$any(maxDate)"
    [firstDayOfWeek]="1"
    [disabledDays]="disabledWeekdays | exec : disabledDays : disableWeekends"
    #input="ngModel"
    icon="d-calendar-icon"
    [ngClass]="{ 'icon-background-white': withIconBackgroundWhite }"
    appendTo="body"
    [attr.data-testId]="dataTestId"
    [style]="style"
    (ngModelChange)="valueChangedHandler($event)"
  >
    <ng-template pTemplate="date" let-date>
      <span [attr.data-testId]="date | exec : calendarDayToShortDateString">
        {{ date.day }}
      </span>
    </ng-template>
  </p-calendar>

  <ng-container *ngFor="let error of errorDictionaryList">
    <small
      *ngIf="
      (input.dirty || input.touched || (!!control?.touched)) && (input.errors?.[error.name] || control?.getError(error.name))"
      class="p-error d-block"
    >
      {{ error.getLabel(input.errors?.[error.name] || control?.getError(error.name)) }}
    </small>
  </ng-container>
</div>
