export const FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'ddd DD MMM',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM',
  },
};

export const FORMAT_WITH_YEAR = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'ddd DD MMM YYYY',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM',
  },
};

/**Default date format used for parsing moment. Same as moment.HTML5_FMT.DATE (https://momentjs.com/docs/#/parsing/special-formats/) */
export const DATE_FORMAT = 'YYYY-MM-DD';
/**Default format used (and supported https://primeng.org/calendar#format) by prime. Same as DATE_FORMAT */
export const PRIME_DEFAULT_DATE_FORMAT = 'yy-mm-dd';
export const DATE_SHORT_YEAR_FORMAT = 'Y-MM-DD';
export const DATE_FORMAT_SLASH = 'YYYY/MM/DD';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const DAY_OF_WEEK_FORMAT = 'ddd';
export const FULLDAY_OF_WEEK_FORMAT = 'dddd';
export const DAY_AND_MONTH_FORMAT = 'ddd D MMM';
export const TIME_FORMAT_H24 = 'HH:mm';
export const TIME_FORMAT_H12 = 'hh:mm A';
export const GTM_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:MM:SS';
export const MONTH_DAY = 'MMM DD';
export const MONTH_DAY_YEAR = 'MMM DD ‘YY';
export const DAY_MONTH_YEAR = 'DD MMMM YYYY';
export const ISO_8601_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const DEFAULT_TIMEZONE = 'Etc/UTC';

export const MAX_DATE = '3000-01-01';

export const HUMAN_DATE_FORMAT: string = 'YYYY-MM-DD[T]HH:mm:ssZZ';
