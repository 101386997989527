import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { getTimesList } from 'shared-utils';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DropdownComponent } from '../dropdown';

@Component({
  selector: 'db-time-picker-control',
  standalone: true,
  imports: [CommonModule, DropdownComponent, FormsModule],
  templateUrl: './time-picker-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimePickerControlComponent,
      multi: true,
    },
  ],
})
export class TimePickerControlComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {
  @ViewChild(DropdownComponent, { read: DropdownComponent, static: true }) dropdownComponent!: DropdownComponent;
  @Input() is24hoursPossible = true;
  @Input() editable = true;
  @Input() required = true;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() timeFormat: 24 | 12 = 24;
  @Input() minutes: 15 | 30 | undefined = undefined;
  @Input() includeOptionFor2359 = false;
  /** List of values that will be available but disabled options. Format HH:mm. */
  @Input() disabledTimeValues: string[] = [];
  /** List of values that will be excluded from the available options. Format HH:mm. */
  @Input() filterTimeValues: string[] = [];
  /** Defines styling of input - default dropdown look or as an input field with bluish background and no arrow */
  @Input() outlineStyleType: 'dropdown' | 'dropdown-no-border' | 'input' = 'dropdown';
  @Input() dataTestId?: string;

  @Output() valueChange = new EventEmitter<string>();

  /** Selected value in format HH:mm */
  value = '';
  touched = false;

  options = getTimesList();
  disabled = false;
  private onChangeHandler!: (value: string) => {};
  private onTouchedHandler!: () => {};
  private onValidationChangeHandler = () => {};

  private changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.setHours();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['disabledTimeValues'] && !changes['disabledTimeValues'].firstChange) ||
      (changes['filterTimeValues'] && !changes['filterTimeValues'].firstChange)
    ) {
      this.setHours();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.value ? { invalid: true } : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChangeHandler = fn;
  }

  registerOnChange(fn: () => {}): void {
    this.onChangeHandler = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouchedHandler = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  writeValue(value: string): void {
    this.value = value;
    this.changeDetectorRef.detectChanges();
  }

  valueChangedHandler(event: any): void {
    this.value = event.value;
    this.onChangeHandler(event.value);
    if (!this.touched) {
      this.touched = true;
      this.onTouchedHandler();
    }
    this.onValidationChangeHandler();
    this.valueChange.emit(this.value);
  }

  private setHours(): void {
    if (this.is24hoursPossible) {
      this.options = [
        {
          value: '24 hours',
          title: $localize`:@@shared|time-picker-control:24 hours`,
          disabled: false,
        },
        ...getTimesList(this.timeFormat, this.minutes, this.disabledTimeValues, this.includeOptionFor2359).filter(
          (option) => !this.filterTimeValues.includes(option.value)
        ),
      ];
    } else {
      this.options = getTimesList(this.timeFormat, this.minutes, this.disabledTimeValues, this.includeOptionFor2359).filter(
        (option) => !this.filterTimeValues.includes(option.value)
      );
    }

    this.dropdownComponent.writeValue(this.value);
    this.changeDetectorRef.detectChanges();
  }
}
