<ng-container *ngIf="status">
  <ng-container
    *ngIf="!status.isNameUserDefined; else userSetName"
    [ngSwitch]="status.type"
  >
    <span *ngSwitchCase="optionType.Office" i18n="@@common|office">Office</span>
    <span *ngSwitchCase="optionType.OtherOffice" i18n="@@common|office"
      >Office</span
    >
    <span
      *ngSwitchCase="optionType.HomeOffice"
      i18n="@@planning-module|home-office"
      >Home office</span
    >
    <span
      *ngSwitchCase="optionType.Travel"
      i18n="@@planning-module|business-travel"
      >Business travel</span
    >
    <span *ngSwitchCase="optionType.Absent" i18n="@@planning-module|absent"
      >Absent</span
    >
    <span
      *ngSwitchCase="optionType.MobileWork"
      i18n="@@planning-module|mobile-work"
      >Mobile work</span
    >
    <span *ngSwitchDefault>{{ status.type | titlecase }}</span>
  </ng-container>

  <ng-template #userSetName>
    <span>{{ status.name }}</span>
  </ng-template>
</ng-container>
