import { GoogleTagManagerConfig } from "angular-google-tag-manager";

export function googleTagManagerProvidersFactory(
  config: GoogleTagManagerConfig
) {
  return [
    { provide: 'googleTagManagerId', useValue: config.id },
    { provide: 'googleTagManagerAuth', useValue: config.gtm_auth },
    { provide: 'googleTagManagerPreview', useValue: config.gtm_preview },
  ];
}