export function isElementInViewport(element: HTMLElement, container: HTMLElement, additionalContainerOffsets: { top: number; left: number; bottom: number; right: number; } = { top: 0, bottom: 0, right: 0, left: 0 }) {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const intersection = {
    top: Math.max(elementRect.top, containerRect.top + additionalContainerOffsets.top),
    left: Math.max(elementRect.left, containerRect.left + additionalContainerOffsets.left),
    bottom: Math.min(elementRect.bottom, containerRect.bottom + additionalContainerOffsets.bottom),
    right: Math.min(elementRect.right, containerRect.right + additionalContainerOffsets.right)
  };

  const isIntersecting = intersection.top < intersection.bottom && intersection.left < intersection.right;
  const elementArea = elementRect.width * elementRect.height;
  const intersectionArea = (isIntersecting ? (intersection.bottom - intersection.top) * (intersection.right - intersection.left) : 0);


  const visibilityRatio = intersectionArea / elementArea;
  const visibilityLevel = Math.ceil(visibilityRatio * 5);

  // Visibility level:
  // 
  // 5 - element is fully visible
  // 4 - 3 - 2 - 1 - element is partially visible
  // 0 - element is not visible

  return {
    isIntersecting: isIntersecting && visibilityLevel !== 0,
    isFullyVisible: visibilityLevel === 5,
    isPartiallyVisible: visibilityLevel > 0 && visibilityLevel < 5,
    visibilityLevel: visibilityLevel
  };
}
