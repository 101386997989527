import { ICorporateInfo } from '../interfaces';
import { IUser } from 'types';

export function getClientDefaultLoggedNavigationUrl(corporateInfo: ICorporateInfo | null): string {
  return corporateInfo?.allowsScheduling ? '/planning' : corporateInfo?.allowsResourceBooking ? '/office' : '/no-access';
}

export function getAdminDefaultLoggedNavigationUrl(corporateInfo: ICorporateInfo | null, user: IUser): string {
  return '/analytics';
}

export function getDefaultNotLoggedNavigationUrl(corporateInfo: ICorporateInfo | null): string {
  return '/login';
}
