import { UserRole, IUser } from 'types';

export const isUserGlobalAdmin = (user: IUser | null) => user && user.role === UserRole.ADMIN;
export const isUserOfficeAdmin = (user: IUser | null) => user && user.role === UserRole.OFFICE_ADMIN;
export const isUserGroupManager = (user: IUser | null) => user && user.role === UserRole.GROUP_MANAGER;
export const isUserGeneralManager = (user: IUser | null) => user && user.role === UserRole.MANAGER;

export const isUserOfficeOrGeneralManager =
  (user: IUser | null) => user && (isUserGeneralManager(user) || isUserGroupManager(user));
export const isUserGlobalOrOfficeAdmin =
  (user: IUser | null) => user && (isUserGlobalAdmin(user) || isUserOfficeAdmin(user));