<div class="popup-header" [ngClass]="{ 'center-aligned': centerAlignedHeader }">
  <ng-content select="[popupContentHeader]"></ng-content>
  <db-button-icon
    *ngIf="!hideCloseButton"
    icon="pi-times"
    class="close-button"
    (click)="closePopupHandler()"
  >
  </db-button-icon>
</div>

<ng-content select="[popupContentBody]"></ng-content>
<div class="popup-footer" *ngIf="!hideFooter">
  <ng-content select="[popupContentFooter]"></ng-content>
</div>
