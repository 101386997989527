import { Observable } from "rxjs";
import { SkeletonLoaderType } from "types";

interface TableColumnFiltrationByString {
  type: 'STRING';
}

interface TableColumnFiltrationByDate {
  type: 'DATE';
}

interface TableColumnFiltrationByAsyncList {
  type: 'ASYNC_LIST';
  optionList$: Observable<{ value: string; title: string, extra?: unknown }[]>;
  filterBy?: string;
}

type TableColumnFiltration = TableColumnFiltrationByString | TableColumnFiltrationByDate | TableColumnFiltrationByAsyncList;

export interface TableColumn<T> {
  field: T | 'actions';
  header: string;
  dataTestId?: string;
  sortable?: boolean;
  skeletonType?: SkeletonLoaderType;
  width?: number;
  filtrationHiddenFn$?: Observable<boolean>;
  filtration?: TableColumnFiltration;
}

export type TableColumnList<T> = TableColumn<T>[];