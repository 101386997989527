import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { RouterModel } from './model';

@Injectable({
  providedIn: 'root'
})
export class RouterEffects {

  navigate$ = createEffect(() => this.routerModel.actions.listen.navigate$.pipe(
    map(action => action),
    tap(({ commands, extras }) => this.router.navigate(commands, extras))
  ), { dispatch: false });

  navigateByUrl$ = createEffect(() => this.routerModel.actions.listen.navigateByUrl$.pipe(
    map(action => action),
    tap(({ url, extras }) => this.router.navigateByUrl(url, extras))
  ), { dispatch: false });

  navigateForward$ = createEffect(() => this.routerModel.actions.listen.forward$.pipe(
    tap(() => this.location.forward())
  ), { dispatch: false });

  navigateBack$ = createEffect(() => this.routerModel.actions.listen.back$.pipe(
    tap(() => this.location.back())
  ), { dispatch: false });

  constructor(
    private routerModel: RouterModel,
    private router: Router,
    private location: Location
  ) { }
}
