import { UserRole } from 'types';

interface ILoginMethods {
  email: { enabled: boolean },
  google: { enabled: boolean },
  microsoft: { enabled: boolean },
  saml: {
    enabled: boolean,
    providerId: string
  }
}

export interface IEmailCheck {
  companyExists: boolean;
  companyLogoUrl: string;
  companyName: string;
  inviteOnlyAccess: boolean;
  loginMethods: Partial<ILoginMethods>;
  userExists: boolean;
  userInvited: boolean;
  isVerified: boolean;
  userRole: UserRole;
  extraInformation?: string;
  email?: string;
}