import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { IUser, IUserViewAvatarProfileOpeningProps, IUserViewAvatar } from 'types';
import { UserAvatarComponent } from '../user-avatar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'db-user-image-list',
  templateUrl: './user-image-list.component.html',
  styleUrls: ['./user-image-list.component.scss'],
  standalone: true,
  imports: [CommonModule, UserAvatarComponent],
})
export class UserImageListComponent {
  @Input() authUser!: IUser;
  @Input() users!: IUserViewAvatar[];
  @Input() countBeforeHide = 5;
  @Input() additionalUsersCount = 0;
  @Input() avatarSpacingStyle: 'overlap' | 'space' = 'overlap';
  @Input() itemBadgeTemplateRef: TemplateRef<any> | undefined;
  @Input() openProfileOnClick = false;

  @Output() profileOpening: EventEmitter<{ event: Event; userInfo: IUserViewAvatarProfileOpeningProps }> = new EventEmitter();

  get visibleUsers(): IUserViewAvatar[] {
    return this.users.slice(0, this.countBeforeHide);
  }
  get hiddenUserCount(): number {
    return Math.max(this.users.length - this.countBeforeHide, 0) + this.additionalUsersCount;
  }

  trackByFn = (_: number, item: IUserViewAvatar) => item.id;

  openProfileHandler(data: { event: Event; userInfo: IUserViewAvatarProfileOpeningProps }) {
    this.profileOpening.emit(data);
  }
}
