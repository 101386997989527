import { Injectable, inject } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { ApplicationModule } from 'common-module';
import { RouterModel } from 'router-module';
import { map, Observable, take, zip } from 'rxjs';
import { combineLatestForFrame, hasAccessToPageFactory } from 'shared';
import { UserRole } from 'types';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeLoad implements CanLoad {
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);
  router = inject(Router);

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    const { data } = route;

    const deskbirdAdminOnly = !!data?.['deskbirdAdminOnly'];
    const userRolesRestrictions: UserRole[] | null = (data?.['userRoles'] as UserRole[]) || null;
    const appModuleRestrictions: ApplicationModule | null = (data?.['appModuleRestrictions'] as ApplicationModule) || null;

    return combineLatestForFrame(
      this.authModel.user$,
      this.authModel.selectors.corporateInfo$,
      this.authModel.selectors.isDeskbirdAdmin$,
      this.routerModel.isAdminAppEnv$,
      this.authModel.userRoleCompanyPageRestrictions$
    ).pipe(
      map(([user, corporateInfo, isDeskbirdAdmin, isAdminAppEnv, userRoleCompanyPageRestrictions]) => {
        if (!user || !corporateInfo) return false;
        if (deskbirdAdminOnly) return isDeskbirdAdmin;
        if (isDeskbirdAdmin) return true;

        const userRoleCompanyPageRestrictionsForEnv =
          (isAdminAppEnv ? userRoleCompanyPageRestrictions.admin : userRoleCompanyPageRestrictions.client) || null;

        const hasUserRoleCompanyPageRestriction = !(userRoleCompanyPageRestrictionsForEnv
          ? hasAccessToPageFactory(userRoleCompanyPageRestrictionsForEnv)(segments.map((s) => s.path).join('/'))
          : true);

        let canContinue = false;
        if (!appModuleRestrictions && !userRolesRestrictions && !hasUserRoleCompanyPageRestriction) return true;

        if (!hasUserRoleCompanyPageRestriction && appModuleRestrictions && corporateInfo[appModuleRestrictions]) {
          canContinue = true;
        }
        if (!hasUserRoleCompanyPageRestriction && userRolesRestrictions && userRolesRestrictions.includes(user.role)) {
          canContinue = true;
        }

        return canContinue;
      }),
      take(1)
    );
  }
}
