import { Directive, ElementRef, HostBinding, inject, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[dbHidden]',
  standalone: true,
})
export class HiddenDirective implements OnChanges {
  private readonly elementRef = inject(ElementRef);
  @Input() dbHidden = false;
  private _hidden = false;

  @Input() absolutePosition = false;
  @Input() absoluteWhenHidden = false;
  @Input() delayVisible: number | null = null;

  @HostBinding('style.opacity') opacity = 1;

  @HostBinding('style.visibility') get visibility() {
    return this._hidden ? 'hidden' : 'visible';
  }

  @HostBinding('style.position') get position() {
    return this.absolutePosition || (this.absoluteWhenHidden && this._hidden) ? 'absolute' : this.initialPositionValue;
  }

  @Input() initialPositionValue: string | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialPositionValue === null && !this.absolutePosition) {
      this.initialPositionValue = this.getElementPosition();
    }
    if (changes['dbHidden']) {
      if (this.delayVisible && !this.dbHidden && this._hidden) {
        setTimeout(() => {
          this._hidden = this.dbHidden;
          this.setOpacity();
        }, this.delayVisible);
      } else {
        this._hidden = this.dbHidden;
        this.setOpacity();
      }
    }
  }

  setOpacity() {
    this.opacity = this._hidden ? 0 : 1;
  }

  private getElementPosition(): string {
    return (this.elementRef.nativeElement as HTMLElement).style.position;
  }
}
