import { createReducer, on } from '@ngrx/store';
import { DEFAULT_LOCALE } from '../constants';
import { loadLocaleBundle } from './bundles';

export interface IInternalizationState {
  locale: string;
}

export const initialState: IInternalizationState = {
  locale: DEFAULT_LOCALE,
};

export const internalizationReducer = createReducer(
  initialState,
  on(loadLocaleBundle.loadLocale, (state, { locale }) => {
    return { ...state, locale };
  })
);
