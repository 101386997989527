import { ErrorDictionaryEntry } from 'types/interfaces';

export const standardErrorDictionary: ErrorDictionaryEntry[] = [
  {
    name: 'required',
    getLabel: () => $localize`:@@shared|field-required-error:This field is required.`,
  },
  {
    name: 'maxlength',
    getLabel: (error) =>
      $localize`:@@shared|max-length-required-error:The maximum length of this field is ${error?.['requiredLength']} characters.`,
  },
  {
    name: 'minlength',
    getLabel: (error) =>
      $localize`:@@shared|min-length-required-error:The minimum length of this field is ${error?.['requiredLength']} characters.`,
  },
  {
    name: 'min',
    getLabel: (error) => $localize`:@@shared|min-required-error:The minimum value of this field is ${error?.['min']}.`,
  },
];
