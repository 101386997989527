import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Range } from '../../types';
import { CommonModule } from '@angular/common';
import { ProgressComponent } from '../progress/progress.component';
import { ButtonComponent, ButtonIconComponent } from 'db-ui';

function generateButtonStyles(listener: EventEmitter<any>, width: string, height: string, color: string) {
  return {
    backgroundColor: color,
    border: color,
    width: width,
    height: height,
    pointerEvents: listener.observed ? 'all' : 'none',
  };
}

export enum FileProgressStatus {
  Uploading = 'uploading',
  UploadSuccess = 'uploadSuccess',
  UploadFailure = 'uploadFailed',
}

@Component({
  selector: 'db-file-progress',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ButtonIconComponent, ProgressComponent],
  templateUrl: './file-progress.component.html',
  styleUrls: ['./file-progress.component.scss'],
})
export class FileProgressComponent {
  readonly buttonsSize = '27px';
  readonly successButtonColor = '#D3EEEC';
  readonly errorButtonColor = '#FAE0E0';
  readonly retryButtonColor = '#FFF';

  @Input() type: 'csv' | undefined;
  @Input() fileName: string | null = null;
  @Input() fileSize: string | number | null = null;
  @Input() progress: Range<0, 101> = 0;
  @Input() status = FileProgressStatus.Uploading;

  @Output() retry = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() proceed = new EventEmitter<void>();

  readonly successButtonStyles = generateButtonStyles(this.proceed, this.buttonsSize, this.buttonsSize, this.successButtonColor);
  readonly retryButtonStyles = {
    ...generateButtonStyles(this.retry, this.buttonsSize, this.buttonsSize, this.retryButtonColor),
    color: '#565656',
  };
  readonly errorButtonStyles = {
    ...generateButtonStyles(this.cancel, this.buttonsSize, this.buttonsSize, this.errorButtonColor),
    color: '#FF5455',
  };

  FileProgressStatus = FileProgressStatus;

  retryClickHandler() {
    this.retry.emit();
  }

  cancelClickHandler() {
    this.cancel.emit();
  }

  proceedClickHandler() {
    this.proceed.emit();
  }
}
