<div
  id="container"
  [ngClass]="{
    'no-styles': noContainerStyles,
    'ui-modal-with-close-button': showCloseButton,
  }"
  [style.width]="width"
  [style.height]="height"
>
  @if (showCloseButton) {
    <div class="button-close-container">
      <db-button-icon
        icon="pi pi-times"
        size="small"
        [attr.data-testid]="dataTestIdCloseButton"
        (click)="closeDialog.emit()"
      >
      </db-button-icon>
    </div>
  }
  <ng-content></ng-content>
</div>
