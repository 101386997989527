import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GtmEvent } from 'shared-types';
import { areaHasRestrictedAccess, areaIsTimeRestricted } from 'shared-utils';
import { ExecPipe } from 'shared-pipes';
import { GtmEventDirective, DebounceClickDirective } from 'shared-directives';
import { ClickFavoriteSpaceEntryPoint } from 'shared-enums';
import { FavoriteSpacesModel } from '../+store/model';
import {
  DeskAreaType,
  IUserFavoriteResource,
  IUser,
  DeskAreaItemAccessType,
  IDeskAreaItem,
  IDeskAreaForBookingSummary,
  IDeskArea,
  IDeskAreaItemBasicInfo,
} from 'types';
import { ProgressSpinnerComponent } from 'db-ui';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'db-favorite-space-action-icon',
  templateUrl: './favorite-space-action-icon.component.html',
  styleUrls: ['./favorite-space-action-icon.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressSpinnerComponent, ExecPipe, GtmEventDirective, DebounceClickDirective, TooltipModule],
})
export class FavoriteSpaceActionIconComponent implements OnChanges {
  private favoriteSpacesModel = inject(FavoriteSpacesModel);

  @Input() authUser!: Pick<IUser, 'favoriteResources' | 'id'>;
  @Input() resourceItem!: IDeskAreaItemBasicInfo;
  @Input() deskArea?: Pick<IDeskArea | IDeskAreaForBookingSummary, 'accessRules'>; // provide desk area if you want to check if the user has access to it
  @Input() @HostBinding('class.mid-text-icon') midTextIcon = false;
  @Input() alwaysVisible = false;
  @Input() gtmEntryPoint!: ClickFavoriteSpaceEntryPoint;
  @Input() dataTestIdBase?: string;

  readonly dataTestIdBaseDefault = 'favorite-space-';
  readonly addSpaceTooltipMessage = $localize`:@@favorite-spaces-module|favorite-space-action-icon|add-space-tooltip:Add to your favorites`;
  readonly removeSpaceTooltipMessage = $localize`:@@favorite-spaces-module|favorite-space-action-icon|remove-space-tooltip:Remove from your favorites`;
  readonly loadingResourceIds$ = this.favoriteSpacesModel.selectors.loadingResourceIds$;
  isResourceAvailableForAdding = true;

  isFavoriteResource = (favoriteResources: IUserFavoriteResource[] | null) => {
    const isDDR = favoriteResources?.some((space) => space.id === this.resourceItem.id);
    return isDDR;
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deskArea'] || changes['resourceItem']) {
      this.isResourceAvailableForAdding = checkIsResourceAvailableForAdding(
        this.resourceItem as IDeskAreaItem,
        this.deskArea,
        this.authUser.id
      );
    }
  }

  arrayIncludes = (arr: number[] | null, value: number): boolean => {
    const result = arr ? arr.includes(value) : false;
    return result;
  };

  iconClickHandler(event: Event, isFavoriteSpace: boolean) {
    event.preventDefault();
    event.stopPropagation();
    const { id, name } = this.resourceItem;

    if (!isFavoriteSpace) {
      this.favoriteSpacesModel.actions.dispatch.addFavoriteSpace({ resource: { id, name } });
    } else {
      this.favoriteSpacesModel.actions.dispatch.removeFavoriteSpace({ resource: { id, name } });
    }
  }

  getGtmProps = (resourceItem: IDeskAreaItemBasicInfo, isFavoriteSpace: boolean): GtmEvent['click_favorite_space_button'] | {} => {
    if (!resourceItem || !this.gtmEntryPoint) return {};

    return {
      entry_point: this.gtmEntryPoint,
      entity_id: resourceItem.id.toString(),
      favorite_status: isFavoriteSpace ? 'unfavorited' : 'favorited',
      entity_type: resourceItem.resourceType || DeskAreaType.Other,
    };
  };
}

//** Check if adding the space is allowed:
// - Resource is active
// - It is not assigned to any user
// - User has access to the area when it has restricted access
// - User has access to book whenever they want when the area has restricted access */
function checkIsResourceAvailableForAdding(
  resourceItem: IDeskAreaItem,
  deskArea: Pick<IDeskArea | IDeskAreaForBookingSummary, 'accessRules'> | undefined,
  authUserId: string
) {
  if (
    resourceItem.status === 'inactive' ||
    (resourceItem.dedicatedResourceOwner && resourceItem.dedicatedResourceOwner.id !== authUserId)
  ) {
    return false;
  }
  const isAssignedSpace = resourceItem.accessInfo?.type === DeskAreaItemAccessType.DEDICATED;

  let userHasAccess;
  if (!deskArea || !deskArea.accessRules) {
    userHasAccess = true;
  } else if (areaHasRestrictedAccess(deskArea.accessRules)) {
    userHasAccess = deskArea.accessRules?.userHasAccess;
  } else if (areaIsTimeRestricted(deskArea.accessRules)) {
    userHasAccess = deskArea.accessRules?.isBookable && deskArea.accessRules?.userHasAccess;
  } else {
    userHasAccess = true;
  }

  const canAdd = !isAssignedSpace && userHasAccess;
  return canAdd;
}
