import { CsvProcessStatus } from '../enums';
import { IBaseResponse } from './base-response';

export interface IUserCsvProcessState {
  addedUsers: number;
  updatedUsers: number;
  deletedUsers: number;
  failedUsers: number;
  importCSVStatus: CsvProcessStatus;
}

export interface IUserCSVStatusResult {
  fileName: string;
  filePath: string;
  fileSize: number;
  bucketName: string;
  createdAt: string;
  results: IUserCsvProcessState;
}

export interface UserCSVStatusResponse extends IBaseResponse<IUserCSVStatusResult> {}
