import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'outletNavigation'
})
export class OutletNavigationPipe implements PipeTransform {

  transform(outletName: any, commands: any[] = []): any[] {
    return ['', { outlets: { [outletName]: commands } }];
  }

}
