<db-dropdown-with-search
  *ngIf="!isLoading; else skeletonLoader"
  [virtualScrollItemSize]="56"
  [options]="userOptions"
  [placeholder]="placeholder || ''"
  [optionValue]="'id'"
  [filterBy]="'firstName,lastName,email,fullName'"
  [selectedOption]="selectedUserId"
  [dataTestId]="dataTestId"
  [emptyFilterMessage]="emptyFilterMessage"
  [isDisabled]="isDisabled"
  [styleClass]="styleClass"
  [showClear]="showClear"
  [panelStyleClass]="panelStyleClass"
  (selectedValueChanged)="selectedUserIdChangeHandler($event)"
>
  <ng-template pTemplate="selectedItem" let-option>
    <div
      [ngClass]="{ small: smallSelectedValue }"
      *ngIf="!hideSelectedValue; else hiddenSelectedValue"
    >
      <db-user-info
        [userInfo]="option"
        [showUserEmail]="showUserEmail"
        [isUserEmailOnSameLine]="true"
        [avatarSize]="selectedUserAvatarSize"
        [ignoreEmailMaxWidth]="true"
      >
      </db-user-info>
    </div>
  </ng-template>

  <ng-template pTemplate="item" let-itemOption>
    <db-user-info
      [userInfo]="itemOption"
      [showUserEmail]="showUserEmail"
      avatarSize="mini"
      [ignoreEmailMaxWidth]="true"
    >
    </db-user-info>
  </ng-template>
</db-dropdown-with-search>

<ng-template #skeletonLoader>
  <db-skeleton-loader skeletonHeight="54px"></db-skeleton-loader>
</ng-template>

<ng-template #hiddenSelectedValue>
  <div>{{ placeholder || defaultPlaceholder }}</div>
</ng-template>
