import { IUserInfo } from 'types';
import { IUserMultiselectEntity } from 'shared-types';
import { UserMultiselectType } from 'shared-enums';

export const mapUsersToMultiselectEntities = (users: IUserInfo[]): IUserMultiselectEntity[] => {
  return users.map((user) => ({
    label: user.firstName + ' ' + user.lastName,
    value: `${UserMultiselectType.USER}-${user.id}`,
    type: UserMultiselectType.USER,
    userEntity: { ...user },
  }));
};
