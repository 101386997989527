import { Injectable, inject } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { getClientDefaultLoggedNavigationUrl, getAdminDefaultLoggedNavigationUrl, ICorporateInfo } from 'common-module';
import { AuthModel } from 'auth-module';
import { RouterModel } from 'router-module';
import { combineLatest, filter, map, Observable, take } from 'rxjs';
import { IUser } from 'types';

@Injectable({
  providedIn: 'root',
})
export class DefaultComponentActivate implements CanActivate {
  private routerModel = inject(RouterModel);
  private authModel = inject(AuthModel);
  private router = inject(Router);

  canActivate(): Observable<UrlTree> {
    return combineLatest([
      this.routerModel.isAdminAppEnv$,
      this.authModel.corporateInfo$.pipe(filter((val): val is ICorporateInfo => !!val)),
      this.authModel.selectors.user$.pipe(filter((user): user is IUser => !!user)),
      this.authModel.isRequiredToVisitSetupWizard$,
      this.authModel.isUserEmailVerified$,
    ]).pipe(
      map(([isAdminAppEnv, corporateInfo, user, isRequiredToVisitSetupWizard, isUserEmailVerified]) => {
        if (!isUserEmailVerified) return this.router.parseUrl('/login/verify-email');
        if (isRequiredToVisitSetupWizard) return this.router.parseUrl('/login/sign-up-wizard');
        const url = isAdminAppEnv
          ? getAdminDefaultLoggedNavigationUrl(corporateInfo, user)
          : getClientDefaultLoggedNavigationUrl(corporateInfo);
        return this.router.parseUrl(url);
      }),
      take(1)
    );
  }
}
