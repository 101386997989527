import { createReducer, on } from '@ngrx/store';
import { createOfficeEquipmentItem, loadOfficeEquipment, removeOfficeEquipmentItem } from './bundles';
import { IOfficeEquipmentItem } from 'types';

export interface IOfficeEquipmentState {
  officeEquipment: IOfficeEquipmentItem[] | null;
}
export const initialState: IOfficeEquipmentState = {
  officeEquipment: null,
};

export const officeEquipmentReducer = createReducer(
  initialState,

  on(loadOfficeEquipment.loadOfficeEquipment, (state) => {
    return { ...state, officeEquipment: null };
  }),
  on(loadOfficeEquipment.loadOfficeEquipmentSuccess, (state, { equipment: officeEquipment }) => {
    return { ...state, officeEquipment };
  }),
  on(createOfficeEquipmentItem.createOfficeEquipmentItemSuccess, (state, { item }) => {
    if (!state.officeEquipment) {
      return state;
    }

    return { ...state, officeEquipment: [...state.officeEquipment, item] };
  }),
  on(removeOfficeEquipmentItem.removeOfficeEquipmentItemSuccess, (state, { id }) => {
    if (!state.officeEquipment) {
      return state;
    }

    return {
      ...state,
      officeEquipment: state.officeEquipment.filter((item) => item.id !== id),
    };
  })
);
