import { Directive, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { fromEvent, debounceTime, Subscription } from 'rxjs';
import { safeRequestAnimationFrame } from 'shared';

@Directive({
  selector: '[dbFullVw]',
  standalone: true
})
export class FullVwDirective implements OnDestroy {

  sub = new Subscription();

  @HostBinding('style.width') width = '100vw';
  @Input() subtractPx: number | string = 0;

  nativeElememnt: HTMLElement;

  updateScheduled = false;

  constructor(private elementRef: ElementRef) {
    this.nativeElememnt = this.elementRef.nativeElement;

    this.sub.add(
      fromEvent(window, "resize")
        .pipe(debounceTime(500))
        .subscribe(() => this.setWidth())
    );

    this.setWidth();
  }

  private setWidth() {
    if (!this.nativeElememnt || this.updateScheduled) { return; }
    this.updateScheduled = true;
    safeRequestAnimationFrame(() => {
      this.updateScheduled = false;
      const boundingClientRect = this.nativeElememnt.getBoundingClientRect();
      this.width = `calc(100vw - ${boundingClientRect.left + +this.subtractPx}px)`;
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
