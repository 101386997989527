<db-navigation [navigationMarginTop]="(freeTrialEndDate$ | async) ? 36 : 0">
  <db-auth [showContent]="!!(showContent$ | async)">
    <ng-template #lazyModule></ng-template>
    <ng-template #lazyModule></ng-template>
    <ng-template #lazyModule></ng-template>
  </db-auth>
</db-navigation>
<db-global-loader></db-global-loader>

<db-mobile-blocker></db-mobile-blocker>

<db-confirmation></db-confirmation>
<db-notification></db-notification>
<ng-template #freeTrialPeriod>
  <ng-container
    i18n="@@deskbird-app|free-trial-expiry-template|free-trial-expiry-banner"
  >
    Your trial ends {{ freeTrialEndDate$ | async | exec: fromNow }}. If you have
    any questions, please contact us at
    <a class="support-link" href="mailto:hello@deskbird.com"
      >hello&#64;deskbird.com</a
    >.
  </ng-container>
</ng-template>
