import { Injectable } from '@angular/core';
import { officeEventsBundles } from './bundles';
import { officeEventsSelectors } from './selectors';
import { connectBundles, connectSelectors } from 'ngrx-action-bundles';

@Injectable({ providedIn: 'root' })
export class OfficeEventsModel {
  selectors = connectSelectors(officeEventsSelectors);
  actions = connectBundles(officeEventsBundles);
}
