import { HttpErrorResponse } from '@angular/common/http';
import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store';
import { IOfficeEquipmentItem } from 'types/interfaces';
import { DeskAreaType } from 'types';

const factory = forNamespace('OFFICE-EQUIPMENT');

export const loadOfficeEquipment = factory.asyncAction(
  'loadOfficeEquipment',
  props<{ officeId: string }>(),
  props<{ equipment: IOfficeEquipmentItem[] }>(),
  props<{ error: HttpErrorResponse }>()
);

export const createOfficeEquipmentItem = factory.asyncAction(
  'createOfficeEquipmentItem',
  props<{ officeId: string; item: IOfficeEquipmentItem }>(),
  props<{ item: IOfficeEquipmentItem }>(),
  props<{ error: HttpErrorResponse }>()
);

export const removeOfficeEquipmentItem = factory.asyncAction(
  'removeOfficeEquipmentItem',
  props<{ officeId: string; id: string, resourceType : DeskAreaType }>(),
  props<{ id: string, resourceType : DeskAreaType }>(),
  props<{ error: HttpErrorResponse }>()
);

export const officeBundles = [
  loadOfficeEquipment,
  createOfficeEquipmentItem,
  removeOfficeEquipmentItem,
];
