export interface IUserDedicatedResource {
  allowOthersToBook: boolean;
  autoBook: boolean;
  floorConfigReady: boolean;
  floorId: string;
  floorName: string;
  officeId: string;
  officeName: string;
  zoneId: string;
  zoneItemId: number;
  zoneItemName: string;
  zoneName: string;
}