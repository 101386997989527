<p-table
  [attr.data-testId]="dataTestId"
  [columns]="columns"
  [value]="values"
  [paginator]="paginator"
  [globalFilterFields]="globalFilterFields"
  [filters]="filters"
  [currentPageReportTemplate]="currentPageReportLocalized"
  [showCurrentPageReport]="showCurrentPageReport"
  [alwaysShowPaginator]="alwaysShowPaginator"
  [rows]="rows"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [totalRecords]="totalRecords"
  [lazy]="lazy"
  [showLoader]="false"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [tableStyleClass]="tableStyleClass"
  [reorderableColumns]="reorderableColumns"
  [loading]="isLoading"
  [selection]="selection"
  (sortFunction)="customSortEmitter.emit($event)"
  (onLazyLoad)="lazyLoadEmitter.emit($event)"
  (onFilter)="filterEmitter.emit($event)"
  (onSort)="sortHandler($event)"
  (onRowReorder)="rowReorder.emit($event)"
  (onRowSelect)="rowSelectionChangeHandler($event, true)"
  (onRowUnselect)="rowSelectionChangeHandler($event, false)"
  (selectionChange)="selectAllChangeHandler($event)"
>
  <ng-container *ngIf="enableBulkActions || headerColumns">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of headerColumns; let i = index">
          <th [attr.class]="col.styleClass" [attr.style]="col.style">
            <ng-container *ngIf="enableBulkActions && !selection.length">
              {{ col.header }}
            </ng-container>
            <ng-container
              *ngIf="
                enableBulkActions &&
                selection.length &&
                i === bulkActionsColumnIndex
              "
            >
              <div class="d-flex align-items-center gap-24 ml-8">
                <db-button-icon
                  icon="pi-pencil"
                  [dynamicSize]="true"
                  (click)="bulkEdit.emit(selection)"
                ></db-button-icon>
                <db-button-icon
                  icon="pi-trash"
                  [dynamicSize]="true"
                  (click)="bulkDelete.emit(selection)"
                ></db-button-icon>
                <span class="ml-16">{{
                  selection.length | i18nPlural : selectionCountPluralMap
                }}</span>
              </div>
            </ng-container>

            <p-tableHeaderCheckbox
              *ngIf="
                enableBulkActions && col.columnType === TableColumnType.CHECKBOX
              "
            />
          </th>
        </ng-container>
      </tr>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!enableBulkActions">
    <ng-template pTemplate="header" let-columns>
      <ng-container
        *ngTemplateOutlet="
          headerTemplate ? headerTemplate : defaultHeader;
          context: { columns };
          injector: templateInjector
        "
      ></ng-container>
    </ng-template>
  </ng-container>

  <ng-template
    pTemplate="body"
    let-rowData
    let-index="rowIndex"
    let-columns="columns"
  >
    <ng-container
      *ngTemplateOutlet="
        getBodyTemplate();
        context: { rowData, index };
        injector: templateInjector
      "
    ></ng-container>
  </ng-template>

  <ng-template pTemplate="loadingbody">
    <ng-container
      *ngTemplateOutlet="defaultLoadingTemplate;
        injector: templateInjector
      "
    >
    </ng-container>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <ng-container
      *ngTemplateOutlet="
        emptyMessageTemplate
          ? emptyMessageTemplate
          : defaultEmptyMessageTemplate;
        injector: templateInjector
      "
    >
    </ng-container>
  </ng-template>
</p-table>

<ng-template #defaultHeader>
  <tr>
    <th *ngFor="let col of columns">
      {{ col.header }}
    </th>
  </tr>
</ng-template>

<ng-template #defaultBodyTemplate let-rowData="rowData">
  <tr>
    <td *ngFor="let col of columns">
      {{ rowData[col.field] }}
    </td>
  </tr>
</ng-template>

<ng-template #defaultEmptyMessageTemplate>
  <div class="ml-16">
    <p i18n="@@common|no-results-found">No results found</p>
  </div>
</ng-template>

<ng-template #defaultLoadingTemplate>
  <tr *ngFor="let i of skeletonLoaderRows">
    <td *ngFor="let skeleton of columnSkeletonLoaders">
      <db-skeleton-loader [skeletonType]="skeleton.type"></db-skeleton-loader>
    </td>
  </tr>
</ng-template>
