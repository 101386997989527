import { Provider } from "@angular/core";
import { ENVIRONMENT } from "../constants";
import { Environment } from "../enums";

export function environmentProviderFactory(env: Environment): Provider {
  return {
    provide: ENVIRONMENT,
    useValue: env
  };
}
