import { IGenericConfirmation } from '../interfaces';

export const getUnsavedChangesConfirmationConfig = (cfg: Partial<IGenericConfirmation> = {}): IGenericConfirmation => ({
  ...(cfg || {}),
  headerText: cfg.headerText || $localize`:@@confirmations|action-text:Unsaved changes`,
  bodyText:
    cfg?.bodyText ||
    $localize`:@@confirmations|action-body:If you leave this page, your changes won't be saved.\nTo save changes, click 'Cancel' below. Then, click 'Save' in the top right corner of the page.`,
  confirmButtonLabel: cfg?.confirmButtonLabel || $localize`:@@common|save:Save`,
  cancelButtonLabel: cfg?.cancelButtonLabel || $localize`:@@common|continue-without-saving:Continue without saving`,
  disableCancel: cfg?.disableCancel || false,
  disableConfirm: cfg?.disableConfirm || false,
});
