import { createReducer, on } from '@ngrx/store';
import { IGenericConfirmation, ILocalConfirmationConfig } from '../interfaces';
import { addOpenConfirmationBundle, confirmationResultBundle, openGenericConfirmationBundle } from './bundles';

type IStoreGenericConfirmation = IGenericConfirmation & { name: string; timestamp: number };
type IConfirmationStoreConfig = ILocalConfirmationConfig & Partial<IStoreGenericConfirmation> & { isGeneric: boolean; timestamp: number };

export interface IConfirmationState {
  confirmationConfigs: IConfirmationStoreConfig[];
}

const initialState: IConfirmationState = {
  confirmationConfigs: [],
};

let genericConfirmationId = 0;

export const confirmationReducer = createReducer(
  initialState,
  on(addOpenConfirmationBundle.__addOpenConfirmation, (state, action) => {
    const { config, timestamp } = action;
    return { ...state, confirmationConfigs: state.confirmationConfigs.concat({ ...config, isGeneric: false, timestamp }) };
  }),
  on(openGenericConfirmationBundle.openGenericConfirmation, (state, { config, timestamp }) => {
    const genericConfig: IGenericConfirmation & { name: string } = { ...config, name: `generic-confirmation-${genericConfirmationId++}` };

    return { ...state, confirmationConfigs: state.confirmationConfigs.concat({ ...genericConfig, isGeneric: true, timestamp }) };
  }),
  on(confirmationResultBundle.confirmationResult, (state, { name }) => {
    const config = state.confirmationConfigs.find((c) => c.name === name);
    if (!config) {
      return state;
    }
    if (config.isGeneric) {
      genericConfirmationId--;
    }
    return { ...state, confirmationConfigs: state.confirmationConfigs.filter((n) => n.name !== name) };
  })
);
