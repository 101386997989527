import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OfficeRoleBackgroundColor, OfficeRoleCode } from 'types';
import { IOfficeRoleLabelDto } from './office-role-label-dto';
import { NgClass, NgStyle } from '@angular/common';
import { ExecPipe } from 'shared-pipes';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'db-office-role-label',
  templateUrl: './office-role-label.component.html',
  styleUrls: ['./office-role-label.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, ExecPipe, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeRoleLabelComponent implements OnChanges {
  @Input({ required: true }) officeRoleCode!: OfficeRoleCode;
  /** Controls whether the labels are shown as chip with icon and colorful background */
  @Input() displayAsChips = false;
  @Input() officeNames: string[] = [];

  readonly OfficeRoleCode = OfficeRoleCode;

  officeRoleDto: IOfficeRoleLabelDto = {} as IOfficeRoleLabelDto;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['officeRoleCode'] && changes['officeRoleCode'].currentValue) {
      this.officeRoleDto = this.initOfficeRoleDto(changes['officeRoleCode'].currentValue as OfficeRoleCode);
    }
  }

  private initOfficeRoleDto(officeRoleCode: OfficeRoleCode): IOfficeRoleLabelDto {
    const ngClass = ['office-role', !this.displayAsChips ? 'dark-gray' : '', this.displayAsChips ? 'colorful' : ''];

    switch (officeRoleCode) {
      case OfficeRoleCode.EVACUATION_ASSISTANT:
        return {
          label: $localize`:@@common|office-roles-evacuation-assistant:Evacuation Assistant`,
          icon: this.displayAsChips ? 'assets/icons/office-roles/evacuation-white.svg' : 'assets/icons/evacuation.svg',
          ngStyle: { 'background-color': this.displayAsChips ? OfficeRoleBackgroundColor.EVACUATION_ASSISTANT : '' },
          ngClass,
        };
      case OfficeRoleCode.FIRST_AIDER:
        return {
          label: $localize`:@@common|office-roles-first-aider:First aider`,
          icon: this.displayAsChips ? 'assets/icons/office-roles/medic-white.svg' : 'assets/icons/medic.svg',
          ngStyle: { 'background-color': this.displayAsChips ? OfficeRoleBackgroundColor.FIRST_AIDER : '' },
          ngClass,
        };
      case OfficeRoleCode.FIRE_RESPONDER:
        return {
          label: $localize`:@@common|office-roles-fire-responder:Fire responder`,
          icon: this.displayAsChips ? 'assets/icons/office-roles/fire-white.svg' : 'assets/icons/fire.svg',
          ngStyle: { 'background-color': this.displayAsChips ? OfficeRoleBackgroundColor.FIRE_RESPONDER : '' },
          ngClass,
        };
      case OfficeRoleCode.KEY_HOLDER:
        return {
          label: $localize`:@@common|office-roles-key-holder:Key holder`,
          icon: this.displayAsChips ? 'assets/icons/office-roles/key-white.svg' : 'assets/icons/key.svg',
          ngStyle: { 'background-color': this.displayAsChips ? OfficeRoleBackgroundColor.KEY_HOLDER : '' },
          ngClass,
        };

      default:
        return {} as IOfficeRoleLabelDto;
    }
  }
}
