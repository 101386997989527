import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dbResponsiveHide]',
  standalone: true,
})
export class ResponsiveHideDirective implements OnInit, OnDestroy {
  private hasView = false;
  private breakpointSubscription!: Subscription;
  private breakpointValue!: string;

  @Input('dbResponsiveHide')
  set breakpoint(value: string) {
    this.breakpointValue = value;
    this.setupObserver();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.setupObserver();
  }

  private setupObserver() {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }

    if (!this.breakpointValue) {
      return;
    }

    this.breakpointSubscription = this.breakpointObserver
      .observe(this.breakpointValue)
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          // Hide content when the breakpoint matches
          if (this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
          }
        } else {
          // Show content when the breakpoint does not match
          if (!this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }
}