import { NgStyle } from '@angular/common';
import { Component, Input, SecurityContext, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'db-html-container',
  templateUrl: './html-container.component.html',
  standalone: true,
  imports: [NgStyle]
})
export class HtmlContainerComponent {
  @Input() htmlValue?: string | null;

  @Input() style: { [klass: string]: any } | null | undefined;

  private readonly domSanitizer = inject(DomSanitizer);

  get htmlValueSafe(): string | null {
    return this.htmlValue ? this.fixLinks(this.domSanitizer.sanitize(SecurityContext.HTML, this.htmlValue)) : '';
  }

  private fixLinks(html: string | null): string {
    if (!html) {
      return '';
    }
    return html.replace(/href="(?!http)/g, 'href="//');
  }
}
