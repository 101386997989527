import { NgFor } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OfficeRoleLabelComponent } from '../office-role-label/office-role-label.component';
import { OfficeRoleCode } from 'types';
import { ExecPipe } from 'shared-pipes';

@Component({
  selector: 'db-office-role-label-all-offices-list',
  standalone: true,
  imports: [NgFor, ExecPipe, OfficeRoleLabelComponent],
  templateUrl: './office-role-label-all-offices-list.component.html',
  styleUrls: ['./office-role-label-all-offices-list.component.scss'],
})
export class OfficeRoleLabelAllOfficesListComponent implements OnChanges {
  readonly Object = Object;

  @Input({ required: true }) officeRolesForAllOffices!: Map<OfficeRoleCode, string[]>;
  /** Controls whether the labels are shown as chip with icon and colorful background */
  @Input() displayAsChips = false;

 _officeRolesForAllOffices: { [key: string]: string[] } = {};

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['officeRolesForAllOffices'] && changes['officeRolesForAllOffices'].currentValue) {
      const officeRolesForAllOffices = changes['officeRolesForAllOffices'].currentValue as Map<OfficeRoleCode, string[]>;

      const _officeRolesForAllOffices: { [key: string]: string[] } = {};

      for (const key of officeRolesForAllOffices.keys()) {
        _officeRolesForAllOffices[key] = officeRolesForAllOffices.get(key) || [];
      }

      this._officeRolesForAllOffices = _officeRolesForAllOffices;

    }
  }

}
