import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { EventEmitterValue } from 'shared-types';

@Component({
  selector: 'db-input-mask',
  standalone: true,
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
  imports: [FormsModule, InputMaskModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputMaskComponent,
      multi: true,
    },
  ],
})
export class InputMaskComponent implements OnInit, ControlValueAccessor {
  cd = inject(ChangeDetectorRef);

  @ViewChild(InputMask, { read: InputMask, static: true }) primeInputMask!: InputMask;

  @Input() characterPattern: string = '[A-Za-z]';
  @Input() mask: string = '******';
  @Input() type: string = 'text';
  @Input() slotChar: string = '_';
  @Input() autoClear: boolean = true;
  @Input() showClear: boolean = false;
  @Input() style: any;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  // @Input() inputId: string;
  // @Input() styleClass: string;
  // @Input() placeholder: string;
  // @Input() size: number;
  // @Input() maxlength: number;
  // @Input() tabindex: string;
  // @Input() title: string;
  // @Input() ariaLabel: string;
  // @Input() ariaRequired: boolean;
  // @Input() readonly: boolean;
  // @Input() unmask: boolean;
  // @Input() name: string;
  // @Input() autoFocus: boolean;
  // @Input() autocomplete: string;

  @Output() onComplete: EventEmitter<EventEmitterValue<InputMask['onComplete']>> = new EventEmitter();
  @Output() onFocus: EventEmitter<EventEmitterValue<InputMask['onFocus']>> = new EventEmitter();
  @Output() onBlur: EventEmitter<EventEmitterValue<InputMask['onBlur']>> = new EventEmitter();
  @Output() onInput: EventEmitter<EventEmitterValue<InputMask['onInput']>> = new EventEmitter();
  @Output() onKeydown: EventEmitter<EventEmitterValue<InputMask['onKeydown']>> = new EventEmitter();
  @Output() onClear: EventEmitter<EventEmitterValue<InputMask['onClear']>> = new EventEmitter();

  changeFn!: (value: any) => void;
  touchFn!: () => void;
  value = '';

  constructor() {}

  valueChangedHandler(value: any): void {
    this.value = value;
    if (this.changeFn) {
      this.changeFn(value);
    }

    this.registerOnTouched(value);
    this.cd.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;
    this.primeInputMask.writeValue(value);
    this.cd.detectChanges();
  }

  registerOnChange = (fn: any): void => {
    this.changeFn = fn;
  };

  registerOnTouched = (fn: any): void => {
    this.touchFn = fn;
  };

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.primeInputMask.buffer = [];
    this.primeInputMask.onComplete.subscribe(this.onComplete);
    this.primeInputMask.onFocus.subscribe(this.onFocus);
    this.primeInputMask.onBlur.subscribe(this.onBlur);
    this.primeInputMask.onInput.subscribe(this.onInput);
    this.primeInputMask.onKeydown.subscribe(this.onKeydown);
    this.primeInputMask.onClear.subscribe(this.onClear);
  }
}
