import { Pipe, PipeTransform } from '@angular/core';
import { AreaResourcesTranslationTypePlural } from '../enums';
import { DeskAreaType } from 'types';
import { getMappedAreaSuffixValue } from '../utils';

@Pipe({
  name: 'areaTypePluralMap',
  standalone: true,
})
export class AreaTypePluralMapPipe implements PipeTransform {
  areaResourcesMap: { [key: string]: { [key: string]: string } } = {
    desk: {
      '=0': $localize`:@@common|desks.zero:0 desks`,
      '=1': $localize`:@@common|desks.one:# desk`,
      few: $localize`:@@common|desks.few:# desks`,
      many: $localize`:@@common|desks.many:# desks`,
      other: $localize`:@@common|desks.other:# desks`,
    },
    'meeting room': {
      '=0': $localize`:@@common|meeting-room-spots.zero:0 spots`,
      '=1': $localize`:@@common|meeting-room-spots.one:# spot`,
      few: $localize`:@@common|meeting-room-spots.few:# spots`,
      many: $localize`:@@common|meeting-room-spots.many:# spots`,
      other: $localize`:@@common|meeting-room-spots.other:# spots`,
    },
    'parking spot': {
      '=0': $localize`:@@common|parking-spots.zero:0 spots`,
      '=1': $localize`:@@common|parking-spots.one:# spot`,
      few: $localize`:@@common|parking-spots.few:# spots`,
      many: $localize`:@@common|parking-spots.many:# spots`,
      other: $localize`:@@common|parking-spots.other:# spots`,
    },
    item: {
      '=0': $localize`:@@common|items.zero:0 items`,
      '=1': $localize`:@@common|items.one:# item`,
      few: $localize`:@@common|items.few:# items`,
      many: $localize`:@@common|items.many:# items`,
      other: $localize`:@@common|items.other:# items`,
    },
  };

  areaResourceAvailableMapBySuffix: { [key: string]: { [key: string]: string } } = {
    desk: {
      '=0': $localize`:@@common|desks-available.zero:No desks available`,
      '=1': $localize`:@@common|desks-available.one:# desk available`,
      few: $localize`:@@common|desks-available.few:# desks available`,
      many: $localize`:@@common|desks-available.many:# desks available`,
      other: $localize`:@@common|desks-available.other:# desks available`,
    },
    'meeting room': {
      '=0': $localize`:@@common|meeting-room-spots-available.zero:No spots available`,
      '=1': $localize`:@@common|meeting-room-spots-available.one:# spot available`,
      few: $localize`:@@common|meeting-room-spots-available.few:# spots available`,
      many: $localize`:@@common|meeting-room-spots-available.many:# spots available`,
      other: $localize`:@@common|meeting-room-spots-available.other:# spots available`,
    },
    'parking spot': {
      '=0': $localize`:@@common|parking-spots-available.zero:No spots available`,
      '=1': $localize`:@@common|parking-spots-available.one:# spot available`,
      few: $localize`:@@common|parking-spots-available.few:# spots available`,
      many: $localize`:@@common|parking-spots-available.many:# spots available`,
      other: $localize`:@@common|parking-spots-available.other:# spots available`,
    },
    item: {
      '=0': $localize`:@@common|items-available.zero:No items available`,
      '=1': $localize`:@@common|items-available.one:# item available`,
      few: $localize`:@@common|items-available.few:# items available`,
      many: $localize`:@@common|items-available.many:# items available`,
      other: $localize`:@@common|items-available.other:# items available`,
    },
  };

  areaResourceActiveMapBySuffix: { [key: string]: { [key: string]: string } } = {
    desk: {
      '=0': $localize`:@@common|desks-active.zero:No desks active`,
      '=1': $localize`:@@common|desks-active.one:# desk active`,
      few: $localize`:@@common|desks-active.few:# desks active`,
      many: $localize`:@@common|desks-active.many:# desks active`,
      other: $localize`:@@common|desks-active.other:# desks active`,
    },
    'meeting room': {
      '=0': $localize`:@@common|meeting-room-spots-active.zero:No spots active`,
      '=1': $localize`:@@common|meeting-room-spots-active.one:# spot active`,
      few: $localize`:@@common|meeting-room-spots-active.few:# spots active`,
      many: $localize`:@@common|meeting-room-spots-active.many:# spots active`,
      other: $localize`:@@common|meeting-room-spots-active.other:# spots active`,
    },
    'parking spot': {
      '=0': $localize`:@@common|parking-spots-active.zero:No spots active`,
      '=1': $localize`:@@common|parking-spots-active.one:# spot active`,
      few: $localize`:@@common|parking-spots-active.few:# spots active`,
      many: $localize`:@@common|parking-spots-active.many:# spots active`,
      other: $localize`:@@common|parking-spots-active.other:# spots active`,
    },
    item: {
      '=0': $localize`:@@common|items-active.zero:No items active`,
      '=1': $localize`:@@common|items-active.one:# item active`,
      few: $localize`:@@common|items-active.few:# items active`,
      many: $localize`:@@common|items-active.many:# items active`,
      other: $localize`:@@common|items-active.other:# items active`,
    },
  };

  transform(deskAreaType: DeskAreaType, areaTranslationType: AreaResourcesTranslationTypePlural): any {
    const deskAreaTypeSuffix = getMappedAreaSuffixValue(deskAreaType);

    if (!deskAreaTypeSuffix) {
      return 'N/A';
    }

    switch (areaTranslationType) {
      case AreaResourcesTranslationTypePlural.RESOURCE:
        return this.areaResourcesMap[deskAreaTypeSuffix];
      case AreaResourcesTranslationTypePlural.AVAILABLE:
        return this.areaResourceAvailableMapBySuffix[deskAreaTypeSuffix];
      case AreaResourcesTranslationTypePlural.ACTIVE:
        return this.areaResourceActiveMapBySuffix[deskAreaTypeSuffix];
      default:
        return 'N/A';
    }
  }
}
