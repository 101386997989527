import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UPLOAD_STORE_NAME } from '../constants';
import { IUploadState } from './reducer';

export const getUploadState = createFeatureSelector<IUploadState>(UPLOAD_STORE_NAME);
export const getProgress = (state: IUploadState) => state.progress;
export const getFilenames = (state: IUploadState) => state.fileNames;
export const getFailedUploads = (state: IUploadState) => state.failedUploadIds;
export const getSuccessfulUploads = (state: IUploadState) => state.successfulUploadIds;

export const selectors = {
  progress: createSelector(getUploadState, getProgress),
  filenames: createSelector(getUploadState, getFilenames),
  failedUploads: createSelector(getUploadState, getFailedUploads),
  successfulUploads: createSelector(getUploadState, getSuccessfulUploads)
};
