<ng-template #routerLink>
  <a id="control" [routerLink]="link" [queryParamsHandling]="queryParamsHandling" [queryParams]="queryParams"
    [routerLinkActiveOptions]="options" routerLinkActive="active">
    <span>
      {{title}}
    </span>
  </a>
</ng-template>
<ng-template #noLink>
  <a id="control" [ngClass]="{ 'active': isActive }">
    <span>
      {{title}}
    </span>
  </a>
</ng-template>

<ng-container *ngTemplateOutlet="useRouterLink ? routerLink : noLink"></ng-container>