import { StaticProvider, enableProdMode } from "@angular/core";
import * as Sentry from "@sentry/angular";

import {
  isLocalEnvironment,
  isProductionEnvironment,
  isStagingEnvironment,
} from "common-module";
import { bootstrapWithLanguage } from "internalization-module";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { LAZY_MODULES } from "./app/constants";
import { routes } from "./app/app-routing.module";

if (!isLocalEnvironment(environment.appURL)) {
  enableProdMode();
}

if (
  isProductionEnvironment(environment.env) ||
  isStagingEnvironment(environment.env)
) {
  Sentry.init({
    dsn: "https://9cde984ea68fb0271739434544830b94@o4507389786521600.ingest.de.sentry.io/4507929171132496",
    environment: environment.env,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [environment.apiURL, environment.appURL],
    tracesSampler: ({ attributes, parentSampled }) => {
      if (attributes?.["sentry.origin"]?.includes("sentry_sample_rate=1")) {
        return 1;
      }

      if (typeof parentSampled === "boolean") {
        return parentSampled;
      }

      for (const [path, dynamicSampleRate] of Object.entries(
        environment.sentryDynamicSampleRate,
      )) {
        if (location?.pathname?.startsWith(path)) {
          return dynamicSampleRate;
        }
      }

      return environment.sentrySampleRate;
    },
    release: environment.sentryRelease,
  });
}

const searchParams = new URLSearchParams(window.location.search);
const langQueryParam = searchParams.get("lang") || undefined;
if (langQueryParam) {
  searchParams.delete("lang");
  const search = searchParams.toString();
  window.history.replaceState(
    window.history.state,
    window.document.title,
    window.location.pathname + (search.length > 0 ? "?" + search : ""),
  );
}

Promise.all([
  import("user-profile-module"),
  import("profile-module"),
  import("floor-plan-module"),
])
  .then(([userProfileModule, profileModule, floorPlanModule]) => {
    routes.unshift(
      ...userProfileModule.routes,
      ...profileModule.routes,
      ...floorPlanModule.routes,
    );
    const extraProviders: StaticProvider[] = [
      {
        provide: LAZY_MODULES,
        useValue: [
          {
            component: profileModule.ProfileComponent,
            module: profileModule.ProfileModule,
          },
          {
            component: userProfileModule.UserProfileWrapperComponent,
            module: userProfileModule.UserProfileModule,
          },
          {
            component: floorPlanModule.FloorPlanOutletComponent,
            module: floorPlanModule.FloorPlanWithProvidersModule,
          },
        ],
      },
    ];
    bootstrapWithLanguage(AppModule, langQueryParam, extraProviders);
  })
  .catch(console.error);
