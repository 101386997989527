<div
  *ngIf="!showOnlyStatusIcon; else statusIconOnly"
  class="status"
  [ngClass]="{ 'flex-end': alignContentEnd }"
  [pTooltip]="bookingStatusDto.tooltipText"
  [tooltipPosition]="'top'"
>
  <div class="status-row">
    <img [src]="bookingStatusDto.iconSrc" alt="icon" />
    <button
      *ngIf="booking.checkInStatus === 'readyForNonQRCheckIn'; else simpleText"
      class="check-in-cta text-primary small-text-bold"
      [attr.data-testid]="dataTestIdForCheckInCta"
      (click)="checkInHandler($event)"
    >
      {{ bookingStatusDto.labelText }}
    </button>
    <ng-template #simpleText>
      <span class="small-text">{{ bookingStatusDto.labelText }}</span>
    </ng-template>
  </div>

  <div *ngIf="bookingStatusDto.additionalInfoText" class="status-row">
    <span class="very-small-text text-dark-gray">
      {{ bookingStatusDto.additionalInfoText }}
    </span>
  </div>
</div>

<ng-template #statusIconOnly>
  <img [src]="bookingStatusDto.iconSrc" alt="icon" />
</ng-template>
