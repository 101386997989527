import { IMeetingRoomKioskEventData } from './meeting-room-calendar-data';

export interface IMeetingRoomKioskInfo {
  id: string;
  name: string;
  description: string;
  imageUrl?: string;
  capacity: number;
  timezone: string;
  checkIn: { enabled: boolean };
  equipment?: { title: string; id: string }[];
  serviceRequestsEnabled: boolean;
}

export interface IMeetingRoomKioskData {
  meetingRoom: IMeetingRoomKioskInfo;
  events: IMeetingRoomKioskEventData[];
}

export interface IMeetingRoomKioskBookingCreationData {
  subject: string;
  description?: string;
  startTime: string;
  endTime: string;
  isPrivate?: boolean;
}
