<div class="user-image-list">
  <div class="user" *ngFor="let user of visibleUsers; let idx = index; trackBy: trackByFn"
    [ngStyle]="avatarSpacingStyle === 'overlap' ? { left: (idx * -13) + 'px' } : { marginRight: '8px'}">

    <div class="badge" *ngIf="itemBadgeTemplateRef">
      <ng-container *ngTemplateOutlet="itemBadgeTemplateRef!"></ng-container>
    </div>

    <db-user-avatar [userInfo]="user" [authUser]="authUser" [openProfileOnClick]="openProfileOnClick"
      (profileOpening)="openProfileHandler($event)"></db-user-avatar>
  </div>

  <div class="user none" *ngIf="!visibleUsers.length">
    <span class="empty"></span>
    <span class="info" i18n="@@common|no-one-here-yet">No one is here yet.</span>
  </div>

  <div class="rest" *ngIf="hiddenUserCount"
    [ngStyle]="avatarSpacingStyle === 'overlap' ? { left: (visibleUsers.length * -13) + 'px' } : { left: 0}">
    +{{ hiddenUserCount }}
  </div>

</div>
