
import { IExtendedDeskArea } from "./extended-desk-area";
import { IOfficeFloor } from "./office-floor";

export interface IExtendedOfficeFloor extends Omit<IOfficeFloor, 'zones'> {
  isRemoved?: boolean;
  isNew?: boolean;
  animate?: boolean;
  zones: IExtendedDeskArea[];
}
