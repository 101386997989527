export interface ICateringCategoryItem {
  id?: string;
  title: string;
}

export interface IMeetingEventCreationCateringCategoryItem extends ICateringCategoryItem {
  id: string;
  count: number;
}

export interface CateringCategory {
  id: string;
  officeId: string;
  title: string;
  providerEmail: string;
  description?: string;
  items: ICateringCategoryItem[];
  isActive?: boolean;
  bufferBeforeMinutes?: number;
  bufferAfterMinutes?: number;
}

export type CateringCategoryDto = Omit<CateringCategory, 'id'> & { id?: string };

export interface IMeetingEventCreationCateringCategoryData {
  id: string;
  items: IMeetingEventCreationCateringCategoryItem[];
  title?: string;
  note?: string;
  bufferBeforeMinutes?: number;
  bufferAfterMinutes?: number;
}

export interface BufferOverlapInfo {
  hasOverlap: boolean;
  haveToReschedule: boolean;
  newMeetingTimeInMinutes?: number;
  overlapMinutes?: BufferOverlapMinutes
}

export interface BufferOverlapMinutes {
  previous?: number, next?: number
}
