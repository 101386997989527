import { CommonModule, NgFor } from '@angular/common';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'db-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  imports: [PanelModule, NgFor, CommonModule],
  standalone: true,
})
export class PanelComponent {
  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  @Input() toggleable = true;
  @Input() collapsed = false

  @Output() collapsedChange = new EventEmitter<boolean>();
}
