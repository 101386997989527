import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlideOverService {

  _slideOverOpen$$ = new BehaviorSubject<boolean>(false);

  slideOverOpen$ = this._slideOverOpen$$.asObservable().pipe(distinctUntilChanged());

  constructor() { }
}
