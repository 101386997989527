import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotLoaderComponent } from 'db-ui';

export enum LoadingTenseState {
  Past,
  Present,
  Infinitive,
}

@Component({
  selector: 'db-loading-tense',
  templateUrl: './loading-tense.component.html',
  standalone: true,
  imports: [CommonModule, DotLoaderComponent, DotLoaderComponent],
})
export class LoadingTenseComponent {
  @Input() state!: LoadingTenseState;
  @Input() pastText!: string;
  @Input() presentText!: string;
  @Input() infinitiveText!: string;
  LoadingTenseState = LoadingTenseState;
}
