import { Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[dbAttachPopupContainer]',
  standalone: true,
})
export class AttachPopupContainerDirective {
  readonly detachAll$ = new Subject<void>();
}
