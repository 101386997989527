import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of, race, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[dbEmailUniqueValidator]',
  providers: [{
    provide: NG_ASYNC_VALIDATORS,
    multi: true,
    useExisting: EmailUniqueValidatorDirective
  }]
})
export class EmailUniqueValidatorDirective implements AsyncValidator, OnInit, OnDestroy {

  valueSubject$$ = new Subject();
  killSubscriptions$$ = new Subject<void>();

  @Input() debounceTime = 500;
  @Input() publicDomainList: string[] | null = null;
  @Input() checkEmailSuccess$!: Observable<any>;
  @Input() checkEmailFailure$!: Observable<any>;
  @Output() checkEmail = new EventEmitter<{ email: string }>();
  currentError: { [key: string]: any } | null = null;

  ngOnInit(): void {
    this.valueSubject$$.pipe(
      debounceTime(this.debounceTime),
      takeUntil(this.killSubscriptions$$)
    ).subscribe((value: unknown) => {
      if (this.currentError) { return; }
      this.checkEmail.emit({ email: value as string });
    });
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    this.valueSubject$$.next(control.value as string);

    const [, domain] = control.value.split('@');

    const validMail = domain.includes('.');
    const isPublicDomain = validMail
      ? this.publicDomainList?.findIndex(d => d === domain) !== -1
      : false;

    this.currentError = validMail
      ? isPublicDomain ? { isPublicDomain } : null
      : { validMail };

    if (this.currentError) { return of(this.currentError); }

    return race(
      this.checkEmailSuccess$,
      this.checkEmailFailure$.pipe(map(() => null))
    ).pipe(
      take(1)
    );
  }

  ngOnDestroy(): void {
    this.killSubscriptions$$.next();
    this.killSubscriptions$$.complete();
  }
}