import { Directive, Input, OnDestroy } from '@angular/core';

import { BehaviorSubject, combineLatest, from, Observable, race, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, mergeMap, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ResolveBundle } from './resolve-bundle';
const NOT_SET = Symbol('DEPS_NOT_SET');

export function generateTimestamp() {
  return (Date.now() + Math.random());
}

@Directive({
  selector: '[dbResolve]',
  exportAs: 'dbResolve'
})
export class ResolveDirective implements OnDestroy {

  private reload$$ = new Subject<void | null | ResolveBundle>();
  private destroySubscriptions$$ = new Subject<void>();
  private isResolvingByIndex: { [key: number]: boolean } = {};
  private resolvingByIndexDeps: { [key: number]: any } = {};
  private dispatchCancelList = [] as ((...args: any[]) => void)[];
  private errors = [] as boolean[];
  private isResolving$$ = new BehaviorSubject<boolean>(false);
  lastSuccessfulResolveTimestamp: number[] = [];
  isResolving$ = this.isResolving$$.asObservable().pipe(distinctUntilChanged());

  get hasError(): boolean {
    return this.errors.includes(true);
  }

  get isResolving(): boolean {
    return Object.values(this.isResolvingByIndex).includes(true);
  }

  @Input() skipResolve = false;

  @Input() set dbResolve(bundles: ResolveBundle[]) {
    this.destroySubscriptions$$.next();
    this.errors = bundles.map(() => false);
    this.dispatchCancelList = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < bundles.length; i++) {
      const bundle = bundles[i];
      const { withTimestamp } = bundle;
      this.errors[i] = false;

      this.isResolvingByIndex[i] = false;
      this.isResolving$$.next(this.isResolving);
      if (bundle.dependencies?.length) {
        combineLatest([
          ...bundle.dependencies,
          this.reload$$.pipe(filter(b => b === null || b === bundle), startWith(null))
        ]).pipe(
          delay(0),
          takeUntil(this.destroySubscriptions$$),
          mergeMap((deps) => {
            return this.dispatchRequest(withTimestamp, i, bundle, deps.slice(0, -1));
          }),
        ).subscribe({
          next: (result) => this.success(i, result),
          error: (error) => { console.error(error); this.failure(i); }
        });
      } else {
        this.reload$$.pipe(
          filter(b => b === null || b === bundle),
          takeUntil(this.destroySubscriptions$$),
          startWith(null),
          delay(0),
          mergeMap(() => this.dispatchRequest(withTimestamp, i, bundle))
        ).subscribe({
          next: (result) => this.success(i, result),
          error: (error) => { console.error(error); this.failure(i); }
        })
      }
    }
  }

  reload(bundles?: ResolveBundle[]): void {
    if (!bundles?.length) { this.reload$$.next(null); return; }
    for (const b of bundles) { this.reload$$.next(b); }
  }

  dispatchRequest = (withTimestamp: boolean, i: number, bundle: any, deps?: any): Observable<any> => {
    if (this.skipResolve) { return from([{ skipResolve: true }]); }
    this.errors[i] = false;
    this.lastSuccessfulResolveTimestamp[i] = new Date().getTime();
    this.isResolvingByIndex[i] = true;
    this.isResolving$$.next(this.isResolving);
    this.resolvingByIndexDeps[i] = deps;
    let timestamp = withTimestamp ? generateTimestamp() : null;
    if (withTimestamp) { deps = [timestamp, ...(deps || [])] }
    const action = bundle.dispatchRequest(...(deps || []));
    timestamp = action?.timestamp || timestamp;

    const cancelFunction = () => bundle.dispatchRequestCancel(withTimestamp ? timestamp : undefined);
    this.dispatchCancelList.push(cancelFunction);

    return race(
      bundle.requestSuccess$.pipe(
        filter((action: any) => withTimestamp ? action.timestamp === timestamp : true),
        map(() => ({ skipResolve: false, race: true }))
      ),
      bundle.requestFailure$.pipe(
        filter((action: any) => withTimestamp ? action.timestamp === timestamp : true),
        map(() => ({ skipResolve: false, race: false }))
      )
    ).pipe(
      take(1),
      takeUntil(this.destroySubscriptions$$),
      tap(() => {
        this.dispatchCancelList = this.dispatchCancelList.filter(val => val !== cancelFunction);
      })
    );
  }

  success = (i: number, result: any) => {
    this.errors[i] = !result.race;
    this.isResolvingByIndex[i] = false;
    this.isResolving$$.next(this.isResolving);
    this.resolvingByIndexDeps[i] = NOT_SET;
  }

  failure = (i: number) => {
    this.isResolvingByIndex[i] = false;
    this.isResolving$$.next(this.isResolving);
    this.resolvingByIndexDeps[i] = NOT_SET;
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$$.next();
    this.destroySubscriptions$$.complete();
    let index = 0;
    for (const dispatchCancel of this.dispatchCancelList) {
      if (!this.isResolvingByIndex[index]) { continue; }
      const deps = this.resolvingByIndexDeps[index];
      dispatchCancel(...(deps || []));
      this.isResolvingByIndex[index] = false;
      this.isResolving$$.next(this.isResolving);
      index++;
    }
    this.dispatchCancelList = [];
  }
}
