import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthModel } from 'auth-module';
import { GoogleTagManagerService } from 'common-module';
import { distinctUntilChanged, tap, withLatestFrom } from 'rxjs';
import { updateBusinessCompanyBundle } from 'administration-module/+store/bundles';
import { exportSchedulingsBundle } from 'analytics-module/+store/people/bundles';
import { convertUserObjectToTrackedProperty } from '../utils/gtm-user-property-converter';
import { convertCompanyObjectToTrackedProperty } from '../utils/gtm-company-property-converter';
import { GlobalModel } from 'global-module';

@Injectable({ providedIn: 'root' })
export class AnalyticsTrackingEffects {
  googleTagManagerInitDataOnLogin = createEffect(
    () =>
      this.authModel.actions.listen.fetchUserAndCorporateInformationSuccess$.pipe(
        distinctUntilChanged((prev, curr) => prev.user?.id === curr.user?.id && prev.corporateInfo?.id === curr.corporateInfo?.id),
        tap((payload) => {
          if (!payload.user || !payload.corporateInfo) {
            return;
          }

          this.googleTagManagerService.initAuthAndCompanyData(payload.user, payload.corporateInfo);
        })
      ),
    { dispatch: false }
  );

  googleTagManagerUserPropertiesChange = createEffect(
    () =>
      this.authModel.actions.listen.updateUserSuccess$.pipe(
        withLatestFrom(this.authModel.authenticatedUser$),
        tap(([payload, authUser]) => {
          if (authUser.id !== payload.userId) return;

          const changedObject = payload.updates;
          const gtmProperties = convertUserObjectToTrackedProperty(changedObject);
          this.googleTagManagerService.updateProperties(gtmProperties);
        })
      ),
    { dispatch: false }
  );

  googleTagManagerCompanyPropertiesChange = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateBusinessCompanyBundle.updateBusinessCompanySuccess),
        withLatestFrom(this.authModel.selectors.corporateInfo$),
        tap(([payload, authUserCompany]) => {
          const { company } = payload;
          if (authUserCompany!.id !== company.id) return;

          const changedObject = company;
          const gtmProperties = convertCompanyObjectToTrackedProperty(changedObject);
          this.googleTagManagerService.updateProperties(gtmProperties);
        })
      ),
    { dispatch: false }
  );

  exportWorkforceAnalytics = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportSchedulingsBundle.exportSchedulings),
        withLatestFrom(this.globalModel.selectors.adminAppCompany$),
        tap(([payload, adminAppCompany]) => {
          const exportType = payload.timeframe ? 'timeframe' : 'all';
          this.googleTagManagerService.pushTag({
            event: 'export_workforce_analytics',
            export_type: exportType,
            privacy_barrier: (adminAppCompany?.peopleAnalyticsPrivacyBarrier || false).toString(),
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private googleTagManagerService: GoogleTagManagerService,
    private authModel: AuthModel,
    private globalModel: GlobalModel,
    private actions$: Actions
  ) {}
}
