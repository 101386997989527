import { OfficeRoleCode } from 'types/enums';

export interface IOfficeRoleOption {
  id: string;
  code: OfficeRoleCode;
  name: string;
}

/** Office role a.k.a office function */
export interface IOfficeRole {
  officeRoleId: string;
  officeIds: string[];
}

export interface IUserOfficeRole {
  officeRoleId: string;
  officeId: string;
  userId: string;
}

export interface IUserOfficeRoleDto {
  code: OfficeRoleCode;
  name: string;
  officeRoleId: string;
  officeId: string;
  userId: string;
  order : number;
}
