<div
  class="user-spaces"
  data-testid="common--user-spaces-cards"
  *ngIf="!isResolving || userSpaces?.length; else skeletonLoaders"
  [ngClass]="{ 'border-cards': showBorder }"
>
  <ng-container
    *ngFor="let userSpace of userSpaces; let i = index; trackBy: trackByFn"
  >
    <div
      class="card"
      #hoverContainer="dbHoverContainer"
      dbHoverContainer
      data-testid="common--user-spaces-cards-card"
      [ngClass]="{
        occupied: userSpace.isOccupied,
        'with-border': showBorder,
        'full-width': takeFullWidth
      }"
    >
      <div class="left">
        <div
          [ngClass]="
            fitDetailsOnOneLine ? 'd-flex align-items-center' : 'd-flex-column'
          "
        >
          <div class="resource-name small-text-bold">
            <span [ngClass]="{ 'full-width': takeFullWidth }">{{
              userSpace.zoneItemName
            }}</span>

            <i
              *ngIf="userSpace.isDedicatedResource"
              class="svg-dedicated-resource size-14"
            >
            </i>

            <ng-container *ngIf="userSpace.isFavoriteResource">
              <ng-container
                *ngTemplateOutlet="
                  favoriteIconTemplate;
                  context: { userSpace, hoverContainer, authUser }
                "
              >
              </ng-container>
            </ng-container>
          </div>

          <div class="vertical-dash ml-0" *ngIf="fitDetailsOnOneLine"></div>

          <div class="area-name small-text">
            {{ userSpace.zoneName }}
          </div>
        </div>

        <ng-container *ngIf="(userSpace.bookings || [])[0] as booking">
          <div class="very-small-text text-light-gray">
            <db-booking-start-end-time
              [hourFormat]="authUser.hourFormat"
              [isFullDay]="booking.isDayPass"
              [startTime]="booking.bookingStartTime"
              [endTime]="booking.bookingEndTime"
              [timeZone]="booking.workspace.address?.timeZone"
            >
            </db-booking-start-end-time>
          </div>
        </ng-container>
      </div>

      <div class="right">
        <div class="action">
          <ng-container
            *ngIf="
              userSpace.bookings?.length || userSpace.isOccupied;
              then showStatus;
              else noBooking
            "
          >
          </ng-container>
          <ng-template #showStatus>
            <div
              *ngIf="userSpace.isOccupied; else myBookingStatus"
              class="small-text label-occupied"
            >
              <span
                class="tooltip-occupied"
                *ngIf="userSpace.isOccupied"
                [pTooltip]="occupiedToolTipMessage"
                tooltipPosition="top"
              >
              </span>
              <ng-container i18n="@@booking-module|your-spaces|space-occupied"
                >Occupied</ng-container
              >
            </div>

            <ng-template #myBookingStatus>
              <db-booking-status
                [booking]="userSpace.bookings![0]"
                [authUser]="authUser"
                [dataTestIdForCheckInCta]="bookingStatusDataTestIdForCheckInCta || undefined"
                (bookingCheckInConfirmation)="
                  bookingCheckInHandler(userSpace.bookings![0])
                "
              >
              </db-booking-status>
            </ng-template>
          </ng-template>

          <ng-template #noBooking>
            <a
              *ngIf="
                !(
                  userSpace.zoneItemId
                  | isSpaceBeingBooked
                    : (userSpaceIdentifiers || [])[i]
                    : resourcesBeingBooked
                );
                else bookingBeingCreated
              "
              class="book-cta small-text-bold"
              data-testid="common--user-spaces-cards-quick-book"
              [ngClass]="{ disabled: disableQuickBook }"
              i18n="@@booking-module|your-spaces|quick-book"
              (click)="quickBookHandler(userSpace)"
              >Quick book</a
            >
            <ng-template #bookingBeingCreated>
              <ngx-skeleton-loader
                [theme]="{ margin: '4px 0 0', width: '88px' }"
              ></ngx-skeleton-loader>
            </ng-template>
          </ng-template>
        </div>

        <ng-container *ngIf="showMoreActionsMenu">
          <div
            class="menu-trigger"
            (click)="extraMenu.toggle($event)"
          >
            <i class="pi pi-ellipsis-v"></i>
          </div>
          <db-menu
            #extraMenu
            [popup]="true"
            [items]="extraActionsMenuItems"
            (onShow)="regenerateExtraActionsMenuItems(userSpace)"
          ></db-menu>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #skeletonLoaders>
  <div class="skeleton-loaders-container user-spaces">
    <div>
      <div class="card">
        <div class="left">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              margin: '0px',
              height: '15px',
              position: 'relative',
              bottom: '-4px'
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ width: '90px', margin: 0, height: '10px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="right">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              height: '20px',
              margin: '0px',
              position: 'relative',
              top: '-1px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div>
      <div class="card">
        <div class="left">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              margin: '0px',
              height: '15px',
              position: 'relative',
              bottom: '-4px'
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ width: '90px', margin: 0, height: '10px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="right">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              height: '20px',
              margin: '0px',
              position: 'relative',
              top: '-1px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div>
      <div class="card">
        <div class="left">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              margin: '0px',
              height: '15px',
              position: 'relative',
              bottom: '-4px'
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{ width: '90px', margin: 0, height: '10px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="right">
          <ngx-skeleton-loader
            [theme]="{
              width: '60px',
              height: '20px',
              margin: '0px',
              position: 'relative',
              top: '-1px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
