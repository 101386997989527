/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Chips, ChipsAddEvent, ChipsModule, ChipsRemoveEvent } from 'primeng/chips';

@Component({
  selector: 'db-chips',
  standalone: true,
  imports: [CommonModule, ChipsModule, FormsModule],
  templateUrl: './chips.component.html',
  styleUrls: [], // check chips.scss under db-ui overrides
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipsComponent,
      multi: true,
    },
  ],
})
export class ChipsComponent implements ControlValueAccessor {
  @Input() values: string[] | undefined;
  @Input() label: string | undefined;
  @Input() inputId: string | undefined;
  @Input() showAsterisk: boolean = false;
  @Input() optional: boolean = false;
  @Input() dataTestId: string | undefined;

  // PrimeNG Chips properties
  @Input() regex: RegExp | undefined;
  @Input() name = '';
  @Input() disabled: boolean = false; // When present, it specifies that the element should be disabled.
  @Input() max: number = 100; // Maximum number of entries allowed.
  @Input() placeholder: string | undefined; // Advisory information to display on input.
  @Input() field: string | undefined; // Name of the property to display on a chip.
  @Input() styleClass: string | undefined; // Style class of the element.

  // @Input() style: { [klass: string]: any } | null | undefined; // Inline style of the element.
  // @Input() ariaLabelledBy: string | undefined; // Establishes relationships between the component and label(s) where its value should be one or more element IDs.
  // @Input() tabindex: number | undefined; // Index of the element in tabbing order.
  // @Input() inputId: string | undefined; // Identifier of the focus input to match a label defined for the component.
  // @Input() allowDuplicate: boolean = true; // Whether to allow duplicate values or not.
  // @Input() inputStyle: { [klass: string]: any } | null | undefined; // Inline style of the input field.
  // @Input() inputStyleClass: string | undefined; // Style class of the input field.
  // @Input() addOnTab: boolean | undefined; // Whether to add an item on tab key press.
  // @Input() addOnBlur: boolean | undefined; // Whether to add an item when the input loses focus.
  // @Input() separator: string | undefined; // Separator char to add an item when pressed in addition to the enter key.
  // @Input() showClear: boolean = false; // When enabled, a clear icon is displayed to clear the value.

  // @Output() onAdd: EventEmitter<ChipsAddEvent> = new EventEmitter<ChipsAddEvent>(); // Callback to invoke on chip add.
  // @Output() onRemove: EventEmitter<ChipsRemoveEvent> = new EventEmitter<ChipsRemoveEvent>(); // Callback to invoke on chip remove.
  // @Output() onFocus: EventEmitter<Event> = new EventEmitter<Event>(); // Callback to invoke on focus of input field.
  // @Output() onBlur: EventEmitter<Event> = new EventEmitter<Event>(); // Callback to invoke on blur of input field.
  // @Output() onChipClick: EventEmitter<ChipsClickEvent> = new EventEmitter<ChipsClickEvent>(); // Callback to invoke on chip clicked.
  // @Output() onClear: EventEmitter<any> = new EventEmitter<any>(); // Callback to invoke on clear token clicked.

  @Output() valueAdded: EventEmitter<ChipsAddEvent> = new EventEmitter<ChipsAddEvent>();
  @Output() valueRemoved: EventEmitter<ChipsRemoveEvent> = new EventEmitter<ChipsRemoveEvent>();

  _onChange = (_: any): void => {};
  _onTouched = (_: any): void => {};
  @ContentChild('item')
  itemTemplateRef!: TemplateRef<any>;
  private changeDetectorRef = inject(ChangeDetectorRef);

  @ViewChild(Chips, { read: Chips, static: true }) primeNgChips!: Chips;

  onAdd(event: ChipsAddEvent) {
    this.valueAdded.emit(event);
  }

  onRemove(event: ChipsRemoveEvent) {
    this.valueRemoved.emit(event);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    this.primeNgChips.registerOnChange(fn);
  }

  registerOnTouched(fn: (_: any) => void): void {
    this._onTouched = fn;
    this.primeNgChips.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.setDisabledState = () => isDisabled;
  }

  writeValue(values: string[]): void {
    this.values = values;
    this.changeDetectorRef.detectChanges();
  }
}
