import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { delay, map, of } from 'rxjs';

@Directive({
  selector: '[dbValueValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      multi: true,
      useExisting: ValueValidatorDirective,
    },
  ],
  standalone: true,
})
export class ValueValidatorDirective<TValue> implements Validator {
  @Input() debounceTime = 500;
  @Input() checkValue!: (value: TValue) => ValidationErrors | null;
  @Input() ignoreEmptyString = true;

  validate(control: AbstractControl): ValidationErrors | null {
    return of(control.value as TValue).pipe(
      delay(this.debounceTime),
      map((value) => {
        if (this.ignoreEmptyString === false || value !== '') {
          return this.checkValue(value);
        }
        return null;
      })
    );
  }
}
