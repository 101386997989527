export const firebaseStagingConfig = {
  apiKey: 'AIzaSyCDgzkO_CGqVvvc0z1uojddXJ3HE5i6bC4',
  authDomain: 'app-staging.deskbird.com',
  databaseURL: 'https://deskbird-prod.firebaseio.com',
  projectId: 'deskbird-prod',
  storageBucket: 'deskbird-prod.appspot.com',
  messagingSenderId: '762108628002',
  appId: '1:762108628002:web:45c296476dc92557cd0e7b',
  measurementId: 'G-KB83V2GWZ7'
}

export const firebaseProductionConfig = {
  apiKey: 'AIzaSyCJG2vthfqCzIEfbY343MABk46DAuvncRQ',
  authDomain: 'app.deskbird.com',
  databaseURL: 'https://deskbird-bbe72.firebaseio.com',
  projectId: 'deskbird-bbe72',
  storageBucket: 'deskbird-bbe72.appspot.com',
  messagingSenderId: '929555102891',
  appId: '1:929555102891:web:8fd76526a0f0724dd04bee',
  measurementId: 'G-8N1Z02BD4N'
}

export const firebaseSandbox404 = {
  apiKey: "AIzaSyBM54_B65ICvGsNDkuO7oE-joq-ORLpK9Y",
  authDomain: "404.deskbird.com",
  projectId: "deskbird-sandbox-404",
  storageBucket: "deskbird-sandbox-404.appspot.com",
  messagingSenderId: "430512342888",
  appId: "1:430512342888:web:fbae82780138485ccbf8dd"
}

export const firebaseSandboxLabRats = {
  apiKey: "AIzaSyDaM2KmdhTlgCAyEFvFaWzt-AviDfKo54g",
  authDomain: "lab-rats.deskbird.com",
  projectId: "deskbird-sandbox-labrats",
  storageBucket: "deskbird-sandbox-labrats.appspot.com",
  messagingSenderId: "950382740573",
  appId: "1:950382740573:web:a8cbf1c5c94eeb9f3fb9b4"
}

export const firebaseSandboxTnt = {
  apiKey: "AIzaSyABfhEMC5UStRje83LJkMCVfoJo4LACCi0",
  authDomain: "tnt.deskbird.com",
  projectId: "deskbird-sandbox-tnt",
  storageBucket: "deskbird-sandbox-tnt.appspot.com",
  messagingSenderId: "1871730983",
  appId: "1:1871730983:web:34d4dc5149a81cdc893102"
}

export const firebaseSandboxTnw = {
  apiKey: "AIzaSyCFnZeo0OoBVqsYbuZxJMJkR9JaZ84ySgY",
  authDomain: "tnw.deskbird.com",
  projectId: "deskbird-sandbox-tnw",
  storageBucket: "deskbird-sandbox-tnw.appspot.com",
  messagingSenderId: "757847094808",
  appId: "1:757847094808:web:434e20f4dadfe4fc62f56d"
}

export const firebaseSandboxOspreys = {
  apiKey: "AIzaSyAYLD3pp_Y9djPRdVy434OREsnNrdYGq3g",
  authDomain: "ospreys.deskbird.com",
  projectId: "deskbird-sandbox-ospreys",
  storageBucket: "deskbird-sandbox-ospreys.appspot.com",
  messagingSenderId: "1052275139089",
  appId: "1:1052275139089:web:9e5d5d6722a8a448d6c7b0"
};