import { Directive, HostListener, inject, Input } from '@angular/core';
import { GoogleTagManagerService } from 'common-module';
import { GtmEvent } from '../types';

@Directive({
  selector: '[dbGtmEvent]',
  standalone: true,
})
export class GtmEventDirective<TEvent extends GtmEvent, TKey = keyof TEvent & string> {
  private readonly gtmService = inject(GoogleTagManagerService);
  @Input() dbGtmEvent: TKey | null = null;
  @Input() dbGtmEventProps: { [key: string]: any } = {};
  @Input() trackFocus = false;
  
  @HostListener('focus')
  onFocus(): void {
    if (this.dbGtmEvent && this.trackFocus) {
      this.gtmService.pushTag({
        event: this.dbGtmEvent,
        ...this.dbGtmEventProps,
      });
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.dbGtmEvent && !this.trackFocus) {
      this.gtmService.pushTag({
        event: this.dbGtmEvent,
        ...this.dbGtmEventProps,
      });
    }
  }
}
