import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button';

@Component({
  selector: 'db-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonComponent],
})
export class EmptyStateComponent {
  @Input() imageSrc = '';
  @Input() title = '';
  @Input() body = '';
  
  @Input() isWhite = false;
  
  @Input() action = '';
  @Output() actionClick = new EventEmitter<void>();
}
