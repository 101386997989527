import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseResponse } from 'types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoriteSpacesService {

  constructor(private http: HttpClient) { }

  addFavoriteSpace(resourceId: number): Observable<IBaseResponse<{}>> {
    return this.http.patch<IBaseResponse<{}>>(`/api/user/favoriteResource`, { id: resourceId });
  }

  removeFavoriteSpace(resourceId: number): Observable<IBaseResponse<{}>> {
    return this.http.delete<IBaseResponse<{}>>(`/api/user/favoriteResource/${resourceId}`);
  }
}
