<ng-container *ngIf="accessRules">
  <div
    class="d-flex"
    *ngIf="accessRules | exec : isTimeRestricted"
    [ngClass]="{ 'text-gray-icon': isTextGrayIcon }"
  >
    <img
      src="/assets/icons/access-clock.svg"
      [style.width.px]="iconSize"
      [style.height.px]="iconSize"
    />
  </div>
  <div
    class="d-flex"
    *ngIf="accessRules | exec : isRestrictedButHasAccess"
    [ngClass]="{ 'text-gray-icon': isTextGrayIcon }"
  >
    <img
      src="/assets/icons/access-lock-check.svg"
      [style.width.px]="iconSize"
      [style.height.px]="iconSize"
    />
  </div>
  <div
    class="d-flex"
    *ngIf="accessRules | exec : isRestrictedButDoesNotHaveAccess"
    [ngClass]="{ 'text-gray-icon': isTextGrayIcon }"
  >
    <img
      src="/assets/icons/access-lock.svg"
      [style.width.px]="iconSize"
      [style.height.px]="iconSize"
    />
  </div>
</ng-container>
