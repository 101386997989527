import { Injectable, inject } from '@angular/core';
import { authBundles } from './bundles';
import { authSelectors } from './selectors';
import { NOT_SET } from '../constants';
import { IUser, UserRole } from 'types';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom, zip } from 'rxjs';
import { Auth, user, getIdToken, getIdTokenResult } from '@angular/fire/auth';
import {
  ENVIRONMENT,
  Environment,
  HRIS_INTEGRATION_TYPE,
  ICorporateInfo,
  IHrisIntegration,
  getPageRestrictionsPerCompanyId,
  getPageRoleRestrictionsForCompanyId,
} from 'common-module';
import { isUserFirstAndLastNameInputRequired } from 'shared-utils';
import { Connect, connectBundles, connectSelectors } from 'ngrx-action-bundles';

@Injectable({
  providedIn: 'root',
})
export class AuthModel {
  connect = inject(Connect);
  environment = inject<Environment>(ENVIRONMENT);
  actions = connectBundles(authBundles);
  selectors = connectSelectors(authSelectors);
  auth = inject(Auth);
  firebaseUser$ = user(this.auth);
  idToken$ = this.firebaseUser$.pipe(switchMap((user) => (user ? getIdToken(user) : [null])));
  idTokenResult$ = this.firebaseUser$.pipe(switchMap((user) => (user ? getIdTokenResult(user) : [null])));

  isAuthenticating$ = this.selectors.user$.pipe(map((val) => val === NOT_SET));
  user$ = this.selectors.user$.pipe(filter((val): val is null | IUser => val !== NOT_SET));
  mixpanelEnabled$ = this.selectors.enabledMixpanel$;
  googleAnalyticsEnabled$ = this.selectors.enabledGoogleAnalytics$;
  authenticatedUser$ = this.user$.pipe(filter(Boolean));
  corporateInfo$ = this.selectors.corporateInfo$.pipe(filter((a): a is ICorporateInfo | null => !!a));
  userProfileImage$ = this.selectors.profileImage$.pipe(
    map((profileImage) => (profileImage ? `${profileImage}?timestamp=${Date.now()}` : null))
  );
  isLoggedIn$ = this.user$.pipe(map((user) => !!user));
  isUserAdmin$ = this.user$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? true : u?.role === UserRole.ADMIN))
  );

  isUserOfficeAdmin$ = this.user$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.OFFICE_ADMIN))
  );

  isUserManager$ = this.user$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.MANAGER))
  );

  // Note: Email should ne considered verified for accounts that are using SSO authentication
  // only accounts using local auth (email login) should confirm their emails
  isUserEmailVerified$ = this.firebaseUser$.pipe(
    map((user) => {
      const samlProviderData = user?.providerData.find((i) => i?.providerId.includes('saml'));
      const microsoftProviderData = user?.providerData.find((i) => i?.providerId.includes('microsoft.com'));
      const googleProviderData = user?.providerData.find((i) => i?.providerId.includes('google.com'));
      return !!samlProviderData || !!microsoftProviderData || !!googleProviderData || !!user?.emailVerified;
    }),
    shareReplay(1)
  );
  isUserProfilePictureRequired$ = this.user$.pipe(map((user) => user?.profileImage === null));
  isUserFirstAndLastNameInputRequired$ = this.user$.pipe(map(isUserFirstAndLastNameInputRequired));
  isRequiredToVisitSetupWizard$ = zip(this.isUserFirstAndLastNameInputRequired$, this.isUserProfilePictureRequired$).pipe(
    map((data) => data.includes(true))
  );

  userRoleCompanyPageRestrictions$ = this.user$.pipe(
    filter(Boolean),
    distinctUntilChanged((a, b) => a?.id === b?.id),
    map((user) => {
      const roleRestrictions = getPageRoleRestrictionsForCompanyId(this.environment, user.companyId);

      const hasRoleConfigured = roleRestrictions && 'roles' in roleRestrictions;
      const hasEmailConfigured = roleRestrictions && 'email' in roleRestrictions;

      const isRoleRestricted = !!(hasRoleConfigured && roleRestrictions.roles!.includes(user.role));
      const isEmailRestricted = !!(hasEmailConfigured && roleRestrictions.email === user.email);

      const isRestricted =
        hasRoleConfigured && hasEmailConfigured
          ? isRoleRestricted && isEmailRestricted
          : hasRoleConfigured
          ? isRoleRestricted
          : hasEmailConfigured
          ? isEmailRestricted
          : false;

      const userRestrictions = isRestricted
        ? { admin: roleRestrictions?.admin || null, client: roleRestrictions?.client || null }
        : { admin: null, client: null };

      const pageRestrictions = getPageRestrictionsPerCompanyId(this.environment, user.companyId);
      if (pageRestrictions) {
        const combinedAdminRestrictions = userRestrictions?.admin?.length
          ? userRestrictions.admin.concat(pageRestrictions.admin || [])
          : pageRestrictions.admin;
        const combinedClientRestrictions = userRestrictions?.client?.length
          ? userRestrictions.client.concat(pageRestrictions.client || [])
          : pageRestrictions.client;
        return {
          admin: combinedAdminRestrictions?.length ? combinedAdminRestrictions : null,
          client: combinedClientRestrictions?.length ? combinedClientRestrictions : null,
        };
      } else {
        return userRestrictions;
      }
    })
  );

  userRoleCompanyPageRestrictionsForAdmin$ = this.userRoleCompanyPageRestrictions$.pipe(map((v) => v.admin || null));
  userRoleCompanyPageRestrictionsForClient$ = this.userRoleCompanyPageRestrictions$.pipe(map((v) => v.client || null));

  hasPlanningAccess$ = this.selectors.corporateInfo$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([corporateInfo, isDeskbirdAdmin]) => (isDeskbirdAdmin ? true : !!corporateInfo?.allowsScheduling))
  );

  hasBookingAccess$ = this.selectors.corporateInfo$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([corporateInfo, isDeskbirdAdmin]) => (isDeskbirdAdmin ? true : !!corporateInfo?.allowsResourceBooking))
  );

  isUserGroupManager$ = this.user$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.GROUP_MANAGER))
  );

  isUserRegularUser$ = this.user$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.USER))
  );

  isUserGuest$ = this.user$.pipe(
    withLatestFrom(this.selectors.isDeskbirdAdmin$),
    map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.GUEST))
  );

  hrisIntegration$ = this.corporateInfo$.pipe(
    filter(Boolean),
    distinctUntilChanged((a, b) => a?.id === b?.id),
    map((corporateInfo: ICorporateInfo) =>
      corporateInfo.integrations?.length && corporateInfo.integrations.some((x) => x.type === HRIS_INTEGRATION_TYPE)
        ? corporateInfo.integrations.find((x): x is IHrisIntegration => x.type === HRIS_INTEGRATION_TYPE)!
        : null
    )
  );
}
