import * as configcat from "configcat-js";

export const FEATURE_FLAG_STORE_NAME = "feature-flag";
export const FEATURE_FLAG_DEFAULT_VALUE = false;

export const CONFIG_CAT_PROXY = "configcat-proxy/webapp";

/** The ConfigCat SDK downloads the latest setting values if they are not present or expired in the cache */
export const CONFIG_CAT_POLLING_MODE = configcat.PollingMode.LazyLoad;
/** 5 minutes */
export const CONFIG_CAT_CACHE_INTERVAL = 300;
export const CONFIG_CAT_CACHE_INTERVAL_FOR_DEVELOPMENT = 3;
