import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { filter, race, take, withLatestFrom } from 'rxjs';
import { UploadModel } from '../+store/model';
import { RequestMethod } from '../enums/request-method';
export interface IUploadEvent {
  files: File[];
  uuid: string;
}
@Component({
  selector: 'db-upload-button',
  templateUrl: './upload-button.component.html',
})
export class UploadButtonComponent {
  static buttonCounter = 0;
  // FIXME: set type as ElementRef<HTMLInputElement> when parent components will be adjusted
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input() accept: string[] = [];
  @Input() disabled = false;
  @Input() uuid?: string;
  @Input() location!: string;
  @Input() isFullHeight = false;
  @Input() requestMethod: RequestMethod.PUT | RequestMethod.POST | RequestMethod.PATCH | undefined = RequestMethod.POST;
  @Output() uploadResult = new EventEmitter<{ success: boolean; body: any }>();
  @Output() upload = new EventEmitter<IUploadEvent>();

  @HostBinding('class.is-uploading') isUploading = false;

  constructor(private uploadModel: UploadModel) {
    this.uuid = `upload-button-${++UploadButtonComponent.buttonCounter}`;
  }

  selectFile(event?: MouseEvent): void {
    event?.preventDefault();
    this.fileInput.nativeElement.click();
  }

  uploadFile(): void {
    const files = this.fileInput.nativeElement.files;
    if (!this.location) {
      if (this.upload.observed) {
        this.upload.emit({ files, uuid: this.uuid! });
        return;
      }
      console.error(`You need to provide location for upload ${this.uuid}!`);
      return;
    }
    if (this.isUploading) {
      return;
    }

    const uuid = this.uuid!;
    const location = this.location;
    if (files.length === 0) {
      return;
    }
    this.isUploading = true;
    this.uploadModel.actions.dispatch.upload({
      uuid,
      files: [...files],
      location,
      requestMethod: this.requestMethod,
      formDataName: '',
    });

    race(
      this.uploadModel.actions.listen.uploadSuccess$.pipe(
        filter((u) => u.uuid === uuid),
        withLatestFrom([true])
      ),
      this.uploadModel.actions.listen.uploadFailure$.pipe(
        filter((u) => u.uuid === uuid),
        withLatestFrom([false])
      )
    )
      .pipe(take(1))
      .subscribe(([{ body }, success]) => {
        this.isUploading = false;
        this.fileInput.nativeElement.value = '';
        this.uploadResult.emit({ success: success, body: body || null });
      });
  }
}
