<db-dialog-container [width]="'550px'">
  <p-card class="ui-modal">
    <ng-template pTemplate="header">
      <h3>{{ titleText }}</h3>
    </ng-template>

    <form [formGroup]="form" (ngSubmit)="formSubmitHandler()">
      <div class="field-radiobutton">
        <p-radioButton
          name="exportType"
          [value]="exportRangeType.ALL"
          formControlName="exportType"
          [inputId]="'exportType1Option'"
          (ngModelChange)="toggleDateRangeActivity()"
        ></p-radioButton>
        <label
          for="exportType1Option"
        >
          {{ getExportAllLabel() }}
        </label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          name="exportType"
          [value]="exportRangeType.TIMEFRAME"
          formControlName="exportType"
          [inputId]="'exportType2Option'"
          (ngModelChange)="toggleDateRangeActivity()"
        ></p-radioButton>
        <label
          for="exportType2Option"
          i18n="@@export-module|export-dialog|export-time-frame"
        >
          Export {{ exportEntityText }} from the following date range
        </label>
      </div>

      <div class="d-flex justify-content-center">
        <div class="start-date">
          <db-date-picker-input
            formControlName="startDate"
            label="Start Date"
            i18n-label="@@common|start-date"
            [errorListDictionary]="datesErrorDictionary"
          >
          </db-date-picker-input>
        </div>
        <div class="end-date">
          <db-date-picker-input
            formControlName="endDate"
            label="End Date"
            i18n-label="@@common|end-date"
            [errorListDictionary]="datesErrorDictionary"
          >
          </db-date-picker-input>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-32">
        <db-button
          width="full-width"
          class="flex-1"
          buttonType="outlined"
          (click)="cancelHandler()"
        >
          <ng-container i18n="@@common|cancel">Cancel</ng-container>
        </db-button>
        <db-button
          width="full-width"
          class="ml-16 flex-1"
          [isSubmit]="true"
          [disabled]="form.invalid"
        >
          <ng-container i18n="@@export-module|export-dialog|export-excel-file">
            Export excel file
          </ng-container>
        </db-button>
      </div>
    </form>
  </p-card>
</db-dialog-container>
