import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeZoneAbbreviation',
  standalone: true,
})
export class TimeZoneAbbreviationPipe implements PipeTransform {
  private readonly intlLocale = inject(LOCALE_ID);

  transform(value: number | string, timeZone: string): string {
    const userOffsetMinutes = new Date().getTimezoneOffset() * -1;
    const officeOffsetMinutes = getTimeZoneMinutesOffset(this.intlLocale, value, timeZone);

    if (userOffsetMinutes != officeOffsetMinutes) {
      return moment().tz(timeZone).zoneAbbr();
    }

    return '';
  }
}

function getTimeZoneMinutesOffset(intlLocale: string, currentTimestamp: number | string, timeZone: string) {
  const val = moment(currentTimestamp).locale(intlLocale);
  const tzVal = timeZone ? val.tz(timeZone) : val;
  return tzVal.utcOffset();
}
