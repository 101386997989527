import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root',
})
export class LoginDeactivate<T> implements CanDeactivate<T> {
  authModel = inject(AuthModel);

  canDeactivate(
    _component: T,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot
  ): boolean {
    this.authModel.actions.dispatch.setProfileImageCleanup();
    this.authModel.actions.dispatch.checkEmailCleanup();
    this.authModel.actions.dispatch.setAuthResultCleanup();
    return true;
  }
}
