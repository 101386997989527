import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'db-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  standalone: true,
  imports: [ProgressSpinnerModule, NgClass],
})
export class ProgressSpinnerComponent {
  @Input() styleClass: string = '';
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() diameter: string = '18px';
  @Input() strokeWidth: string = '8';
  @Input() fill: string = 'none';
  @Input() animationDuration: string = '2s';
  @Input() color = '#8b5cf6';
  @HostBinding('class.table-action-spinner')
  @Input()
  isTableAction = false;
}
