import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'db-switch',
  standalone: true,
  imports: [CommonModule, InputSwitchModule, CheckboxModule, FormsModule],
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SwitchComponent,
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() labelTrue = '';
  @Input() labelFalse = '';
  @Input() inputId: string = '';
  @Input() description: string | null = null;
  @Input() dataTestId: string | null = null;
  @Input() value = false;
  @Input() disabled = false;

  @Output() valueChanged = new EventEmitter<boolean>();

  private onChange!: (value: boolean) => unknown;
  private onTouch!: () => unknown;

  constructor(private cd: ChangeDetectorRef) {}

  registerOnChange(fn: (value: boolean) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.value = value;
    this.cd.markForCheck();
  }

  valueChangedHandler(event: { checked: boolean }): void {
    this.value = event.checked;
    this.valueChanged.emit(event.checked);
    if (this.onChange) {
      this.onChange(event.checked);
    }
  }
}
