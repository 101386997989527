import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { isElementInViewport } from '../utils';
import { InViewPortItem, ViewportContainerDirective } from './viewport-container.directive';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Directive({
  selector: '[dbInViewport]',
  standalone: true
})
export class InViewportDirective implements InViewPortItem, OnInit, OnChanges, OnDestroy {

  private readonly viewportContainer = inject(ViewportContainerDirective);
  private __isInitialized = false;

  // Check view port container directive if you wonder what would be the case of using this
  // but in general if ignore is set we assume that the element is visible
  _ignored = false;
  set ignore(isIgnored: boolean) {
    if (isIgnored) {
      this.isViewportLoading = false;
      this.isViewportReady = true;
      this.isIntersecting = true;
      this.isNotIntersecting = false;
      this.isFullyVisible = true;
      this.isPartiallyVisible = false;
      this.isNotVisible = false;
      this.visibility = 'visible';

      this.visibilityLevel5 = true;
      this.visibilityLevel4 = false;
      this.visibilityLevel3 = false;
      this.visibilityLevel2 = false;
      this.visibilityLevel1 = false;
      this.visibilityLevel0 = false;
    } else if (this._ignored) {
      this.viewportContainer.recalculateIntersections(true);
    }

    this._ignored = isIgnored;
  }
  get ignore() {
    return this._ignored;
  }

  elementRef = inject(ElementRef);
  changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('class.db-in-viewport') readonly hasViewportDirective = true;
  @HostBinding('class.db-in-viewport--loading') isViewportLoading = true;
  @HostBinding('class.db-in-viewport--ready') isViewportReady = false;
  @HostBinding('class.db-in-viewport--intersecting') isIntersecting = false;
  @HostBinding('class.db-in-viewport--not-intersecting') isNotIntersecting = false;
  @HostBinding('class.db-in-viewport--is-fully-visible') isFullyVisible = false;
  @HostBinding('class.db-in-viewport--is-partially-visible') isPartiallyVisible = false;
  @HostBinding('class.db-in-viewport--is-not-visible') isNotVisible = false;
  @HostBinding('style.visibility') visibility = 'hidden';

  @HostBinding('class.db-in-viewport--visibility-level-5') visibilityLevel5 = false;
  @HostBinding('class.db-in-viewport--visibility-level-4') visibilityLevel4 = false;
  @HostBinding('class.db-in-viewport--visibility-level-3') visibilityLevel3 = false;
  @HostBinding('class.db-in-viewport--visibility-level-2') visibilityLevel2 = false;
  @HostBinding('class.db-in-viewport--visibility-level-1') visibilityLevel1 = false;
  @HostBinding('class.db-in-viewport--visibility-level-0') visibilityLevel0 = false;

  @Input() scrollContainer: HTMLElement | null = null;
  @Input() intersectionContainer: HTMLElement | null = null;

  private visibilityLevel = null;
  private isVisible$$ = new BehaviorSubject<boolean>(this.isIntersecting);
  isVisible$ = this.isVisible$$.asObservable().pipe(distinctUntilChanged());
  resizeObserver: ResizeObserver;

  set initialized(isInitialized: boolean) {
    this.isViewportLoading = !isInitialized;
    this.isViewportReady = isInitialized;
    this.__isInitialized = isInitialized;
  }

  get initialized(): boolean {
    return this.__isInitialized;
  }

  constructor() {
    this.resizeObserver = new ResizeObserver(() => {
      this.viewportContainer.recalculateIntersections(true);
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnInit(): void {
    this.viewportContainer.registerViewPortItem(this);
  }

  ngOnChanges(): void {
    this.viewportContainer.registerViewPortItem(this);
  }

  setIntersectionResult(intersectionResult: ReturnType<typeof isElementInViewport>) {
    if (
      this.isIntersecting === intersectionResult.isIntersecting &&
      this.isFullyVisible === intersectionResult.isFullyVisible &&
      this.isPartiallyVisible === intersectionResult.isPartiallyVisible &&
      this.visibilityLevel === intersectionResult.visibilityLevel
    ) { return; }

    if (!this.initialized) {
      this.initialized = true;
      this.visibility = 'visible';
    }

    this.isIntersecting = intersectionResult.isIntersecting;
    this.isNotIntersecting = !intersectionResult.isIntersecting;
    this.isFullyVisible = intersectionResult.isFullyVisible;
    this.isPartiallyVisible = intersectionResult.isPartiallyVisible;
    this.isNotVisible = !this.isFullyVisible && !this.isPartiallyVisible;
    this.visibilityLevel5 = intersectionResult.visibilityLevel === 5;
    this.visibilityLevel4 = intersectionResult.visibilityLevel === 4;
    this.visibilityLevel3 = intersectionResult.visibilityLevel === 3;
    this.visibilityLevel2 = intersectionResult.visibilityLevel === 2;
    this.visibilityLevel1 = intersectionResult.visibilityLevel === 1;
    this.visibilityLevel0 = intersectionResult.visibilityLevel === 0;

    this.isVisible$$.next(!this.isNotVisible);
    this.changeDetectorRef.detectChanges();
  };

  ngOnDestroy(): void {
    this.viewportContainer.unregisterViewPortItem(this);
    this.resizeObserver.disconnect();
  }
}
