import { InjectionToken } from '@angular/core';
import { ICustomerIoConfig } from '../interfaces/customer-io-config';
import { Environment } from '../enums';

export const WINDOW = new InjectionToken<Window>('WINDOW');
export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT');
export const API_URL = new InjectionToken<string>('API_URL');
export const PUBLIC_API_URL = new InjectionToken<string>('PUBLIC_API_URL');
export const BACKEND_SLACK_LOGIN_URL = new InjectionToken<string>('BACKEND_SLACK_LOGIN_URL');
export const APP_URL = new InjectionToken<string>('APP_URL');
export const ADMIN_PART_PREFIX = new InjectionToken<string>('ADMIN_PART_PREFIX');
export const CLIENT_PART_PREFIX = new InjectionToken<string>('CLIENT_PART_PREFIX');
export const CUSTOMER_IO_CONFIG = new InjectionToken<ICustomerIoConfig>('CUSTOMER_IO_CONFIG');
