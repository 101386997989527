import { Component, inject } from '@angular/core';
import { AuthModel } from '../+store/model';
import { filter, race, switchMap, take, tap, map } from 'rxjs';
import { NgForm } from '@angular/forms';
import { RouterModel } from 'router-module';
import { GlobalLoaderModel } from 'loader-module';
import { LoadingTenseState } from 'shared';
import { LoginType } from 'types';
import { LoginActionPayload } from '../+store/bundles';

@Component({
  selector: 'app-sign-in-email',
  templateUrl: './sign-in-email.component.html',
  styleUrls: ['./sign-in-email.component.scss'],
})
export class SignInEmailComponent {
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);
  globalLoaderModel = inject(GlobalLoaderModel);

  visibility: 'text' | 'password' = 'password';
  isLoading = false;
  LoadingTenseState = LoadingTenseState;
  emailCheckResult$ = this.authModel.selectors.emailCheckResult$.pipe(filter((d) => !!d));

  toggleVisibility(): void {
    this.visibility = this.visibility === 'text' ? 'password' : 'text';
  }

  onEmailSignIn(form: NgForm): void {
    if (form.invalid) return;
    this.isLoading = true;
    const { password, email } = form.value as { email: string; password: string };
    if (!password || !email) return;

    const loginPayload: LoginActionPayload = {
      email,
      password,
      loginType: LoginType.Email,
    };

    this.authModel.actions.dispatch.login(loginPayload as any); // TODO: @iliya remove any when issue is found

    race(
      this.authModel.actions.listen.loginSuccess$.pipe(map(() => true)),
      this.authModel.actions.listen.loginFailure$.pipe(map(() => false))
    )
      .pipe(take(1))
      .subscribe((success) => {
        this.isLoading = false;
        if (!success) return;
        this.authModel.actions.dispatch.checkEmailCleanup();
        this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
      });
  }

  forgotPassword(): void {
    this.emailCheckResult$
      .pipe(
        take(1),
        tap(() => {
          this.globalLoaderModel.actions.dispatch.showLoader({ visibility: true });
        }),
        switchMap((emailResult) => {
          this.authModel.actions.dispatch.passwordReset({ email: emailResult?.email || '' });

          return race(
            this.authModel.actions.listen.passwordResetSuccess$.pipe(map(() => true)),
            this.authModel.actions.listen.passwordResetFailure$.pipe(map(() => false))
          ).pipe(take(1));
        })
      )
      .subscribe(() => {
        this.globalLoaderModel.actions.dispatch.showLoader({ visibility: false });
      });
  }
}
