<ng-template
  #missingTemplate
  i18n="@@confirmation-module|confirmation|template-missing"
>
  Template missing!
</ng-template>

<ng-template #standardConfirmationHeaderTemplate let-content="content">
  <h3 class="text-black">{{ content || '' }}</h3>
</ng-template>

<ng-template
  #standardConfirmationBodyTemplate
  let-content="content"
  let-infoText="infoText"
>
  <div class="content small-text">{{ content || '' }}</div>
  <div *ngIf="infoText" class="info-text-box">
    <db-info-message [text]="infoText"></db-info-message>
  </div>
</ng-template>

<ng-container *ngFor="let confirmationConfig of confirmationConfigs$ | async">
  <db-dialog-container [width]="confirmationConfig.width || '600px'">
    <db-dialog-card>
      <ng-container
        uiCardHeader
        *ngTemplateOutlet="
          (confirmationConfig.isGeneric
            ? standardConfirmationHeaderTemplate
            : (
                activeDialogs
                | exec
                  : __getConfirmationInstanceByName
                  : confirmationConfig.name
              ).header) || missingTemplate;
          context: {
            $implicit: confirmationConfig.data,
            instance: this,
            content: confirmationConfig?.headerText || ''
          }
        "
      >
      </ng-container>
      <ng-container
        uiCardBody
        *ngTemplateOutlet="
          (confirmationConfig.isGeneric
            ? standardConfirmationBodyTemplate
            : (
                activeDialogs
                | exec
                  : __getConfirmationInstanceByName
                  : confirmationConfig.name
              ).body) || missingTemplate;
          context: {
            $implicit: confirmationConfig.data,
            instance: this,
            content: confirmationConfig?.bodyText || '',
            infoText: confirmationConfig?.infoText || ''
          }
        "
      >
      </ng-container>
      <ng-container uiCardFooter>
        <ng-container *ngIf="!confirmationConfig.hideActions">
          <div class="actions d-flex">
            <db-button
              *ngIf="!confirmationConfig.hideCancel"
              class="flex-1"
              [width]="'full-width'"
              [buttonType]="'outlined'"
              [buttonType]="'outlined'"
              [disabled]="!!(disableCancel$$ | async)"
              (click)="cancelClickHandler(confirmationConfig)"
            >
              {{
                confirmationConfig.cancelButtonLabel || cancelLabelDefault
              }}</db-button
            >
            <db-button
              *ngIf="!confirmationConfig.hideConfirm"
              class="flex-1 ml-16"
              [width]="'full-width'"
              [disabled]="!!(disableConfirm$$ | async)"
              (click)="confirmClickHandler(confirmationConfig)"
            >
              {{
                confirmationConfig.confirmButtonLabel ||
                  confirmationLabelDefault
              }}
            </db-button>
          </div>
        </ng-container>
      </ng-container>
    </db-dialog-card>
  </db-dialog-container>
</ng-container>
