import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DEFAULT_TIMEZONE } from 'shared-constants';
import { FormatDateTimePipe, GetTimeFormatStringForUser, TimeZoneAbbreviationPipe } from 'shared-pipes';
import { CommonModule } from '@angular/common';
import { TimeFormat } from 'types';

@Component({
  selector: 'db-booking-start-end-time',
  templateUrl: './booking-start-end-time.component.html',
  standalone: true,
  imports: [CommonModule, FormatDateTimePipe, GetTimeFormatStringForUser, TimeZoneAbbreviationPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingStartEndTimeComponent {
  @Input() hourFormat: TimeFormat | undefined;
  @Input() isFullDay = false;
  @Input() startTime: string | number = '';
  @Input() endTime: string | number = '';
  @Input() timeZone: string | undefined = DEFAULT_TIMEZONE;
}
