import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { ButtonIconComponent } from 'db-ui';
import { HiddenDirective, ProgressBarComponent } from 'shared';

@Component({
  selector: 'db-slide-over-frame',
  templateUrl: './slide-over-frame.component.html',
  styleUrls: ['./slide-over-frame.component.scss'],
  standalone: true,
  imports: [
    ProgressBarComponent,
    CommonModule,
    HiddenDirective,
    ButtonIconComponent
  ]
})
export class SlideOverFrameComponent {
  // NOTE: prevent default behavior for html title attribute
  @HostBinding('attr.title') get getTitle(): null {
    return null;
  }

  @Input() showProgressLoader = false;
  @Input() title!: string;
  @Input() titleTemplate!: TemplateRef<void>;
  @Input() headerTemplate!: TemplateRef<void>;
  @Input() icon = 'svg-close-v2';
  @Input() headerBackgroundColor: 'white' | 'magnolia' | 'unset' = 'unset';
  @Input() contentBackgroundColor: 'white' | 'magnolia' | 'unset' = 'unset';
  @Input() headerStyle = {};

  @Output() closeAction = new EventEmitter<void>();

  constructor() {}
}
