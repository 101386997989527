import { Component, HostBinding, inject, Input, TemplateRef } from '@angular/core';
import { RouterModel } from 'router-module';
import { map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { GtmEvent, PageFrameComponent } from 'shared';
import { OfficeSwitcherComponent } from '../office-switcher';

@Component({
  selector: 'db-office-switcher-page-frame',
  templateUrl: './office-switcher-page-frame.component.html',
  styleUrls: ['./office-switcher-page-frame.component.scss'],
  standalone: true,
  imports: [CommonModule, OfficeSwitcherComponent, PageFrameComponent],
})
export class OfficeSwitcherPageFrameComponent {
  private readonly routerModel = inject(RouterModel);
  // NOTE: prevent default behavior for html title attribute
  @HostBinding('attr.title') get getTitle(): null {
    return null;
  }
  @Input() title!: string;
  @Input() headerTemplate!: TemplateRef<any>;
  @Input() showProgressLoader = false;

  readonly officeChangeGtmEventName: keyof GtmEvent = 'filter_office';
  readonly noOfficeId$ = this.routerModel.allRouteParams$.pipe(map((params) => !params['officeId']));
  readonly isClientAppEnv$ = this.routerModel.isClientAppEnv$;
}
