import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getAbbreviation } from 'shared-utils';
import { AvatarModule } from 'primeng/avatar';
import { ExecPipe } from 'shared-pipes';
import { BadgeModule } from 'primeng/badge';
import { ChipComponent } from '../chip';
import { ChipSize } from 'shared-types';

@Component({
  selector: 'db-avatar-chip',
  standalone: true,
  imports: [CommonModule, ChipComponent, AvatarModule, ExecPipe, BadgeModule],
  templateUrl: './avatar-chip.component.html',
  styleUrls: ['./avatar-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarChipComponent implements OnInit {
  @ViewChild(ChipComponent, { read: ChipComponent, static: true }) chip!: ChipComponent;

  @Input() label: string | undefined;
  @Input() removable: boolean | undefined = false;
  @Input() removeIcon = 'pi pi-times-circle';
  @Input() contentStyles: { [klass: string]: any } | null | undefined;
  @Input() avatarImgSrc: string | undefined;

  @Input() avatarColor: string | undefined = '#A5A5A5';
  @Input() showBadge: boolean | undefined = false;
  @Input() badgeLabel = '';
  @Input() size: ChipSize = 'large';

  @Output() onRemove: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() onImageError: EventEmitter<Event> = new EventEmitter<Event>();

  protected readonly getAbbreviation = getAbbreviation;

  ngOnInit(): void {
    this.chip.onRemove.subscribe(this.onRemove);
    this.chip.onImageError.subscribe(this.onImageError);
  }
}
