import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ROUTER_STORE_NAME } from './constants';
import { FullRouterState } from './state-serializer';

export const getRouterReducerState = createFeatureSelector<RouterReducerState<FullRouterState>>(ROUTER_STORE_NAME);

export const getRouterState = createSelector(getRouterReducerState, state => state ? state.state : {} as FullRouterState);

export const getUrl = (routerState: FullRouterState) => (routerState && routerState.url) || '';
export const getPath = (routerState: FullRouterState) => (routerState && routerState.path) || '';
export const getQueryParams = (routerState: FullRouterState) => (routerState && routerState.queryParams) || {};
export const getHierarchyData = (routerState: FullRouterState) => (routerState && routerState.hierarchyData) || {};
export const getHierarchyRouteParams = (routerState: FullRouterState) => (routerState && routerState.hierarchyRouteParams) || {};
export const getOutlets = (routerState: FullRouterState) => (routerState && routerState.outlets) || { primary: [] } as { [outletName: string]: string[]; primary: string[]; };
export const getPathWithoutAuxiliaries = (routerState: FullRouterState) => (routerState && routerState.pathWithoutAuxiliaries) || '';

export const getPreviousUrl = (routerState: FullRouterState) => (routerState && routerState.previousUrl) || '';
export const getPreviousPath = (routerState: FullRouterState) => (routerState && routerState.previousPath) || '';
export const getPreviousQueryParams = (routerState: FullRouterState) => (routerState && routerState.previousQueryParams) || {};
export const getPreviousHierarchyData = (routerState: FullRouterState) => (routerState && routerState.previousHierarchyData) || {};
export const getPreviousHierarchyRouteParams = (routerState: FullRouterState) => (routerState && routerState.previousHierarchyRouteParams) || {};
export const getPreviousOutlets = (routerState: FullRouterState) => (routerState && routerState.previousOutlets) || { primary: [] } as { [outletName: string]: string[]; primary: string[]; };
export const getPreviousPathWithoutAuxiliaries = (routerState: FullRouterState) => (routerState && routerState.previousPathWithoutAuxiliaries) || {};

export const routerSelectors = {
  url: createSelector(getRouterState, getUrl),
  path: createSelector(getRouterState, getPath),
  queryParams: createSelector(getRouterState, getQueryParams),
  hierarchyData: createSelector(getRouterState, getHierarchyData),
  hierarchyRouteParams: createSelector(getRouterState, getHierarchyRouteParams),
  outlets: createSelector(getRouterState, getOutlets),
  pathWithoutAuxiliaries: createSelector(getRouterState, getPathWithoutAuxiliaries),

  previousUrl: createSelector(getRouterState, getPreviousUrl),
  previousPath: createSelector(getRouterState, getPreviousPath),
  previousQueryParams: createSelector(getRouterState, getPreviousQueryParams),
  previousHierarchyData: createSelector(getRouterState, getPreviousHierarchyData),
  previousHierarchyRouteParams: createSelector(getRouterState, getPreviousHierarchyRouteParams),
  previousOutlets: createSelector(getRouterState, getPreviousOutlets),
  previousPathWithoutAuxiliaries: createSelector(getRouterState, getPreviousPathWithoutAuxiliaries)
};
