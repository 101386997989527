<ng-container [ngSwitch]="skeletonType">
  <ng-container *ngSwitchCase="SkeletonLoaderType.NONE"></ng-container>
  <ng-container *ngSwitchCase="SkeletonLoaderType.DEFAULT">
    <p-skeleton
      [styleClass]="skeletonClass"
      [borderRadius]="borderRadius"
      [width]="skeletonWidth"
      [height]="skeletonHeight"
    ></p-skeleton>
  </ng-container>

  <ng-container *ngSwitchCase="SkeletonLoaderType.DEFAULT_TWO_LINES">
    <div class="skeleton-group-column">
      <p-skeleton
        [borderRadius]="borderRadius"
        width="10rem"
        styleClass="mb-4"
      ></p-skeleton>
      <p-skeleton [borderRadius]="borderRadius" width="10rem"></p-skeleton>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SkeletonLoaderType.CIRCLE">
    <p-skeleton shape="circle" [size]="skeletonHeight"></p-skeleton>
  </ng-container>

  <ng-container *ngSwitchCase="SkeletonLoaderType.TWO_CIRCLES">
    <div class="d-flex">
      <p-skeleton [style]="{ marginRight: '27px' }" shape="circle" [size]="skeletonHeight"></p-skeleton>
      <p-skeleton shape="circle" [size]="skeletonHeight"></p-skeleton>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SkeletonLoaderType.CIRCLE_AND_DEFAULT">
    <div class="skeleton-group-row">
      <p-skeleton shape="circle" size="2rem" styleClass="mr-8"></p-skeleton>
      <div class="flex-1">
        <p-skeleton [borderRadius]="borderRadius"></p-skeleton>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SkeletonLoaderType.CIRCLE_AND_DEFAULT_TWO_LINES">
    <div class="skeleton-group-row">
      <p-skeleton shape="circle" size="2rem" styleClass="mr-8"></p-skeleton>
      <div class="skeleton-group-column">
        <p-skeleton
          [borderRadius]="borderRadius"
          width="10rem"
          styleClass="mb-4"
        ></p-skeleton>
        <p-skeleton [borderRadius]="borderRadius" width="10rem"></p-skeleton>
      </div>
    </div>
  </ng-container>

  <p-skeleton *ngSwitchDefault></p-skeleton>
</ng-container>
