import { Provider } from '@angular/core';
import { AUTO_LOGIN_CREDENTIALS } from './injection-tokens';
import * as creds from './auto-login-credentials.json';

export function autoLoginCredentialsProviderFactory(credentials?: { email: string; password: string }): Provider {
  const useValue = credentials || { ...creds };
  console.warn(`Auto logging you in as: ${useValue.email}! To change the user go to auth-module -> auto-login-credentials.json.`);

  return {
    provide: AUTO_LOGIN_CREDENTIALS,
    useValue,
  };
}
