<div
  (click)="canOpenProfile ? openProfileHandler($event) : null"
  [class]="avatarSize"
  [ngClass]="{ 'hover-style': canOpenProfile }"
  [pTooltip]="showTooltips ? avatarTooltip : ''"
  tooltipPosition="top"
>
  <div
    class="avatar"
    *ngIf="
      imageUrl;
      else initials
    "
    [ngStyle]="{ background: 'url(' + imageUrl + ')' }"
  >
    <div
      class="hover-overlay"
      [ngClass]="{ 'hover-disabled': !canOpenProfile }"
      data-testId="db-ui--user-avatar"
    ></div>
  </div>

  <ng-template #initials>
    <div
      class="initials"
      [ngStyle]="{
        background: userInfo?.avatarColor || userInfo?.color || '#A5A5A5'
      }"
    >
      {{
        name || userInfo?.firstName + ' ' + userInfo?.lastName
          | exec : getAbbreviation
      }}
      <div
        class="hover-overlay"
        [ngClass]="{ 'hover-disabled': !canOpenProfile }"
        data-testId="db-ui--user-avatar"
      ></div>
    </div>
  </ng-template>

  <div
    class="favorite-star"
    *ngIf="
      showFavUserIcon &&
      userInfo?.followingStatus === FollowingStatusType.FOLLOWING
    "
  >
    <img src="/assets/icons/purple-star.svg" />
  </div>
</div>
