import { DeskAreaType, IDeskArea, IOfficeEquipmentItemBasicInfo, IUserInfo } from 'types';

export interface IOfficeFloor {
  id?: string;
  type?: DeskAreaType;
  name: string;
  zones: IDeskArea[];
  isActive: boolean;
  order: number;
  workspaceId: string;
  image?: string;
  floorConfig?: string;
  floorConfigReady?: boolean;
  interactiveImage?: string;

  meetingRoomAvailable?: boolean;
  meetingRoomMessage?: string;
}

/** Interfaces for the data that is sent by mobile platforms */
/** LOCAL STORAGE INPUT */
export interface IFloorPlanInputDto {
  floorConfig: string;
  floor: IFloorPlanDto;
  authUser: IUserInfo;
  selectedDesk: IFloorPlanSelectedResourceDto;
  filters: {
    officeRoleIds?: string[];
    resourceTypes?: DeskAreaType[];
  };
}

/** SELECTED DESK */
export interface IFloorPlanSelectedResourceDto {
  id?: string | number;
  zoneId?: number;
  name?: string;
  order?: number;
  status?: 'active' | 'inactive';
  type: DeskAreaType;

  // dev
  title: string;
  active: boolean;
  position: [number, number] | null;
}

export interface IFloorPlanSelectedResourceInputDto {
  id: string;
  name: string;
  order: number;
  status: 'active' | 'inactive';
  type: DeskAreaType;

  // dev
  title: string;
  active: boolean;
  zoneId?: number;
  position: [number, number] | null;
}

/** FLOOR */
export interface IFloorPlanDto {
  id: string;
  floorConfigReady: boolean;
  interactiveImage: string;
  zones: IFloorPlanAreaDto[];
}

/** AREA a.ka. ZONE */
export interface IFloorPlanAreaDto {
  id: string;
  type: DeskAreaType;
  name: string;
  isActive: boolean;
  items: IFloorPlanZoneItemDto[];
  accessRules: {
    isBookable: boolean;
    isRestricted: boolean;
    userHasAccess: boolean;
    restrictedTime?: number;
  }; // IAccessRules;
  equipment?: IOfficeEquipmentItemBasicInfo[]; // available for meeting rooms only
}

/** RESOURCE a.k.a ZONE ITEM */
export interface IFloorPlanZoneItemDto {
  id: string;
  name: string;
  order: number;
  accessInfo: {
    type: 'DEDICATED' | 'SHARED'; // DeskAreaItemAccessType;
  };
  users: {
    id: string;
    firstName: string;
    lastName: string;
    avatarColor: string;
    avatarUrl: string;
  };
  dedicatedResourceOwner?: {
    id: string;
    firstName: string;
    lastName: string;
    avatarColor: string;
    profileImage: string;
  }; // IUserInfo;
}
/** End of Interfaces for the data that is sent by mobile platforms */
