<ng-template let-item #defaultMultiSelectChipDropdownChipRef>
  {{ item[optionLabel] }}
</ng-template>
<ng-template let-item #defaultMultiSelectChipDropdownOptionRef>
  {{ item[optionLabel] }}
</ng-template>

<db-dropdown
  class="full-width-dropdown"
  [style.width]="dropdownWidth"
  [options]="notSelectedDropdownOptions"
  [ngModel]="dropdownValue"
  [optionLabel]="$any(optionLabel)"
  [optionDisabled]="optionDisabled"
  [filter]="true"
  [filterBy]="filterBy"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [style.width]="inputWidth + 'px'"
  [autoDisplayFirst]="autoDisplayFirst"
  [dataTestId]="dropdownDataTestId"
  [resetFilterOnHide]="true"
  (onChange)="selectedItemHandler($event)"
  (onHide)="onDropdownHideHandler()"
  [templatesAsInput]="
    templates && templates.length > 0 ? templates.toArray() : null
  "
>
</db-dropdown>

<ng-template #defaultChipTemplate let-item let-i="index">
  <db-chip
    [removable]="true"
    [image]="$any(item | exec : getImageSrc)"
    [showBadge]="isNewItem(item)"
    [badgeLabel]="newBadgeLabel"
    (onRemove)="removedItemHandler(item)"
  >
    <ng-template
      [ngTemplateOutlet]="
        chipItemTemplateRef || defaultMultiSelectChipDropdownChipRef
      "
      [ngTemplateOutletContext]="{ $implicit: item }"
    >
    </ng-template>
  </db-chip>
</ng-template>

<ng-template #defaultRemovedChipTemplate let-item let-i="index">
  <db-chip
    [image]="$any(item | exec : getImageSrc)"
    [removable]="true"
    removeIcon="pi pi-undo"
    (onRemove)="undoRemovedItemHandler(item)"
    class="removed-chip"
  >
    <div class="removed-chip-content">
      <ng-template
        [ngTemplateOutlet]="
          chipItemTemplateRef || defaultMultiSelectChipDropdownChipRef
        "
        [ngTemplateOutletContext]="{ $implicit: item }"
      >
      </ng-template>
    </div>
  </db-chip>
</ng-template>

<div class="chips-wrapper">
  <ng-template ngFor [ngForOf]="selectedItems" let-item let-i="index">
    <ng-container
      *ngTemplateOutlet="
        chipTemplateRef ? chipTemplateRef : defaultChipTemplate;
        context: {
          $implicit: item,
          index: i,
          isNew: isNewItem(item),
          removedItemHandler: removedItemHandler,
          undoRemovedItemHandler: undoRemovedItemHandler,
          isRemoved: false
        }
      "
    ></ng-container>
  </ng-template>

  <ng-template ngFor [ngForOf]="removedItems" let-item let-i="index">
    <ng-container
      *ngTemplateOutlet="
        chipTemplateRef ? chipTemplateRef : defaultRemovedChipTemplate;
        context: {
          $implicit: item,
          index: i,
          isNew: isNewItem(item),
          removedItemHandler: removedItemHandler,
          undoRemovedItemHandler: undoRemovedItemHandler,
          isRemoved: true
        }
      "
    ></ng-container>
  </ng-template>
</div>
