import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { filter, race, take } from 'rxjs';
import { UploadModel } from '../+store/model';
import { IUploadEvent } from '../upload-button/upload-button.component';
import { RequestMethod } from '../enums/request-method';

@Component({
  selector: 'db-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  static counter = 0;
  isDragOver = false;
  isUploading = false;

  @Input() imageUrls: string[] = [];
  @Input() hasImage = false;
  @Input() acceptedImageFormats!: string[];
  @Input() uploadBtnName!: string;
  @Input() maxFileSizeMB = 5;
  @Input() uuid!: string;
  @Input() location!: string;
  @Input() height = '260px';
  @Input() width = 'fit-content';
  @Input() formDataName!: string;
  @Input() csrf: { token: string; headerName: string } | undefined;
  @Input() usePUTRequest = false;
  @Input() requestMethod: RequestMethod.PUT | RequestMethod.POST | RequestMethod.PATCH = RequestMethod.POST;
  @Input() showAllowedFormats = false;
  @Input() lazy = false;

  @Input() simpleDesign = false;

  @Input() tooLargeFileErrorHandler: () => void = () => alert('file too big');

  @Output() uploadResult: EventEmitter<{ success: boolean; body: any }> = new EventEmitter<{ success: boolean; body: any }>();
  @Output() upload: EventEmitter<IUploadEvent> = new EventEmitter<IUploadEvent>();

  get parsedAcceptedImageFormats(): string {
    return this.acceptedImageFormats.join(', ');
  }

  @HostListener('dragenter') onDragOver(): void {
    this.isDragOver = true;
  }

  @HostListener('dragleave') onDragLeave(): void {
    this.isDragOver = false;
  }

  constructor(private uploadModel: UploadModel) {}

  ngOnInit(): void {
    this.uuid = this.uuid || `image-uploader-${ImageUploaderComponent.counter++}`;
  }

  uploadImage({ files, uuid }: { files: File[]; uuid: string }): void {
    if (this.lazy) {
      this.imageUrls = [];
      for (const file of files) {
        const fileURL = URL.createObjectURL(file);
        this.imageUrls.push(fileURL);
      }
    }

    if (this.upload.observed) {
      this.upload.emit({ files, uuid });
      return;
    }

    if (files.length === 0) {
      return;
    }
    for (const file of files) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > this.maxFileSizeMB) {
        this.tooLargeFileErrorHandler();
        return;
      }
    }

    this.uploadModel.actions.dispatch.upload({
      uuid,
      files: [...files],
      location: this.location,
      requestMethod: this.requestMethod,
      formDataName: this.formDataName,
      csrf: this.csrf,
    });

    race(
      this.uploadModel.actions.listen.uploadSuccess$.pipe(
        filter((u) => u.uuid === uuid),
        take(1)
      ),
      this.uploadModel.actions.listen.uploadFailure$.pipe(
        filter((u) => u.uuid === uuid),
        take(1)
      )
    )
      .pipe(take(1))
      .subscribe((payload) => {
        this.isUploading = false;
        this.uploadResult.emit({ success: !(payload as any).error, body: payload.body });
      });
  }

  handleDragAndDrop(files: File[]): void {
    this.uploadImage({ files, uuid: this.uuid });
  }

  hasImages(imageUrls: string[] | undefined | null): boolean {
    return (imageUrls?.length || 0) > 0 && !!imageUrls?.every((a) => !!a);
  }
}
