import { Injectable } from '@angular/core';
import { favoriteSpacesBundles } from './bundles';
import { favoriteSpacesSelectors } from './selectors';
import { connectBundles, connectSelectors } from 'ngrx-action-bundles';

@Injectable({ providedIn: 'root' })
export class FavoriteSpacesModel {
  selectors = connectSelectors(favoriteSpacesSelectors);
  actions = connectBundles(favoriteSpacesBundles);
}
