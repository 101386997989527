import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'db-text-ellipsis',
  templateUrl: './text-ellipsis.component.html',
  standalone: true,
  imports: [NgIf, NgClass, NgTemplateOutlet, OverlayPanelModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEllipsisComponent implements AfterViewInit {
  @Input({ required: true }) text = '';
  @Input() styleClass: string = '';
  @Input() widthStyleClass: string = 'w-100'; // Ensure the element has a fixed width
  @ViewChild('textElement') textElement!: ElementRef;
  showTooltip = false;

  get textStyleClass() {
    return `truncate-text ${this.widthStyleClass} ${this.styleClass || ''}`;
  }

  @HostListener('window:resize', ['$event.target'])
  setTruncate() {
    const nativeElement = this.textElement.nativeElement as HTMLElement;
    if (nativeElement.offsetWidth < nativeElement.scrollWidth) {
      this.showTooltip = true;
    } else {
      this.showTooltip = false;
    }
  }

  @HostBinding('class')
  get hostClass() {
    return 'd-flex';
  }

  ngAfterViewInit() {
    this.setTruncate();
  }
}
