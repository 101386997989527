export function downloadFile(document: Document, downloadLocation: string, authToken: string) {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('enctype', 'multipart/form-data');
  form.setAttribute('action', downloadLocation);

  const tokenInput = document.createElement('input');
  tokenInput.setAttribute('type', 'hidden');
  tokenInput.setAttribute('name', 'token');
  tokenInput.value = `Bearer ${authToken}`;
  form.appendChild(tokenInput);

  document.body.appendChild(form);
  form.submit();
  form.remove();
}
