import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { LOADER_STORE_NAME } from './constants';
import { globalLoaderReducer } from './+store/reducer';
import { LoaderComponent } from './loader/loader.component';
import { LottiePlayerComponent } from "lottie-module";
import { CommonModule } from '@angular/common';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';
import { DotLoaderComponent } from 'db-ui';

@NgModule({
  imports: [CommonModule, DotLoaderComponent, DotLoaderComponent, LottiePlayerComponent],
  declarations: [LoaderComponent, GlobalLoaderComponent],
  exports: [LoaderComponent, GlobalLoaderComponent],
})
export class LoaderModule {}

@NgModule({
  imports: [
    LoaderModule,
    StoreModule.forFeature(LOADER_STORE_NAME, globalLoaderReducer),
  ],
  exports: [LoaderModule],
})
export class GlobalLoaderModule {
  constructor(@Optional() @SkipSelf() globalLoaderModule: GlobalLoaderModule) {
    if (globalLoaderModule) {
      throw new Error('GlobalLoaderModule should be imported only once!');
    }
  }
}
