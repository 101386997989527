import { Params } from '@angular/router';
import { MeetingRoomCalendarData, MeetingRoomCalendarEventData, IMeetingRoomKioskData, MeetingRoomWithFloor } from 'types/interfaces';
import { getEventsForMeetingRoom } from './get-events-for-meeting-room';
import { formatTime } from '../time-formatter';
import moment from 'moment';
import { TIME_FORMAT_H24 } from 'shared-constants';

export const generateTimeBlockersForDay = (
  timezone: string,
  meetingRooms: MeetingRoomWithFloor[],
  meetingRoomCalendarData: MeetingRoomCalendarData[] | null,
  routeParams: Params
) => {
  const { meetingRoomId, seriesId, virtualId, bookingId } = routeParams;

  const meetingRoomData = (meetingRooms || []).find((mr) => mr.id === meetingRoomId);
  const meetingRoomEvents = getEventsForMeetingRoom(meetingRoomData!, meetingRoomCalendarData!);
  if (!meetingRoomData) {
    return [];
  }

  const filterEventBeingEdited = (event: MeetingRoomCalendarEventData): boolean => {
    const isEventBeingEdited =
      (!!seriesId && !!virtualId && event.seriesId === seriesId && event.virtualId === virtualId) ||
      (!!bookingId && event.id === bookingId);
    return !isEventBeingEdited;
  };


return meetingRoomEvents.filter(filterEventBeingEdited).map((event) => {
  const startTimeWithBuffer = moment(event.startTime).subtract(event.bufferBeforeMinutes || 0, 'minutes').format(TIME_FORMAT_H24)
  const endTimeWithBuffer = moment(event.endTime).add(event.bufferAfterMinutes || 0, 'minutes').format(TIME_FORMAT_H24)


  const startTime = formatTime(timezone, startTimeWithBuffer)
  const endTime = formatTime(timezone, endTimeWithBuffer)
  return {startTime, endTime}
});
};

export const generateKioskTimeBlockersForDay = (kioskData: IMeetingRoomKioskData) => {
  const { events } = kioskData;

  return events.map((event) => ({
    startTime: formatTime(kioskData.meetingRoom.timezone, event.startTime),
    endTime: formatTime(kioskData.meetingRoom.timezone, event.endTime),
  }));
};
