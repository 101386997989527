import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'formatMinutes',
  standalone: true
})
export class FormatMinutesPipe implements PipeTransform {
  /**
   * Transforms a given number of minutes into a readable format.
   *
   * @param {number} minutes - The number of minutes to format.
   * @param {string} [format='short'] - Format to use - 'long' for full words (e.g. 'hours'/'minutes'), 'short' (default) for abbreviated (e.g. 'h'/'min')
   * @returns {string} - The formatted time string.
   *
   * @example
   * // Returns '1h 15 min'
   * 75 | formatMinutes
   *
   * @example
   * // Returns '1 hour 15 minutes'
   * 75 | formatMinutes:'long'
   *
   * @example
   * // Returns '1h'
   * 60 | formatMinutes
   *
   * @example
   * // Returns '15 min'
   * 15 | formatMinutes
   */
  transform(minutes: number, format: 'long' | 'short' = 'short'): string {
    const duration = moment.duration(minutes, 'minutes');
    const hours = duration.hours();
    const mins = duration.minutes();

    if (hours > 0 && mins > 0) {
      if (format === 'long') {
        const hourText = hours === 1 ? 'hour' : 'hours';
        const minText = mins === 1 ? 'minute' : 'minutes';
        return `${hours} ${hourText} ${mins} ${minText}`;
      }
      return `${hours}h ${mins} min`;
    } else if (hours > 0) {
      if (format === 'long') {
        const hourText = hours === 1 ? 'hour' : 'hours';
        return `${hours} ${hourText}`;
      }
      return `${hours}h`;
    } else {
      if (format === 'long') {
        const minText = mins === 1 ? 'minute' : 'minutes';
        return `${mins} ${minText}`;
      }
      return `${mins} min`;
    }
  }
}
