<div
  class="db-image"
  [ngClass]="{
    'has-image': imageUrls | exec : hasImages,
    'drag-over': isDragOver,
    'simple-design': simpleDesign
  }"
  [ngStyle]="{ height, width }"
>
  <div id="image-container">
    <div
      *ngFor="let imageUrl of imageUrls"
      class="image"
      [ngStyle]="{
        background:
          'no-repeat center/contain url(' + imageUrl + '), transparent'
      }"
    ></div>
  </div>
  <db-drop-area (swDrop)="handleDragAndDrop($event)">
    <div class="db-upload-photo-message">
      <div class="db-subheader-message">
        <ng-template #normalDesignTemplate>
          <div>
            <ng-container
              i18n="@@upload-module|image-uploader|drag-drop-message"
              >Drag & drop your picture or<span class="highlight"
                >&nbsp;click here</span
              ></ng-container
            >
            <span class="allowed-formats" *ngIf="showAllowedFormats"
              ><br />
              <ng-container
                i18n="@@upload-module|image-uploader|allowed-formats"
                >(supported formats:
                {{ parsedAcceptedImageFormats }})</ng-container
              >
            </span>
          </div>
        </ng-template>
        <ng-template #simpleDesignTemplate>
          <div class="simple-design-text">
            +
            <ng-container i18n="@@upload-module|image-uploader|add-photo"
              >Add photo</ng-container
            >
          </div>
        </ng-template>
        <ng-container
          *ngIf="
            simpleDesign;
            then simpleDesignTemplate;
            else normalDesignTemplate
          "
        ></ng-container>

        <db-upload-button
          [uuid]="uuid"
          (upload)="uploadImage($event)"
          [accept]="acceptedImageFormats"
          [isFullHeight]="true"
        >
          <div class="placeholder"></div>
        </db-upload-button>
      </div>
    </div>
  </db-drop-area>
</div>
