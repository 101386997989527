import { Component } from '@angular/core';
import { GlobalLoaderModel } from '../+store/model';

@Component({
  selector: 'db-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent {

  globalLoaderVisible$ = this.globalLoaderModel.selectors.globalLoaderVisible$;
  globalLoaderText$ = this.globalLoaderModel.selectors.globalLoaderText$;

  constructor(private globalLoaderModel: GlobalLoaderModel) {}

}
