export const getAbbreviation = (val: string): string => {
  const valueArray = val.split(' ');

  return (
    valueArray.length === 1
      ? [valueArray[0][0]]
      : valueArray.slice(0, 1).concat(valueArray.slice(-1))
  )
    .map(word => word[0])
    .map(letter => letter?.toUpperCase())
    .join('');
};
