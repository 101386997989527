import { HttpErrorResponse } from '@angular/common/http';
import { props } from '@ngrx/store';
import { forNamespace } from 'ngrx-action-bundles';
import {
  IDownloadQrCodesParameters,
  ILoadQrOfficeTemplateResponse,
  ISetQrOfficeTemplate,
  IUpdateQrOfficeTemplateResponse,
  IUpdateQrStickerTemplateRequest,
} from 'qr-generation-module';
import { QrGenerationLevel, QrGeneratorCurrentView } from 'qr-generation-module';

const factory = forNamespace('QR Generation');

export const loadQrStickerTemplateBundle = factory.asyncAction(
  'loadQrOfficeTemplate',
  props<{ officeId: string }>(),
  props<{ template: ILoadQrOfficeTemplateResponse }>(),
  props<{ error: HttpErrorResponse }>()
);

export const updateQrStickerTemplateBundle = factory.asyncAction(
  'updateQrOfficeTemplate',
  props<{ officeId: string; payload: IUpdateQrStickerTemplateRequest }>(),
  props<IUpdateQrOfficeTemplateResponse>(),
  props<{ error: string }>()
);

export const setCurrentViewBundle = factory.asyncAction('setCurrentView', props<{ view: QrGeneratorCurrentView }>());

export const setQrStickerTemplateBundle = factory.asyncAction('setQrOfficeTemplate', props<ISetQrOfficeTemplate>());

export const setQrGenerationLevelBundle = factory.asyncAction('setQrGenerationLevel', props<{ level: QrGenerationLevel }>());

export const clearQrStickerTemplateBundle = factory.asyncAction('clearQrOfficeTemplate');

export const downloadQrCodesBundle = factory.asyncAction(
  'downloadQrCodes',
  props<{ officeId: string }>(),
  undefined,
  props<{ error: HttpErrorResponse }>(),
  undefined
);

export const setQrCodesForResourceCountBundle = factory.asyncAction(
  'setQrCodesForResourceCount',
  props<{ qrCodesForResourceCount: number }>()
);

export const clearErrorsBundle = factory.asyncAction('clearErrors');
export const setNameBundle = factory.asyncAction('setName', props<{ name: string }>());
export const setDownloadParametersBundle = factory.asyncAction('setDownloadParameters', props<IDownloadQrCodesParameters>());
export const clearDownloadParametersBundle = factory.asyncAction('clearDownloadParameters');

export const qrGenerationBundles = [
  loadQrStickerTemplateBundle,
  updateQrStickerTemplateBundle,
  downloadQrCodesBundle,
  clearQrStickerTemplateBundle,
  clearErrorsBundle,
  clearDownloadParametersBundle,
  setNameBundle,
  setQrStickerTemplateBundle,
  setCurrentViewBundle,
  setDownloadParametersBundle,
  setQrCodesForResourceCountBundle,
  setQrGenerationLevelBundle,
];
