import { HttpErrorResponse } from '@angular/common/http';
import { ICompany, IOffice, IUserGroup } from 'common-module';
import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store';
import { IMainOfficeInfo, IPeopleAnalytics } from '../../typings/people-analytics';

const factory = forNamespace('[ANALYTICS]');

export const loadCompaniesBundle = factory.asyncAction(
  'loadCompanies',
   undefined,
    props<{ companies: ICompany[] }>(),
    props<{ error: HttpErrorResponse }>()
  );

export const loadUSerGroupsTreeBundle = factory.asyncActionWithCleanup(
  'loadUserGroupsTree',
  props<{ companyId: string }>(),
  props<{ userGroups: IUserGroup[] }>(),
  props<{ error: HttpErrorResponse }>()
);

export const loadMainOfficesBundle = factory.asyncActionWithCleanup(
  'loadMainOffices',
  props<{ companyId: string }>(),
  props<{ mainOffices: IMainOfficeInfo[] }>(),
  props<{ error: HttpErrorResponse }>()
);

export const loadPeopleAnalyticsBundle = factory.asyncAction(
  'loadPeopleAnalytics',
  props<{ companyID: string; startDate: string; endDate: string; userGroupID?: string; officeIds?: string[] }>(),
  props<{ response: IPeopleAnalytics }>(),
  props<{ error: HttpErrorResponse }>()
);

export const exportSchedulingsBundle = factory.asyncAction(
  'exportSchedulings',
  props<{ companyId: string; timeframe?: { startDate: string; endDate: string } }>(),
  undefined,
  props<{ error: any }>()
);
export const toggleAbsoluteBundle = factory.singleAction('toggleAbsolute');
export const toggleUnknownBundle = factory.singleAction('toggleUnknown');
export const changeSortingBundle = factory.singleAction('changeSorting', props<{ sortExpression?: string; sortDirection?: 'asc' | 'desc' }>());

export const analyticsBundles = [
  loadCompaniesBundle,
  loadUSerGroupsTreeBundle,
  loadPeopleAnalyticsBundle,
  toggleAbsoluteBundle,
  toggleUnknownBundle,
  loadMainOfficesBundle,
  changeSortingBundle,
  exportSchedulingsBundle,
];
