import { PaidServiceType } from '../enums/paid-service-type';

export interface IPaidService {
  id: string;
  name: string;
  costPerPerson: number;
  numberOfPersons: number;
  description?: string;
  icon?: string;
  type?: PaidServiceType;
}
