import moment from "moment";

export function getWorkingHoursForGivenDay(
  workingHours: { openingTime: string; closingTime: string; },
  startDay: number,
  timezone: string,
): { startTime: number, endTime: number } {
  if (!workingHours) {
    return { startTime: null, endTime: null } as any;
  }
  const { openingTime, closingTime } = workingHours;

  const [startHours, startMinutes] = openingTime.split(':').map((d: string) => +d);
  const [endHours, endMinutes] = closingTime.split(':').map((d: string) => +d);

  const timePartsArr = [startHours, startMinutes, endHours, endMinutes];
  if (timePartsArr.find(tp => !tp && tp !== 0) !== undefined) {
    return { startTime: null, endTime: null } as any;
  }

  const startTimeMomentTz = (startDay ? moment(startDay) : moment())
    .tz(timezone)
    .set('hours', startHours)
    .set('minutes', startMinutes)
    .set('seconds', 0)
    .set('milliseconds', 0);

  const endTimeMomentTz = (startDay ? moment(startDay) : moment())
    .tz(timezone)
    .set('hours', endHours)
    .set('minutes', endMinutes)
    .set('seconds', 0)
    .set('milliseconds', 0);

  return {
    startTime: startTimeMomentTz.toDate().getTime(),
    endTime: endTimeMomentTz.toDate().getTime()
  };
}