import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { RouterModel } from 'router-module';

import { Observable, zip } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root',
})
export class NoAccessActivate implements CanActivate {
  private readonly router = inject(Router);
  private readonly authModel = inject(AuthModel);
  private readonly routerModel = inject(RouterModel);

  canActivate(_route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return zip(this.authModel.isLoggedIn$, this.routerModel.selectors.url$).pipe(
      take(1),
      tap(([isLogged, url]) => {
        if (isLogged) return;
        this.routerModel.actions.dispatch.navigate({
          commands: ['/login'],
          extras: { queryParams: { redirectUrl: url }, queryParamsHandling: 'merge' },
        });
      }),
      filter((val) => !!val),
      switchMap(() => this.authModel.selectors.corporateInfo$),
      filter(Boolean),
      map((corporateInfo) => corporateInfo.allowsScheduling === false && corporateInfo.allowsResourceBooking === false),
      map((noAccess) => {
        if (noAccess) return noAccess;
        return this.router.parseUrl('/default');
      })
    );
  }
}
