import { props } from '@ngrx/store';
import { forNamespace } from 'ngrx-action-bundles';
import { ApiErrorResponse, IUserOfficeRole } from 'types';

const factory = forNamespace('OFFICE-ROLES');

export const loadOfficeRolesForOfficeBundle = factory.asyncAction(
  'loadOfficeRolesForOffice',
  props<{ officeId: string }>(),
  props<{ officeRoles: IUserOfficeRole[] }>(),
  props<{ error: ApiErrorResponse }>()
);

export const loadOfficeRolesForUsersBundle = factory.asyncAction(
  'loadOfficeRolesForUsers',
  props<{ userIds: string[] }>(),
  props<{ officeRoles: IUserOfficeRole[] }>(),
  props<{ error: ApiErrorResponse }>()
);

export const officeRolesBundles = [loadOfficeRolesForOfficeBundle, loadOfficeRolesForUsersBundle];
