/*
 * Public API Surface of internalizaion-module
 */

import '@angular/localize/init';

export * from './lib/internalization.module';
export * from './lib/i18n.service';
export * from './lib/+store/model';
export * from './lib/interfaces-and-types';
export * from './lib/bootstrap-with-language';
export * from './lib/paginator-intl';
export * from './lib/constants';
export * from './lib/prime-ng-translation.service';
