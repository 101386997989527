import { inject, Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs';
import { NotificationModel } from './model';
import { NotificationType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class NotificationEffects {
  private readonly notificationModel = inject(NotificationModel);
  private readonly toastService = inject(MessageService);

  showToastMessage$ = createEffect(
    () =>
      this.notificationModel.actions.listen.showNotification$.pipe(
        tap(({ data, notificationType }) => {
          this.toastService.add({
            severity: notificationType,
            summary: this.getNotificationTypeMessage(notificationType),
            detail: data,
            data,
            life: 5000,
          });
        })
      ),
    { dispatch: false }
  );

  private getNotificationTypeMessage(notificationType: NotificationType): string {
    switch (notificationType) {
      case NotificationType.WARNING:
        return $localize`:@@notification-module|summary|warning:WARNING`;
      case NotificationType.ERROR:
        return $localize`:@@notification-module|summary|error:ERROR`;
      case NotificationType.SUCCESS:
        return $localize`:@@notification-module|summary|success:Success`;
      default:
        return $localize`:@@notification-module|summary|info:Info`;
    }
  }
}
