import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DBCommonModule } from 'common-module';
import { GlobalModule } from 'global-module';

import { NavigationComponent } from './navigation/navigation.component';
import { ClientNavigationComponent } from './client-navigation/client-navigation.component';
import { AdminNavigationComponent } from './admin-navigation/admin-navigation.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuItemWithChildrenComponent } from './menu-item-with-children/menu-item-with-children.component';
import { ExecPipe } from 'shared';
import { MenuComponent, UserInfoComponent } from 'db-ui';
import { ResolveModule } from 'resolve-module';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from 'primeng/api';

const dbUi = [UserInfoComponent];

@NgModule({
  declarations: [
    NavigationComponent,
    ClientNavigationComponent,
    AdminNavigationComponent,
    MenuItemComponent,
    MenuItemWithChildrenComponent,
  ],
  imports: [CommonModule, RouterModule, DBCommonModule, GlobalModule, ExecPipe, ...dbUi, ResolveModule, TooltipModule, MenuComponent, SharedModule],
  exports: [NavigationComponent],
})
export class NavigationModule {
  constructor(@SkipSelf() @Optional() module: NavigationModule) {
    if (module) {
      throw new Error('NavigationModule should be imported only once!');
    }
  }
}
