import { UserStatus } from 'types/enums';
import { IUserInfo } from 'types/interfaces';

export const userIsActive = (userInfo: IUserInfo): boolean => {
  return !userInfo?.status || userInfo?.status === UserStatus.Active;
};

export const canOpenProfile = (userInfo: IUserInfo, authUser: IUserInfo | null): boolean => {
  if (!userInfo || !authUser || userInfo.isGuest) {
    return false;
  }
  return (userInfo?.id ?? userInfo?.userId) != authUser?.id && !userInfo?.isAnonymous && userIsActive(userInfo);
};
