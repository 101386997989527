import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from "primeng/autocomplete";
import { UserService } from "projects/user-module/src/lib/user.service";
import { Observable, Subject, takeUntil } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from "rxjs/operators";
import { IUser, IUserInfo } from "types";
import { AttendeeType } from "types/enums/meeting-rooms/attendee-type";
import {
  AttendeeInput,
  ExternalAttendee,
  InternalAttendeeInput,
} from "types/interfaces/meeting-rooms/attendee";
import { AvatarChipComponent } from "../avatar-chip/avatar-chip.component";
import { UserInfoComponent } from "../user-info/user-info.component";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const SEARCH_DEBOUNCE_TIME = 400;
const DEFAULT_LIMIT = 100;

@Component({
  selector: "db-autocomplete-users",
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    AvatarChipComponent,
    UserInfoComponent,
  ],
  templateUrl: "./autocomplete-users.component.html",
  styleUrls: ["./autocomplete-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnChanges, OnDestroy {
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly destroy$ = new Subject<void>();
  private readonly searchSubject = new Subject<string>();

  @Input() companyId!: string;
  @Input() placeholder!: string;
  @Input() emptyMessage!: string;
  @Input() limit = DEFAULT_LIMIT;
  @Input() alreadySelected!: (IUserInfo | { email: string })[];
  @Input() excludeUserIds: string[] = [];
  @Input() userService!: UserService;
  @Input() dataTestId!: string;

  @Output() selectionChange = new EventEmitter<AttendeeInput[]>();

  selectedUsers: IUser[] = [];
  suggestions: IUser[] = [];

  constructor() {
    this.initializeSearchSubscription();
  }

  ngOnChanges({ alreadySelected }: SimpleChanges): void {
    if (alreadySelected) {
      this.selectedUsers = this.mapAlreadySelectedToUsers(this.alreadySelected);
    }
  }

  private mapAlreadySelectedToUsers(
    users: (IUserInfo | { email: string })[],
  ): IUser[] {
    return users?.map((user) => ("id" in user ? user : { ...user })) as IUser[];
  }

  private initializeSearchSubscription(): void {
    this.searchSubject
      .pipe(
        startWith(""),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((searchValue) => {
          this.suggestions = [];
          if (!searchValue || !this.companyId) {
            return new Observable<IUser[]>((subscriber) => subscriber.next([]));
          }

          const excludedIds = this.getExcludedUserIds();
          const selectedEmails = this.getSelectedEmails();

          return this.userService
            .loadUsersForCompanyFiltered_v3({
              companyId: this.companyId,
              searchQuery: searchValue,
              excludeUserIds: excludedIds,
              offset: 0,
              limit: this.limit,
              sortField: "userName",
              sortOrder: "ASC",
            })
            .pipe(
              switchMap(({ data }) => {
                const suggestions = this.filterAndAddSuggestions(
                  data,
                  selectedEmails,
                  searchValue,
                );
                return new Observable<IUser[]>((subscriber) =>
                  subscriber.next(suggestions),
                );
              }),
            );
        }),
      )
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.cdRef.markForCheck();
      });
  }

  private getExcludedUserIds(): string[] {
    const selectedUserIds = (this.selectedUsers ?? [])
      .filter((user) => user.id)
      .map((user) => user.id);
    return [...(this.excludeUserIds ?? []), ...selectedUserIds];
  }

  private getSelectedEmails(): Set<string> {
    return new Set(
      (this.selectedUsers ?? [])
        .filter((user) => !user.id)
        .map((user) => user.email.toLowerCase()),
    );
  }

  private filterAndAddSuggestions(
    data: IUser[],
    selectedEmails: Set<string>,
    searchQuery: string,
  ): IUser[] {
    const filteredSuggestions = data?.filter(
      (user) => !selectedEmails.has(user.email.toLowerCase()),
    );

    if (
      EMAIL_REGEX.test(searchQuery) &&
      filteredSuggestions.length === 0 &&
      !selectedEmails.has(searchQuery.toLowerCase())
    ) {
      return [...filteredSuggestions, this.createExternalUser(searchQuery)];
    }

    return filteredSuggestions;
  }

  private createExternalUser(email: string): IUser {
    return {
      email,
    } as IUser;
  }

  onSearch(event: AutoCompleteCompleteEvent): void {
    this.searchSubject.next(event?.query?.trim());
  }

  onSelect(): void {
    this.emitAttendees();
    this.searchSubject.next("");
  }

  onBlur(): void {
    this.searchSubject.next("");
  }

  onUnselect(): void {
    this.emitAttendees();
  }

  private emitAttendees(): void {
    const attendees = this.selectedUsers.map((user) =>
      user.id
        ? ({
            type: AttendeeType.INTERNAL,
            id: user.id,
          } as InternalAttendeeInput)
        : ({
            type: AttendeeType.EXTERNAL,
            email: user.email,
          } as ExternalAttendee),
    );

    this.selectionChange.emit(attendees);
  }

  onClearAll(): void {
    this.selectionChange.emit([]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
