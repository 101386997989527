import { BookingType, MultiDayBookingErrorReason } from '../enums';
import { IDeskArea, IUserInfo, IBaseResponse, IMultiDayBookingUserDto, IBooking } from 'types';

export type BookingListColumn = 'dateTime' | 'userId' | 'officeId' | 'area' | 'spot' | 'checkIn' | 'status';

export interface ILoadBookingStandardParams {
  allBookings: boolean;
  bookingType: BookingType;
  page: number;
  pageSize: number;
}

export type ILoadBookingCustomParams = Record<BookingListColumn, string | null>;

export type ILoadBookingRequest = ILoadBookingStandardParams & Partial<ILoadBookingCustomParams>;

export interface IBookingResponse {
  id: string;
  booking: IBooking;
}

export interface IBookingResponse
  extends IBaseResponse<{
    id: string;
    booking: IBooking;
  }> {}

export interface IBookingRequest {
  /**
   * FIXME: in meeting room endpoints times are ISO strings in other endpoints they are timestamps
   * but both uses the same interface
   * */
  bookingStartTime: string | number;
  bookingEndTime: string | number;
  bookingTitle?: string | null;
  resourceType?: string;
  internal: boolean;
  isAnonymous: boolean;
  isDayPass: boolean;
  zoneId: string;
  zoneItemId: number;
  workspaceId: string;
}

export interface ITimeParamInfo {
  startTime?: number;
  endTime?: number;
  isFullDay?: boolean;
  auto?: boolean;
}

export interface ITimeParamInfoInitialized {
  startTime: number;
  endTime: number;
  isFullDay: boolean;
}

export interface IBookingEditRequest extends IBookingRequest {
  bookingId: string;
  action: 'updateInternalBooking';
}
export interface IMeetingRoomCalendarCard {
  bookingTitle: string;
  // FIXME: virtualId, bookingId shouldn't be part of the user
  // FIXME: it should be id instead of userId
  user: IUserInfo & { virtualId?: string; bookingId?: string; userId?: string };
  startTime: number;
  endTime: number;
  height: number;
  offsetTop: number;
  seriesId?: string;
  isAnonymous?: boolean;
}

export interface IMultiDayBooking {
  id: string;
  booking: IBooking | null;
  resourceId: number | null;
  deskAreaId: string;
  bookingUser: IMultiDayBookingUserDto;
  officeId: string;
  timeInfo: ITimeParamInfo | null;
  /** Desk area is required for GTM event tracking and should not be used for other purposes */
  deskArea: IDeskArea | null;
  noMoreDesksAvailable?: boolean;
  hasErrorCreatingBooking?: boolean;
  errorReason?: MultiDayBookingErrorReason;
  isAnonymous: boolean;
}

export interface IFloorConfigResponse {
  success: boolean;
  data: {
    id: string;
    floorConfig: string;
    floorConfigReady: boolean;
  };
}
