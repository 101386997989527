import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownWithSearchComponent } from '../dropdown-with-search';

@Component({
  selector: 'db-dropdown-input',
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownWithSearchComponent],
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownInputComponent,
      multi: true,
    },
  ],
})
export class DropdownInputComponent<TItem extends { [key: string]: any }> implements ControlValueAccessor {
  selectedOptionValue: TItem | string | undefined | null = undefined;

  @Input() set selectedOption(value: TItem | string | undefined | null) {
    this.selectedOptionValue = value || '';
  }

  @Input() virtualScrollItemSize = 44;
  @Input() options: any = [];
  @Input() optionLabel = '';
  @Input() optionValue = '';
  @Input() isLoading = false;
  @Input() filterBy!: string;
  @Input() placeholder: string = '';
  @Output() selectedOptionChanged = new EventEmitter<string>();

  @ViewChild(DropdownWithSearchComponent, { read: DropdownWithSearchComponent, static: true })
  private dropdownWithSearch!: DropdownWithSearchComponent<TItem>;

  private changeDetectorRef = inject(ChangeDetectorRef);

  selectedOptionChangedHandler(value: string): void {
    this.selectedOptionChanged.emit(value);
  }

  show = () => {
    this.dropdownWithSearch.show();
  };

  hide = () => {
    this.dropdownWithSearch.hide();
  };

  writeValue(obj: any): void {
    this.selectedOption = obj;
    this.dropdownWithSearch.writeValue(obj);
    this.changeDetectorRef.markForCheck();
  }
  registerOnChange(fn: any): void {
    this.dropdownWithSearch.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.dropdownWithSearch.registerOnTouched(fn);
  }
  setDisabledState(isDisabled: boolean): void {
    this.dropdownWithSearch.setDisabledState(isDisabled);
    this.changeDetectorRef.markForCheck();
  }
}
