<div *ngIf="isLoading" class="container" [ngClass]="{ fixed: isFixed }">
  <div data-testid="loader--loader-container" class="loader-container" [ngClass]="{ 'no-shadow': noShadow }">
    <db-lottie-player
      [lottie]="loaderLottie"
      width="180px"
      height="180px"
      [loop]="true"
      [autoplay]="true"
    >
    </db-lottie-player>
    <p class="small-text-bold" *ngIf="loaderText">
      <db-dot-loader>{{ loaderText }}</db-dot-loader>
    </p>
  </div>
</div>
