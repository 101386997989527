export enum OfficePlanningStatusOptionType {
  Office = 'office',
  HomeOffice = 'home_office',
  Absent = 'absent',
  Travel = 'travel',
  OtherOffice = 'other_office',
  Custom = 'custom',
  MobileWork = 'mobile_work',
  Invalid = 'n/a'
}
